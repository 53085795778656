import {
  FieldPosition, PendingRemoteSigningSessionFieldCustom,
  PendingRemoteSigningSessionPage,
  SigningSessionFieldType,
  SigningSessionSubType
} from '@property-folders/contract';
import { Dimension } from '@property-folders/common/signing/draw-compound-signature';
import { useState } from 'react';

export function asPercentage(originalValue: number, containerValue: number) {
  if (containerValue === 0) {
    return originalValue;
  }

  return `${100 * (originalValue / containerValue)}%`;
}

export interface FieldProps {
  id: string;
  type: SigningSessionFieldType;
  subtype: SigningSessionSubType;
  name: string;
  timestamp: number;
  timeZone: string;
  page: PendingRemoteSigningSessionPage;
  location: FieldPosition;
  minCompositeSignatureDimensions?: Dimension;
  fillField?: (text?: string) => void;
  clearField?: () => void;
  text?: string;
  image?: string;
  pixelsPerInch: number;
  custom?: PendingRemoteSigningSessionFieldCustom;
  observer?: IntersectionObserver
}

export function loadImage(src: string) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new window.Image();
    image.onload = () => resolve(image);
    image.onerror = err => {
      image.remove();
      reject(err);
    };
    image.src = src;
  });
}

export function useFieldVisibilityAid(condition: boolean) {
  const [touched, setTouched] = useState(false);
  const showAid = !touched && condition;
  return {
    className: showAid
      ? 'field-visibility-aid'
      : '',
    showAid,
    setTouched
  };
}
