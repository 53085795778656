import { useContext, useEffect, useMemo, useState } from 'react';
import {
  generateParentPath,
  getHierarchyNode,
  getPathParentAndIndex,
  getValidationDefnByPath,
  getValueByPath,
  normalisePathToStr,
  normalisePathToStrArray
} from '@property-folders/common/util/pathHandling';
import { Snapshot } from 'immer-yjs';
import { v4, validate as isUUID } from 'uuid';
import _, { cloneDeep, findIndex } from 'lodash';
import { useTimeout } from '@property-folders/components/hooks/useTimeout';
import {
  runValidationMethods,
  simpleValidationMethods
} from '@property-folders/common/yjs-schema/property/validation/process-validation';
import * as Y from 'yjs';
import {
  DocumentFieldType,
  FieldGroupFn,
  PathSegments,
  PathType,
  ValidationDefnType
} from '@property-folders/contract/yjs-schema/model';
import { fieldGroupFnDirectory } from '@property-folders/common/yjs-schema/property/validation/field-group-functions';
import {
  updatePreventerCheckDirectory
} from '@property-folders/common/yjs-schema/property/validation/update-interceptors';
import { canonicalisers, eventSourcePreprocessors, inputTransformers } from '@property-folders/common/util/formatting';
import { PDFLoadStateSetterContext } from '@property-folders/components/context/pdfLoadStateContext';
import { COMPONENT_COMMIT_DELAY, LegalJurisdiction, UPDATING_FLASH_TIME } from '@property-folders/common/data-and-text/constants';
import { DebouncedAwarenessContext } from '@property-folders/components/context/DebouncedAwarenessContext';
import { useUpdatePresence } from '@y-presence/react';
import {
  AwarenessData,
  InstanceHistory,
  MaterialisedPropertyData,
  TransactionMetaData
} from '@property-folders/contract';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { Maybe } from '@property-folders/common/types/Utility';
import { WizardFieldFocusStateContext } from '@property-folders/components/context/WizardContexts';
import { isPathInAnyHierachy, LineageContext } from './useVariation';

import {
  WarnBeforeUpdateContext,
  WarnBeforeUpdateContextType
} from '@property-folders/components/dragged-components/Wizard/WarnBeforeUpdateWithContext';
import { MasterRootKey } from '@property-folders/contract/yjs-schema/property';
import { useSelector } from 'react-redux';
import { Predicate } from '@property-folders/common/predicate';
import { FormContext } from '@property-folders/components/context/FormContext';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { FormUserInteractionContext } from '@property-folders/components/context/FormUserInteractionContext';
import { checkBuildPath } from '@property-folders/common/util/build-skeleton';
import { useNoopUndefined } from './useNoop';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { formatAct } from '@property-folders/common/util/pdfgen/formatters/clauses';
import { ValidationReducerState } from '@property-folders/common/redux-reducers/validation';

export const insertAllImmerPath = (immerState: Record<string, any>, values: any[], path: string, rootFieldDefinition: DocumentFieldType) => {
  const defn = getValidationDefnByPath(path.split('.').filter(a=>a), rootFieldDefinition);
  if (defn == null) console.error('Definition not found for requested path', path, rootFieldDefinition);
  const valueArray = values.map(value => {
    if (defn._type === 'Array' && defn._children?._type === 'Map' && !value?.id) {
      value = { id: v4(), ...value };
    }
    return value;
  });

  const { parent, indexer } = getPathParentAndIndex(path, immerState);
  if (!Array.isArray(parent[indexer])) {
    parent[indexer] = [];
  }
  parent[indexer].push(...valueArray);
};

const insertImmerPath = (immerState: Record<string, any>, value: any, path: string, fieldDefinition: DocumentFieldType) => {
  insertAllImmerPath(immerState, [value], path, fieldDefinition);
};

export const updateImmerPath = (immerState: Record<string, any>, value: any, path: string, groupPostProcess?: FieldGroupFn, displayLabel?: string, variationsSnapshot?: MaterialisedPropertyData, variationHistory?: InstanceHistory) => {
  const { parent, indexer } = getPathParentAndIndex(path, immerState);
  const groupFunctionPreviousState = groupPostProcess && cloneDeep(immerState);
  if (parent === undefined) {
    // This will be a mess if we have a non-existent array in the path. At the moment this is here
    // to handle a map in a map, but we don't have the document definition at this scope, and I need
    // a fix now
    const pathSegs = path.split('.');
    const ppath = pathSegs.slice(0,pathSegs.length-1).join('.');
    updateImmerPath(immerState, {}, ppath);
    const { parent: p2, indexer: i2 } = getPathParentAndIndex(path, immerState);
    p2[i2] = value;
    const labelIndexer = `${i2}_display`;
    if (displayLabel) {
      p2[labelIndexer] = displayLabel;
    } else {
      delete parent[labelIndexer];
    }
  } else {
    parent[indexer] = value;
    const labelIndexer = `${indexer}_display`;
    if (displayLabel) {
      parent[labelIndexer] = displayLabel;
    } else {
      delete parent[labelIndexer];
    }
  }
  groupPostProcess?.(
    path.split('.').map(s=>s.startsWith('[')?'[]':s).join('.'),
    path,
    immerState as any,
    variationsSnapshot,
    variationHistory,
    groupFunctionPreviousState
  );
};

const removeYjsPath = (ydoc: Y.Doc, transactionRootKey: string, path: string) => {
  // Removing this with immer doesn't seem to work correctly, so I'm doing it directly with yjs
  // semantics
  const pathSegments = path.split('.').filter(a=>a);
  let current: any = ydoc.getMap(transactionRootKey);
  for (const segi in pathSegments) {
    const pathSegment = pathSegments[segi];
    let indexer: string | number = pathSegment;
    if (pathSegment.startsWith('[')) {
      indexer = indexer.slice(1, pathSegment.length-1);
      if (isUUID(indexer)) {
        const inArray = [...current as Iterable<any>];
        indexer = _.findIndex(inArray, elem => elem?.get('id') === indexer);
      } else {
        indexer = parseInt(indexer);
      }
    }
    if (parseInt(segi) < pathSegments.length - 1) {
      current = current.get(indexer);
    } else if (current) {
      current.delete(indexer);
    }
  }
};

const clearYjsList = (ydoc: Y.Doc, transactionRootKey: string,path: string) => {
  // Removing this with immer doesn't seem to work correctly, so I'm doing it directly with yjs
  // semantics
  const pathSegments = path.split('.').filter(a=>a);
  let current: any = ydoc.getMap(transactionRootKey);
  for (const segi in pathSegments) {
    const pathSegment = pathSegments[segi];
    let indexer: string | number = pathSegment;
    if (pathSegment.startsWith('[')) {
      indexer = indexer.slice(1, pathSegment.length-1);
      if (isUUID(indexer)) {
        const inArray = [...current as Iterable<any>];
        indexer = _.findIndex(inArray, elem => elem?.get('id') === indexer);
      } else {
        indexer = parseInt(indexer);
      }
    }
    current = current.get(indexer);
    if (parseInt(segi) === pathSegments.length - 1) {
      if (current instanceof Y.Array) {
        current.delete(0,current.length);
      } else {
        console.warn('Clear was not called on an array');
      }
    }

  }
};

function checkIntercept<TValue>(proposedValue: TValue, proceedCallback: ()=>void, formNodeRule: ValidationDefnType, transactionRoot: MaterialisedPropertyData, setDialog: WarnBeforeUpdateContextType,latestSnapshot?: MaterialisedPropertyData, instHistory?: InstanceHistory) {
  if (!latestSnapshot || !formNodeRule._variationChangeIntercept) {
    proceedCallback();
    return;
  }
  const checkFn = updatePreventerCheckDirectory[formNodeRule._variationChangeIntercept ?? ''];
  if (!checkFn) {
    proceedCallback();
    return;
  }

  const shouldPrevent = checkFn(proposedValue, transactionRoot, latestSnapshot, instHistory);

  if (!shouldPrevent) {
    proceedCallback();
    return;
  }

  setDialog(proceedCallback, 'Legal compliance warning', <p>
    If you choose to change the method of sale to Auction, to ensure compliance with sections 20(5a) and 20(6f) of the <span className='fs-italic'>{formatAct(LegalJurisdiction.SouthAustralia, 'LandAndBusinessSaleAndConveyancingAct1994', { noItalics: true })}</span>, you must revert the Vendor’s Selling Price to the lowest price it has been for the duration of this Sales Agency Agreement. Click 'Continue' to proceed with Auction, which will automatically reduce the Vendor’s Selling Price accordingly.
  </p>);
}

// export is for testing, made static even though it is using a lot of side effects
// guess it will need to be mocked
export const handleUpdateStatic = (
  originalValue: any,
  unbouncedValue: any,
  setUnbouncedValue: (value: any)=> void,
  setUnbouncedLabel: (label: string | undefined) => void,
  fullPath: string,
  declareDebounce: (value: string)=> void,
  delayTime: number | null,
  setDelayTime: (time: number | null)=> void,
  setAwaitingLoadCompletion: (complete: boolean) => void,
  subtype?: string,
  immediate = false,
  eventSource?: string,
  eventData?: string,
  displayLabel?: string
) => {
  let value = originalValue;
  if (eventSource && subtype && eventSourcePreprocessors[eventSource]?.[subtype]) {
    let lvalue: string|undefined;
    if (eventData && subtype in canonicalisers) {
      const llvalue = eventSourcePreprocessors[eventSource]?.[subtype](eventData);
      const canon = canonicalisers[subtype](llvalue);
      if (canon.valid) {
        lvalue = llvalue;
      }
    }
    if (lvalue === undefined) {
      lvalue = eventSourcePreprocessors[eventSource]?.[subtype](value);
    }
    value = lvalue;
  }
  if (subtype && subtype in inputTransformers) {
    value = inputTransformers[subtype](value);
  }
  // We use original value for the compare, so that we can demo the entry of the same data in a
  // different format.
  if (originalValue !== unbouncedValue && !(unbouncedValue === 'undefined' && originalValue === '')) {
    setUnbouncedValue(value);
    setUnbouncedLabel(displayLabel);
    declareDebounce(fullPath);
    setAwaitingLoadCompletion(true);

    // So what we have here is enabling and triggering the useTimeout. This is because the useEffect
    // internal to the useTimeout is triggers when delayTime changes, and if not null, begins the
    // timeout. The other trigger is that of value changes, so that the timer resets every time
    // the value changes.
    if (immediate) {
      setDelayTime(1);
    } else {
      setDelayTime(COMPONENT_COMMIT_DELAY);
    }
  } else if (immediate && originalValue === unbouncedValue && delayTime !== null) {
    setDelayTime(1);
    setAwaitingLoadCompletion(true);
  }
};

/**
 *
 * @param rules
 * @param path
 * @param dataRoot
 * @param lockedIds While this is typed any, really, we want a string[]
 * @returns
 */
export function isPartyLockedField(rules: DocumentFieldType, path: PathSegments, dataRoot: any, lockedIds: any) {
  if (!(Array.isArray(lockedIds) && lockedIds.length > 0 && typeof lockedIds[0] === 'string' && Array.isArray(rules?._readonlyOnLockedIdPath) )) {
    return false;
  }
  const relpathSegs = [...rules._readonlyOnLockedIdPath];
  const mutablePath = [...path];
  for (const nseg of relpathSegs) {
    switch (nseg) {
      case '.': continue;
      case '..': mutablePath.splice(mutablePath.length-1,1); continue;
      default: mutablePath.push(nseg);
    }
  }
  const dependentValue = getValueByPath(mutablePath, dataRoot, true);
  return lockedIds.includes(dependentValue);
}

/**
 *
 * @param param0 React-style props for this hook
 * @param param0.validationTypes List of simple validation filters to run with every change. This
 *  is in addition to types listed in the calculated definitions
 * @param param0.noPushErrors List of simple validation filters which should prevent push if present.
 *  Note: Validation rules in this list should also be in validationTypes, or they will not be run.
 *  I'm lazy ok, yes I know I could join them and get unique entries but w/e
 * @param param0.ydocForceKey Override retrieved root key of the ydoc, for metadata or specific
 *  versions. Does not affect returned transactionRootKey
 * @param param0.bindToMetaKey Rather than forcing key, use the current meta key, being aware of
 *  sublineages. Value comes from YjsDocContext.transactionMetaRootKey
 * @param param0.useCanonical Feed canonical value into the component, eg for date and for time input
 *  types.
 * @param param0.deleteParentItemEmpty We are expecting this to be part of a list, and if this field
 *  is set to empty, check sibling fields (exclusion ID) for empty values and if all are empty,
 *  delete the row. This parameter can only be used if all fields are able to be set to a falsey
 *  value. Note that values of 0 will still trigger this removal. Any nested objects or lists will
 *  not trigger at this time.
 * @param forceFieldGroupFn Generally you should be putting field group functions in a form context,
 *  however you may wish to use this if, say, it's metadata which is not a form concern and the
 *  declarative infra isn't really set up for it.
 * @returns
 */
export function useTransactionField<TValue = any>({ bindToMetaKey, forceFieldGroupFnName, parentPath, myPath, noPushErrors, validationTypes, ydocForceKey, useCanonical, deleteParentItemEmpty }: {
  parentPath?: PathType,
  myPath?: PathType,
  noPushErrors?: string[],
  validationTypes?: string|string[],
  ydocForceKey?: string, // Does not affect returned transactionRootKey
  bindToMetaKey?: boolean,
  forceFieldGroupFnName?: string,
  useCanonical?: boolean,
  deleteParentItemEmpty?: boolean
}) {
  const setUpdateWarningDialog = useContext(WarnBeforeUpdateContext);
  const { ydoc, transactionRootKey, transactionMetaRootKey, declareDebounce, clearDeclareDebounce, docName } = useContext(YjsDocContext);
  const variationContext = useContext(LineageContext);
  const { variationsMode, changeSet, snapshotData, snapshotHistory } = variationContext;

  const { updatePresence } = useContext(DebouncedAwarenessContext);
  const { addFieldFocus, remFieldFocus } = useContext(WizardFieldFocusStateContext) ?? {};
  const updatePresenceFallback = useUpdatePresence<AwarenessData>();
  const { formRules, transactionRules: dataTransRules, metaRules, fieldGroups, reportMissing, formName } = useContext(FormContext);

  const { setAwaitingLoadCompletion } = useContext(PDFLoadStateSetterContext);
  const rootKey = ydocForceKey ?? (bindToMetaKey ? transactionMetaRootKey : null) ?? transactionRootKey ?? MasterRootKey.Data;
  const transactionRules = rootKey.endsWith(`${MasterRootKey.Meta}`) ? metaRules : dataTransRules;

  const fullPathSegs = normalisePathToStrArray([
    ...normalisePathToStrArray(parentPath??[]).filter(Predicate.isTruthy), // Empty string is relative path, however this component should never face a relative path, we need transaction root, and any empty strings are erroneous
    ...normalisePathToStrArray(myPath??[]).filter(Predicate.isTruthy) // in case this is an empty string too, relative paths in the middle of the path array are nonsensical
  ]);
  const fullPath = normalisePathToStr(fullPathSegs);
  const shouldShowFullValidationError = useContext(FormUserInteractionContext).userShouldSeeAllValidation;
  const fullValidationError = useSelector((state: { validation: ValidationReducerState }) => {
    const baseTree = state?.validation?.errorPaths?.[docName ?? '']?.[rootKey ?? '']?.[formName];

    const result = !!getHierarchyNode(fullPathSegs, baseTree);

    return !!result;
  }) && shouldShowFullValidationError;

  const fullValidationDetail = useSelector((state: { validation: ValidationReducerState }) => {
    const baseTree = state?.validation?.vtrees?.[docName ?? '']?.[rootKey ?? '']?.[formName];
    const value = getValueByPath(fullPathSegs, baseTree, true);

    const maybeErrorList = value?._validationResult?.errors;
    if (!Array.isArray(maybeErrorList)) return undefined;
    return maybeErrorList;
  });

  const fullPathDefn = fullPathSegs.map(s=>s.startsWith('[')?'[]':s).join('.');
  const fieldGroupFnName = forceFieldGroupFnName || fieldGroups?.[fullPathDefn] || '';
  const fieldGroupFn = fieldGroupFnDirectory[fieldGroupFnName];

  if (fieldGroupFnName && !fieldGroupFn) {console.warn('Declared function for location but no function found', fieldGroupFnName, fullPathDefn);}

  useEffect(()=>{
    // Clear our debounce declaration if we are cleaned up
    return ()=>clearDeclareDebounce?.(fullPath);
  }, []);

  const { bindState, status: ydocBindStatus, binder: staticDataBinder } = useImmerYjs(ydoc, rootKey);
  const { bindState: metaBindState } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey ?? MasterRootKey.Meta);
  const { bindState: parentMetaBindState } = transactionMetaRootKey !== MasterRootKey.Meta ? useImmerYjs<TransactionMetaData>(ydoc, MasterRootKey.Meta) : (useNoopUndefined()()??{});
  const { data: storedValue, update } = bindState?.((state: any) => getValueByPath(fullPath, state, true)) || {};
  const { data: lockedParties } = (parentMetaBindState??metaBindState)?.(state => state?.previouslySignedParties) || {};

  const [unbouncedValue, setUnbouncedValue] = useState<any>(storedValue);
  const [unbouncedLabel, setUnbouncedLabel] = useState<string|undefined>(undefined);

  const [delayTime, setDelayTime] = useState<null|number>(null);
  const [fieldValidationState, setFieldValidationState] = useState(false);
  const [fieldErrorKeys, setFieldErrorKeys] = useState<string[]>([]);
  const [updatedDelayTime, setUpdatedDelayTime] = useState<null|number>(null);
  const [updatedDelayID, setUpdatedDelayID] = useState<null|string>(null);
  const [supressLoadFlash, setSupressLoadFlash] = useState(true);

  const setUpdateFlash = () => {
    setUpdatedDelayTime(UPDATING_FLASH_TIME);
    setUpdatedDelayID(v4());
  };
  const resetUpdateFlash = () => {
    setUpdatedDelayTime(null);
    setUpdatedDelayID(null);
  };

  const fieldRule = useMemo(()=>getValidationDefnByPath(fullPathSegs,transactionRules), [transactionRules]);
  const formRule =  useMemo(()=>getValidationDefnByPath(fullPathSegs,formRules as ValidationDefnType, reportMissing), [formRules]);
  const subtype = typeof fieldRule?._subtype === 'string'
    ? fieldRule._subtype
    : undefined;

  const { allValidations, mergedRules  } = useMemo(()=>{
    const mergedRules =  { ...fieldRule, ...formRule };

    const localValidation: string[] = [];
    if (mergedRules._required) {
      localValidation.push('required');
    }
    if (mergedRules._subtype in simpleValidationMethods || mergedRules._subtype in canonicalisers) {
      localValidation.push(mergedRules._subtype);
    }
    localValidation.concat(validationTypes ?? []);

    return { allValidations: localValidation, mergedRules };
  },[validationTypes, fieldRule, formRule]);

  // Readonly should never be set on a primary document, as the behaviour of readonly is to use
  // snapshot data of a previous lineage form
  // Be mindful that a snapshot is of what was in the data model at the time signing was begun, so
  // if that data was changed in a different form, the snapshot may contain the wrong data (as I
  // suspect I didn't account for this in the snapshot process, this should probably be rectified)
  const readOnly = useMemo(()=>{
    if (formRule?._readOnly) {
      return true;
    }

    return isPartyLockedField(mergedRules, fullPathSegs, staticDataBinder?.get(), (lockedParties??[]));

  }, [formRules, variationsMode, lockedParties]);
  const hasVaried = variationsMode && isPathInAnyHierachy(fullPathSegs, changeSet) && !readOnly;

  useEffect(() => {
    if ((Array.isArray(allValidations) && allValidations.length > 0) || fullValidationError) {
      const validations = Array.isArray(allValidations) ? allValidations : [allValidations];
      const errorKeysRaw = runValidationMethods(unbouncedValue, validations, mergedRules).errorKeys;

      const errorKeys = errorKeysRaw.filter(key=>key!=='required');

      if (fullValidationError) {
        // Should be the lowest priority error, others might be more informative
        const errorDetails = fullValidationDetail??[];
        errorKeys.push(...errorDetails, 'stillInvalidAfterProcessing');
      }
      setFieldErrorKeys(errorKeys);
      setFieldValidationState(!errorKeys.length);
    } else {
      setFieldErrorKeys([]);
      setFieldValidationState(true);
    }
  }, [allValidations, unbouncedValue, fullValidationError, (fullValidationDetail??[]).join('')]);

  useEffect(() => {
    let toUpdateValue = storedValue;
    if (subtype && subtype in canonicalisers) {
      const extraParams = [];
      if (subtype === 'days') {
        extraParams.push(mergedRules._extraSuffixFormView);
      }
      toUpdateValue = canonicalisers[subtype](toUpdateValue, ...extraParams)[useCanonical ? 'canonical' : 'display'];
    }

    setUnbouncedValue(toUpdateValue);
    setDelayTime(null); // If the value that a person is waiting to get committed is clobbered by

    // a network update, we won't be recommitting what they sent us. If they still want to edit it,
    // this will go back through handleUpdate and set the timer again
    if (!supressLoadFlash) {
      setUpdateFlash();
    }
  }, [storedValue]);

  const checkForEmptySiblings = () => {
    const materialised = ydoc?.getMap(rootKey).toJSON();
    if (!materialised) {
      return;
    }
    const { parent, indexer } = getPathParentAndIndex(generateParentPath(fullPath), materialised, true);
    if (Array.isArray(parent) && typeof parent[parseInt(indexer)] === 'object') {
      const pathLeaf = fullPathSegs[fullPathSegs.length-1];
      const testObj = parent[parseInt(indexer)];
      let nonEmpty = false;
      for (const key in testObj) {
        if (key !== 'id' && key !== pathLeaf) {
          nonEmpty = !!testObj[key]; // As per the other comment, a value of zero is probably still empty
        }
      }
      return !nonEmpty;
    }
    return false;
  };

  // End of debounce
  useTimeout(()=> {
    if (!Array.isArray(noPushErrors) || _.intersection(noPushErrors, fieldErrorKeys).length === 0) {
      let toUpdateValue = unbouncedValue;
      const toUpdateLabel = unbouncedLabel;
      let nDisplay = toUpdateValue;
      if (subtype && subtype in canonicalisers) {
        const canonResult = canonicalisers[subtype](toUpdateValue);
        nDisplay = canonResult[useCanonical ? 'canonical' : 'display'];
        toUpdateValue = canonResult.canonical;
      }

      if (
        unbouncedValue == null
        || (
          storedValue === undefined && !toUpdateValue
          && !(mergedRules._type === 'number' && typeof toUpdateValue === 'number')
          && !(mergedRules._type === 'boolean' && typeof toUpdateValue === 'boolean')
        )
      ) {
        // Not writing a falsey value over undefined, unless it is 0 in the case of a number type
        // or a false in the case of a declared boolean type

        // We do need to stop the saving declaration though
        clearDeclareDebounce?.(fullPath);
        setAwaitingLoadCompletion(false);
      } else if (toUpdateValue === storedValue) {
        // Hack to make sure that when someone enters the same data, it is still flashing and
        // transforming, despite Yjs making no changes
        setUnbouncedValue(nDisplay);
        if (!supressLoadFlash) {
          setUpdateFlash();
        }
        // Less of a hack, we actually need to do this when people don't change the value and think
        // they are
        setAwaitingLoadCompletion(false);
      } else if (deleteParentItemEmpty && !toUpdateValue && checkForEmptySiblings()) { // Maybe we do want to check for 0 values, a line item with 0 and every other field blanked is likely unwanted
        ydoc && removeYjsPath(ydoc, rootKey || '', generateParentPath(fullPath));
      } else {
        update?.((draft: any) => {
          checkBuildPath(generateParentPath(fullPath), transactionRules, draft);
          updateImmerPath(draft, toUpdateValue, fullPath, fieldGroupFn, toUpdateLabel, snapshotData, snapshotHistory);
        });
        if (fieldGroupFn) {
          const mapping = ydoc?.getMap(rootKey).toJSON() || {};
          const valueAsFreshlyUpdated = getValueByPath(fullPath, mapping, true);
          if (valueAsFreshlyUpdated === storedValue && valueAsFreshlyUpdated !== toUpdateValue) {
            // The reason this rigmarole is neccessary, is because the useEffect to run value
            // commits, and adjust presentation for it relies on a trigger of [storedValue], but if
            // stored value doesn't change because the given value was invalid, the previous value
            // is valid and the automatically updated value is equal to the previous value. Thus
            // storedValue is not changed.
            const valueToRestore = storedValue;
            let reDisplay = valueToRestore;
            if (subtype && subtype in canonicalisers) {
              const canonResult = canonicalisers[subtype](reDisplay);
              reDisplay = canonResult[useCanonical ? 'canonical' : 'display'];
            }
            setUnbouncedValue(reDisplay);
            setUpdateFlash();
          }
        }
      }
    }

    setDelayTime(null);
  }, delayTime, unbouncedValue);

  useTimeout(() => {
    resetUpdateFlash();
    clearDeclareDebounce?.(fullPath);
  }, updatedDelayTime, updatedDelayID);

  const rVal = (variationsMode && readOnly ? getValueByPath(fullPath, snapshotData??{}, true) : unbouncedValue) as TValue;

  // This valid flag now no longer includes required. It should be thus only used if the user has performed an action
  // to check form validity as a whole, or the data itself is invalid
  const valid = fieldValidationState;
  const handleUpdate = readOnly ? ()=>console.warn('attempted to update a readonly value') : (originalNewValue: TValue, immediate = false, eventSource?: string, eventData?: string, displayLabel?: string) => {
    const updateBind = () => handleUpdateStatic(
      originalNewValue,
      unbouncedValue,
      setUnbouncedValue,
      setUnbouncedLabel,
      fullPath,
      declareDebounce,
      delayTime,
      setDelayTime,
      setAwaitingLoadCompletion,
      subtype,
      immediate,
      eventSource,
      eventData,
      displayLabel
    );
    checkIntercept(
      originalNewValue,
      updateBind,
      mergedRules,
      ydoc?.getMap(rootKey).toJSON() as MaterialisedPropertyData,
      setUpdateWarningDialog,
      snapshotData,
      snapshotHistory,
    );
  };

  const handleRemove = readOnly ? ()=>console.warn('attempted to remove a readonly value') : () => {
    const groupFunctionPreviousState = fieldGroupFn && cloneDeep(ydoc?.getMap(rootKey).toJSON());
    ydoc && removeYjsPath(ydoc, rootKey || '', fullPath);
    update?.((draft: any) => {
      fieldGroupFn?.(
        fullPath.split('.').map(s=>s.startsWith('[')?'[]':s).join('.'),
        fullPath,
        draft as any,
        snapshotData,
        snapshotHistory,
        groupFunctionPreviousState
      );
    });
  };
  /**Searches list for this item, and then removes it.
   *
   * Honestly not sure whether this will work for object removal, because it's probably serialised
   * or something, but should still work for strings and numbers etc
   * @param item Item to be removed. Ref must match or otherwise be === to item removed
   */
  const removeItem = (item: any) => {
    if (Array.isArray(storedValue)) {
      const removalI = findIndex(storedValue, e=>e===item);
      if (removalI >=0) {
        ydoc && removeYjsPath(ydoc, rootKey || '', fullPath+`.[${removalI}]`);
      } else {
        console.warn('Can\'t remove element that cannot be found');
      }
    } else {
      console.warn('Can\'t remove item from something other than a proper Array');
    }

  };
  const handleClear = readOnly ? ()=>console.warn('attempted to clear a readonly value') : () => {
    ydoc && clearYjsList(ydoc, transactionRootKey ?? '', fullPath);
  };
  const handleInsert = readOnly ? ()=>console.warn('attempted to insert on a readonly value') : (value: any) => {
    update?.((draft: any) => {
      checkBuildPath(generateParentPath(fullPath), transactionRules, draft);
      Array.isArray(value) ? insertAllImmerPath(draft, value, fullPath, transactionRules) : insertImmerPath(draft, value, fullPath, transactionRules);
    });
  };
  const handleAwarenessPayload = (payload: Partial<AwarenessData>) => {
    if (updatePresence) {
      return updatePresence(payload);
    }
    updatePresenceFallback(payload);
  };
  const handleAwarenessFocus = (evt: React.FocusEvent<HTMLElement, Element>) => {
    addFieldFocus?.(fullPath, evt);
    handleAwarenessPayload({
      location: {
        path: fullPath
      }
    });
  };
  const handleAwarenessBlur = (evt: React.FocusEvent<HTMLElement, Element>) => {
    remFieldFocus?.(fullPath, evt);
    handleAwarenessPayload({
      location: {
        path: ''
      }
    });
  };

  const required = allValidations.includes('required');

  const justUpdated = updatedDelayTime !== null;

  // Hook must be after others, and that matters, because the initial firing of the
  // storedValue hook causes a flash on load, which is irritating
  useEffect(()=>{
    if (ydocBindStatus === 'success') {
      setSupressLoadFlash(false);
    }
  }, [ydocBindStatus]);

  // To avoid creating a new object every time... maybe?
  return useMemo(()=>({
    fullPath,
    value: rVal,
    handleUpdate,
    handleRemove,
    removeItem,
    handleClear,
    handleInsert,
    required,
    valid,
    errorKeys: fieldErrorKeys,
    justUpdated, subtype,
    loading: supressLoadFlash,
    handleAwarenessFocus,
    handleAwarenessBlur,
    suppressLoad: supressLoadFlash,
    hasVaried,
    variationContext,
    readOnly,
    mergedRules,
    transactionRootKey, // For convenience because we already have this hooked in
    transactionMetaRootKey
  }), [fullPath, rVal, required, valid, fieldErrorKeys, justUpdated, subtype, supressLoadFlash, hasVaried, changeSet, readOnly, transactionRootKey, transactionMetaRootKey]);
}

export function fullPathJoin({ parentPath, myPath }: TransactionConsumerProps) {
  let fullPath = parentPath ?? '';
  if (fullPath && myPath) {fullPath = fullPath + '.' + myPath;} // Make recursive friendly in concept
  else if (myPath) {fullPath = myPath;}
  return fullPath;
}

/**Will return canonical values
 *
 * Despite parameters for setting the root key used for the path, the returned transaction*Key
 * values are straight from YjsDocContext and are for convenience only
 * @param param0
 * @returns
 */
export function useLightweightTransaction<TValue extends Snapshot>({ parentPath, myPath, ydocForceKey, bindToMetaKey }:{
  parentPath?: string,
  myPath?: string,
  ydocForceKey?: string, // Does not contribute to 'transactionRootKey' returned
  bindToMetaKey?: boolean
}) {

  const { ydoc, transactionRootKey, transactionMetaRootKey  } = useContext(YjsDocContext);

  const { bindState, status } = useImmerYjs<TValue>(ydoc, ydocForceKey || (bindToMetaKey && transactionMetaRootKey) || transactionRootKey || MasterRootKey.Data);

  const fullPath = fullPathJoin({ parentPath, myPath });

  const { data: data } = bindState(state => getValueByPath(fullPath, state, true));

  return { value: data as Maybe<TValue>, status, fullPath, transactionRootKey, transactionMetaRootKey };
}
