import { SigningSessionFieldType } from '@property-folders/contract';
import { forwardRef, RefObject, useEffect, useState } from 'react';
import { ScrollToNextFieldEvent } from './SigningProcess';
import { useBreakpointValue } from '@property-folders/components/hooks/useBreakpointValue';
import { Predicate } from '@property-folders/common/predicate';
import { reaformsOrange } from '@property-folders/common/visual';

type DivProps = React.HTMLProps<HTMLDivElement>;

export const SignHere = forwardRef<HTMLDivElement, DivProps>((props, ref) => {
  const classes = new Set((props.className || '')
    .split(' ')
    .map(s => s.trim())
    .filter(Predicate.isNotNull));
  const bg = props.style?.backgroundColor || reaformsOrange;
  return <div
    ref={ref}
    {...props}
    className={[...classes].join(' ')}
    style={{
      clipPath: 'polygon(0 0, 85% 0, 100% 50%, 85% 100%, 0 100%)',
      color: 'white',
      backgroundColor: bg,
      borderColor: bg,
      width: '100px',
      height: '36px',
      ...props.style
    }}
  />;
});

interface InteractiveSignHereProps extends DivProps {
  allFieldsAreFilled: boolean;
  finish: () => void;
  scrollToNextField: () => ScrollToNextFieldEvent;
  scrollContainerRef: RefObject<Element>;
  brand: {
    color: string;
    bg: string;
  }
}

// sign here:
// - begin: "start"
// - user scrolls: "next"
// - user clicks: scroll to location and "sign" or "initial", after delay, update position

export const InteractiveSignHere = forwardRef<HTMLDivElement, InteractiveSignHereProps>((props, ref) => {
  const widthPx = useBreakpointValue({ base: 50, md: 65, lg: 100 }, 50);
  const fontSize = useBreakpointValue({ base: '10px', md: '12px', lg: '16px' }, '10px');
  const heightPx = widthPx / 3;
  const leftPx = widthPx * -0.85;
  // to appear in the center of the scroll area, apply this.
  // except for first/last page, this would normally be sufficient to line the pointer up with the field to fill
  // future: on a timer, detect if the pointer is too far away from the field and set the height
  const defaultTop = `calc(50vh - var(--constant-sign-here-offset) - ${heightPx / 2}px)`;
  const [isSystemScrolling, setIsSystemScrolling] = useState(false);
  const [action, setAction] = useState('Start');

  useEffect(() => {
    if (!props.scrollContainerRef.current) {
      return;
    }

    // there isn't a scroll end event, and there isn't a way to determine if the scroll is caused by the user or js.
    // so... we have to do it ourselves with timers and state tracking
    let timer: NodeJS.Timeout | null = null;
    const handler = () => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        setIsSystemScrolling(false);
      }, 150);

      if (!isSystemScrolling) {
        setAction('Next');
      }
    };
    props.scrollContainerRef.current.addEventListener('scroll', handler);
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
      props.scrollContainerRef.current?.removeEventListener('scroll', handler);
    };
  }, [props.scrollContainerRef, isSystemScrolling]);

  const clickHandler = () => {
    if (props.allFieldsAreFilled) {
      props.finish();
      return;
    }

    setIsSystemScrolling(true);
    const { type, didScroll } = props.scrollToNextField();
    if (!didScroll) {
      setIsSystemScrolling(false);
    }

    switch (type) {
      case SigningSessionFieldType.Initials:
        setAction('Initial');
        break;
      case SigningSessionFieldType.Signature:
        setAction('Sign');
        break;
      case SigningSessionFieldType.Text:
        setAction('Fill');
        break;
      default:
        setAction('Next');
        break;
    }
  };

  const bg = props.brand?.bg || reaformsOrange;
  const color = props.brand?.color || 'white';

  return <SignHere
    ref={ref}
    style={{
      position: 'absolute',
      zIndex: 2,
      left: `${leftPx}px`,
      width: `${widthPx}px`,
      height: `${heightPx}px`,
      top: defaultTop,
      cursor: 'pointer',
      backgroundColor: bg,
      color: color
    }}
    onClick={clickHandler}>
    <div
      className={'d-flex flex-column justify-content-center align-items-center h-100'}
    >
      <b style={{ marginLeft: '-15px', fontSize: fontSize }}>
        {props.allFieldsAreFilled ? 'Finish' : action}
      </b>
    </div>
  </SignHere>;
});
