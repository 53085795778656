import { ReactElement, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { FormCheckInputProps } from 'react-bootstrap/FormCheckInput';
import { v4 } from 'uuid';
import clsJn from '@property-folders/common/util/classNameJoin';

type TextClickCheckProps = FormCheckInputProps & {
  inline?: boolean
  label?: string|ReactElement
  onSelected: React.ChangeEventHandler<HTMLInputElement> | undefined
  markVaried?: boolean | any
};

// Doesn't look like it does much, but having an ID is what makes the text clickable
export function TextClickCheck ({ name, inline, onSelected, label, title, markVaried, ...rest }: TextClickCheckProps) {
  const id = useMemo(()=>{
    return v4();
  }, []);
  return <Form.Check
    id={id}
    label={label && <span className={clsJn(markVaried && 'varied-control')} title={title}>{label}</span>}
    inline={inline}
    key={name}
    name={name}
    onChange={onSelected}
    {...rest}
  />;
}
