import { Alert, Button, Modal } from 'react-bootstrap';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import React, { useContext, useMemo, useState } from 'react';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { FormSigningState, MaterialisedPropertyData, TransactionMetaData } from '@property-folders/contract';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { FormUtil } from '@property-folders/common/util/form';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import { useMediaQuery } from 'react-responsive';
import { BP_MINIMA } from '@property-folders/common/data-and-text/bootstrapBreakpoints';
import { AsyncButton } from '@property-folders/components/dragged-components/AsyncButton';
import { FormContext } from '@property-folders/components/context/FormContext';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { BaseAjaxResponse, LegacyApi } from '@property-folders/common/client-api/legacyApi';
import { releaseSubscriptionForm } from '@property-folders/signing/src/util/api';
import { CustomiseMessageStandalone } from '../signing/CustomiseMessage';
import { reaformsOrange } from '@property-folders/common/visual';
import { Predicate } from '@property-folders/common/predicate';
import { AuthApi } from '@property-folders/common/client-api/auth';

export function ReleaseToAgentModal({ onClose }: { onClose: () => void }) {
  const { ydoc: yDoc, transactionMetaRootKey } = useContext(YjsDocContext);
  const { formId, formName: formCode } = useContext(FormContext);
  const { value: transRoot } = useLightweightTransaction<MaterialisedPropertyData>({ 'myPath': '' });
  const { bindState: metaBindState } = useImmerYjs<TransactionMetaData>(yDoc, transactionMetaRootKey);
  const { data: meta } = metaBindState<TransactionMetaData>(m => m);
  const form = useMemo(() => FormUtil.getFormState(formCode, formId, meta), [meta]);
  const narrowMode = useMediaQuery({ maxWidth: BP_MINIMA.sm });
  const headlineVal = generateHeadlineFromMaterialisedData(transRoot, narrowMode);
  const [show, setShow] = useState(true);
  const [releaseMessage, setReleaseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const agentName = sessionInfo?.name;

  const handleCancel = () => {
    setShow(false);
    onClose();
  };

  const releaseForm = async () => {
    if (!form?.subscription?.documentId || !transRoot?.id) return;

    try {
      let result;
      if ((form.signing?.state || FormSigningState.None) === FormSigningState.None) {
        result = await LegacyApi.ajax<{
          link: string
        } & BaseAjaxResponse>('generatepdf', {
          DocumentID: form.subscription.documentId.toString(),
          Download: 'true'
        });
      }

      const res = await releaseSubscriptionForm(transRoot?.id, formCode, formId, result?.link, releaseMessage);
      if (res?.errorMessage) {
        setErrorMessage(res.errorMessage.toString());
      } else {
        handleCancel();
      }
    } catch (e) {
      setErrorMessage('There was a problem releasing the document. Please try again.');
    }
  };

  return <Modal show={show} size='lg' onHide={handleCancel}>
    <Modal.Header closeButton>
      <div className='d-flex flex-column'>
        <Modal.Title>Release {FormTypes[formCode].shortLabel || FormTypes[formCode].label} to {form?.order?.source?.sourceEntityName||'Agent'}</Modal.Title>
        <span>{headlineVal}</span>
      </div>
    </Modal.Header>
    <Modal.Body>
      {errorMessage && <Alert variant='danger' dismissible onClose={() => setErrorMessage('')}>{errorMessage}</Alert>}
      <div className={'px-3'}>
        <div className="mb-4 email-preview-container d-flex justify-content-center">
          <div className="centerline">
            <div className="whitebox">
              <div><img src="/legacy/images/reaforms_logo_strap.svg" alt="reaforms" style={{ maxHeight: '40px' }}></img></div>
              <div className="brand-box" style={{ backgroundColor: reaformsOrange, color: 'white' }}>
                <p className="mt-2">The Form 1 - Vendor’s Statement that you ordered for {headlineVal} has been released to your property folder by {agentName} at Eckermann Property Forms.</p>
                <div className="d-flex justify-content-center">
                  <div className="fake-button" style={{ backgroundColor: 'white', color: reaformsOrange }}>VIEW</div>
                </div>
              </div>
              <div className={'mt-4'}>
                <p>{releaseMessage?.split(/\n\n+/).filter(Predicate.isTruthy).map(para =>
                  <p>{para.split(/\n/).filter(Predicate.isTruthy).map((line, idx, { length }) => idx < length - 1 ? <>{line}<br/></> : line)}</p>
                )}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomiseMessageStandalone config={{ content: releaseMessage }} onConfigChange={value => setReleaseMessage(value.content || '')}/>
    </Modal.Body>
    <Modal.Footer>
      <Button variant='outline-secondary' onClick={onClose}>Cancel</Button>
      <AsyncButton title={'Release to Agent'} onClick={releaseForm}>Release</AsyncButton>
    </Modal.Footer>
  </Modal>;
}
