import './Annexures.scss';
import { useTransactionField } from '../../hooks/useTransactionField';
import { Annexure } from '@property-folders/contract';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import React, { useCallback, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import clsJn from '@property-folders/common/util/classNameJoin';
import { CollectionRemoveButton } from './CollectionRemoveButton';
import { WrField } from './CommonComponentWrappers';
import { LineageContext } from '../../hooks/useVariation';
import { EditorListChildProps } from './CollectionEditor';

export function AnnexureEditor(props: TransactionConsumerProps & EditorListChildProps & {
  myIndex: number
  onDelete: <T = any>(element: T)=>void
  onDrop: (file: File[], idx: number)=>void
  overrideValue: Annexure
}) {
  const { variationsMode } = useContext(LineageContext);

  const { overrideValue, ...restProps } = props;
  const transact = useTransactionField<Annexure>({ ...restProps, bindToMetaKey: true });
  const { transactionMetaRootKey } = transact;
  const { value: annexure, fullPath, handleRemove } = overrideValue && variationsMode
    ? { value: overrideValue, handleRemove: restProps.onDelete, fullPath: '' }
    : transact;

  const handleDrop = useCallback((acceptedFiles: File[]) => {
    props.onDrop(acceptedFiles, props.myIndex);
  }, [props.myIndex]);

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    onDrop: handleDrop,
    noClick: true,
    accept: { 'application/pdf': [] }
  });

  return (
    <div {...getRootProps()} className={clsJn('Annexure w-100 d-flex flex-row', isDragAccept && 'drop-accept')} tabIndex={-1}>
      <input {...getInputProps()} />
      <div className={'annexure-label d-flex justify-content-center align-items-center'} style={{ flex: '0 0 40px' }}>{annexure?.label}</div>
      <div style={{ flex: '1 1 auto' }} >
        {overrideValue
          ? <div className='d-flex align-items-center fs-5 h-100'><div className='static-annexure-title'>{annexure?.name}</div></div>
          : <WrField.Control disabled={annexure.noEditRemove} bindToMetaKey={true} parentPath={fullPath} myPath={'name'} name={'name'} className='fs-5'/>
        }
      </div>
      <div className={'d-flex justify-content-end align-items-center delete-div'} style={{ flex: '0 0 40px' }}>
        <CollectionRemoveButton removable={props.removable ?? true} onRemove={() => {
          handleRemove();
          props.onDelete?.(annexure);
        }}/>
      </div>
    </div>
  );
}
