import {
  ExtraFormCode,
  FormCode, FormCodeUnion,
  FormInstanceSigning,
  FormSigningState,
  SigningParty,
  SigningPartyType
} from '@property-folders/contract';
import { useMemo } from 'react';
import { Predicate } from '@property-folders/common/predicate';
import { WizardSidebarSubsetProps } from '@property-folders/components/dragged-components/Wizard/WizardStepPage';
import {
  FormTypes,
  mapSigningPartySourceTypeToCategory,
  PartyCategory, partyCategoryToLabelStrings
} from '@property-folders/common/yjs-schema/property/form';
import { byMapperFn } from '@property-folders/common/util/sortComparison';
import { getSigningOrderVersion, SigningOrderVersion } from '@property-folders/common/util/form';

type PartyFilter = (parties: SigningParty[]) => SigningParty[];
const defaultFilter: PartyFilter = (a)=>a;

export interface PartyGroup {
  order: number;
  label: string;
  labels: { singular: string, plural: string };
  type: PartyCategory;
  key: string;
  icon: string;
  parties: SigningParty[];
}

const defaultPartyCategoryOrder: PartyCategory[] = ['purchaser', 'vendor', 'agent'];

export function useSigningNavProps({
  signing,
  formCode,
  signingPartyFilter = defaultFilter,
  titleOverride
}: {
  signing?: FormInstanceSigning,
  formCode: string
  signingPartyFilter?: PartyFilter
  titleOverride?: string | JSX.Element
}) {
  const serveToPurchaser = FormTypes[formCode].serveToPurchaser || false;
  const signingState = signing?.state || FormSigningState.None;
  const parties = signingPartyFilter(signing?.parties || []);
  const allPartyIdMunge = parties.map(party=> party.id).sort().join('');
  const allPartyTypeMunge = parties.map(party => party.type).sort().join('');
  const allPartyOrderMunge = parties.map(party => party.signingOrderSettings?.order?.toString() || party.id).join('');
  const allPartyDistributionMunge = parties.map(party => JSON.stringify(party.distributionState)).join('');
  const orderMunge = (signing?.signingOrderSettings || [])
    .map(x => `${x.type}:${x.order}`).join(';');
  return useMemo(() => {
    const showConfiguration = signingState === FormSigningState.Configuring;
    const showSigningSession = signingState === FormSigningState.OutForSigning
      || signingState === FormSigningState.OutForSigningPendingUpload
      || signingState === FormSigningState.OutForSigningPendingServerProcessing
      || signingState === FormSigningState.Signed
      || signingState === FormSigningState.SignedPendingUpload
      || signingState === FormSigningState.SignedPendingDistribution;

    const signingMain: WizardSidebarSubsetProps = {
      name: 'sign',
      iconPack: 'material-symbols',
      icon: signingState === FormSigningState.Signed ? 'order_approve' : 'order_play',
      label: titleOverride ?
        titleOverride
        : signingState === FormSigningState.Signed
          ? 'Signing complete'
          : signingState === FormSigningState.SignedPendingUpload || signingState === FormSigningState.SignedPendingDistribution
            ? 'Signing being executed'
            : 'Signing in progress'
    };

    const signingOrderVersion = getSigningOrderVersion(signing);
    const sortedPartyGroups = signingOrderVersion === SigningOrderVersion.Grouped
      ? getPartyGroups({
        parties,
        signing,
        formCode: formCode as FormCodeUnion,
        showSigningSession
      })
      : undefined;

    return {
      showSigningSession,
      showConfiguration,
      showGeneralEmailConfig: parties.some(p => p.type === SigningPartyType.SignOnline),
      signingOrderVersion,
      partyGroups: sortedPartyGroups,
      signingMainProps: showSigningSession
        ? signingMain
        : undefined,
      serveToPurchaserProps: serveToPurchaser
        ? {
          label: `Serve ${[FormCode.RSC_ContractOfSale, ExtraFormCode.SCV_ContractOfSaleVariation].includes(formCode) ? 'Form 1 ' : '' }to Purchaser`,
          name: 'serve_purchaser',
          icon: 'send'
        }
        : undefined,
      signingSessionWizardPropsForSidebar: [
        showSigningSession && signingMain,
        ...(sortedPartyGroups ?? []).map(pg => ({
          label: pg.label,
          name: pg.key,
          icon: pg.icon
        })),
        serveToPurchaser && {
          label: `Serve ${[FormCode.RSC_ContractOfSale, ExtraFormCode.SCV_ContractOfSaleVariation].includes(formCode) ? 'Form 1 ' : '' }to Purchaser`,
          name: 'serve_purchaser',
          icon: 'send'
        }
      ].filter(Predicate.isNotNull) as WizardSidebarSubsetProps[]
    };
  }, [allPartyIdMunge, allPartyDistributionMunge, signingState, allPartyTypeMunge, orderMunge, signing?.useSigningOrder, allPartyOrderMunge, signing?.signingOrderVersion]);
}

function getPartyGroups({
  parties,
  signing,
  showSigningSession,
  formCode
}: {
  parties: SigningParty[],
  signing?: FormInstanceSigning,
  showSigningSession: boolean,
  formCode: FormCodeUnion
}) {
  const partyCategories = FormTypes[formCode].parties?.length
    ? FormTypes[formCode].parties?.map(p => p.type) || defaultPartyCategoryOrder
    : defaultPartyCategoryOrder;
  const formPartyCategoryOrder = new Map(partyCategories
    .map((cat, index) => [cat, index]));
  const groupedParties = new Map<PartyCategory, PartyGroup>();
  const hasAuthRep = parties.some(p => p.source.isAuthRep);

  parties.forEach((party) => {
    if (signing?.session?.partySourceTypeRestriction && !signing.session.partySourceTypeRestriction.includes(party.source.type)) {
      return;
    }
    const type = mapSigningPartySourceTypeToCategory(party.source.type);
    if (!type) {
      return;
    }
    if (showSigningSession && party.ignoreForSigning) {
      return;
    }

    const group = groupedParties.get(type);
    const partyClone = structuredClone(party);
    if (group) {
      group.parties.push(partyClone);
    } else {
      const labels = partyCategoryToLabelStrings(type, hasAuthRep);
      const defaultOrder = 100 + (type ? formPartyCategoryOrder.get(type) || 0 : 0);
      groupedParties.set(type, {
        order: decidePartyGroupOrder(type, signing, defaultOrder),
        type,
        label: labels.singular,
        labels,
        parties: [partyClone],
        key: `sign_${type}`,
        icon: getIconForType(type)
      });
    }
  });

  const sortedPartyGroups = [...groupedParties.values()]
    .sort(byMapperFn(x => x.order));
  for (const pg of sortedPartyGroups) {
    pg.parties.sort(byMapperFn(x => x.signingOrderSettings?.order || 0));
  }

  return sortedPartyGroups;
}

function getIconForType(type: PartyCategory) {
  switch (type) {
    case 'agent':
      return 'real_estate_agent';
    case 'purchaser':
      return 'shopping_cart';
    case 'vendor':
    default:
      return 'person';
  }
}

function decidePartyGroupOrder(type: PartyCategory, signing: FormInstanceSigning | undefined, defaultOrder: number): number {
  if (!signing?.useSigningOrder) return defaultOrder;
  if (!signing.signingOrderSettings?.length) return defaultOrder;
  const match = signing.signingOrderSettings.find(s => s.type === type);
  return match
    ? match.order
    : defaultOrder;
}
