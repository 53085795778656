/* eslint-disable quotes */
import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { PDFDocumentProxy } from 'pdfjs-dist';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { PDFPageProxy } from 'pdfjs-dist/types/src/display/api';
import { Button, Form } from 'react-bootstrap';
import { Icon } from '../Icon';
import clsJn from '@property-folders/common/util/classNameJoin';
import { PDFLoadStateContext, PDFLoadStateSetterContext } from '@property-folders/components/context/pdfLoadStateContext';
import { difference, isArray, clamp } from 'lodash';
import { useSize } from '@property-folders/components/hooks/useSize';

import './PDFViewer.scss';
import { useMergeRefs } from '@property-folders/components/hooks/useMergeRefs';
import {
  A4_ASPECT_RATIO_HW,
  A4_ASPECT_RATIO_WH,
  A4_WIDTH_INCHES
} from '@property-folders/common/util/pdfgen/measurements';
import { useBreakpointValue } from '@property-folders/components/hooks/useBreakpointValue';
import { mapMutateIfSet, setMutateIfAdd, setMutateIfDelete, tryParseInt } from '@property-folders/common/util';
import { ArrayUtil } from '@property-folders/common/util/array';
import { byNumericValueAsc } from '@property-folders/common/util/sortComparison';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { DocumentViewFallback, PreviewFallback } from '../../display/errors/pdf';
import { CoordinateMath } from '@property-folders/common/util/coords';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

interface PDFViewerProps {
  pdfUrl: string | { id: string, name: string, url: string }[],
  bookmark: string,
  filename?: string,
  toolbarRight?: React.ReactNode,
  toolbarBottom?: React.ReactNode,
  offsetRightByDragBar?: boolean,
  scrollContainerRef?: React.RefObject<HTMLElement>,
  allowPrint?: boolean,
  renderTextLayer?: boolean,
  onRenderSuccess?: () => void,
  zoomMode?: ZoomMode,
  activeViews?: number,
  useAdvancedMode?: boolean,
  standalonePreview?: boolean,
  onDocumentRegenerate?: () => Promise<string>
  useLoadSuccessForCompletion?: boolean,
  onDownloadClick?: () => Promise<void>
  pageWrapElement?: PageWrapElementFunc,
  allowDownload?: boolean
}

async function makeSameOrigin(url: string) {
  if (!url) return url;
  const parsed = url.startsWith('blob:')
    ? new URL(url.replace('blob:', ''))
    : url.startsWith('/')
      ? new URL(window.origin + url)
      : new URL(url);
  const location = window.location;
  // same origin check
  if (parsed.host === location.host && parsed.protocol === location.protocol && parsed.port === location.port) return url;

  const response = await fetch(url);
  return URL.createObjectURL(await response.blob());
}

export interface PDFViewerRefProps {
  applyZoomSteps: (steps: number) => void;
  setZoomValue: (value: number) => void;
  getZoomValue: () => number;
  getPageDimensions: (index: number) => undefined | PageDimensions;
}

const zoomSteps = [
  1/4, 1/3, 1/2, 2/3, 3/4, 0.8, 0.9,
  1,
  1.1, 1.2, 1.25, 1.5, 1.75, 2,
  2.5, 3, 4, 5
];
export const zoomMin = zoomSteps[0];
export const zoomMax = zoomSteps[zoomSteps.length - 1];

function getNextZoom(currentZoom: number, steps: number): number {
  if (steps === 0) return currentZoom;
  const currentIndex = zoomSteps.findIndex(x => x >= currentZoom);
  const nextIndex = currentIndex + steps;

  if (nextIndex < 0) return zoomMin;
  if (nextIndex >= zoomSteps.length) return zoomMax;
  return zoomSteps[nextIndex];
}

export enum ZoomMode {
  // recalc/reset if the window changes size, e.g. embedded mode.
  Automatic,
  // the user has the zoomies, e.g. preview mode.
  Manual
}

/**
 * @param compareRatio if landscapes present _HW, else _WH
 */
function calculateDisplayPageWidth(width: number, height: number, compareRatio: number) {
  if (!width) return 0;
  if (!height) return 0;
  const aspectRatio = width / height;
  return aspectRatio > compareRatio
    ? height * compareRatio
    : width;
}

// Essentially a fudge factor for modern screens being more dense generally than olde days,
// that are not reflected in the DPR
const MODERN_SCREEN_SCALE_FACTOR = 1.1;

function roughA4MaxWidth (realUnitsSizeTestDiv: HTMLDivElement) {

  // This returns the virtual pixel size, and it'll probably be 96 PPI. Browser should take care of the rest
  const width1InchInPx = realUnitsSizeTestDiv.offsetWidth;
  const a4WidthPix = A4_WIDTH_INCHES * width1InchInPx * MODERN_SCREEN_SCALE_FACTOR;
  return a4WidthPix;
}

const LOCALSTORAGE_ZOOM_KEY_WIZARDSPLIT = 'reaformsPreviewLastExplicitZoom-advanced';
const LOCALSTORAGE_ZOOM_KEY_WIZARDFULLPREVIEW = 'reaformsPreviewLastExplicitZoom-preview';
const LOCALSTORAGE_ZOOM_KEY_STANDALONE = 'reaformsPreviewLastExplicitZoom-subscription';

export const PDFViewer = forwardRef<PDFViewerRefProps, PDFViewerProps>(function PDFViewer(props: PDFViewerProps, ref) {
  return <ErrorBoundary FallbackComponent={PreviewFallback}>
    <PDFViewerUnwrapped {...props} ref={ref}/>
  </ErrorBoundary>;
});

const PDFViewerUnwrapped = forwardRef<PDFViewerRefProps, PDFViewerProps>(function PDFViewerFunc({
  pdfUrl,
  bookmark,
  filename: fileName,
  toolbarRight,
  offsetRightByDragBar,
  toolbarBottom,
  scrollContainerRef,
  allowPrint,
  renderTextLayer,
  activeViews = 2,
  useAdvancedMode = false, // In Wizard, is enabled when in split view
  standalonePreview = true,
  onDocumentRegenerate,
  useLoadSuccessForCompletion,
  onDownloadClick,
  pageWrapElement,
  allowDownload: allowDownloadRaw
}: PDFViewerProps, ref) {
  const dpiGuessDiv = useRef<HTMLDivElement>(null);
  const pdfPreviews = isArray(pdfUrl) ? pdfUrl : [{ id: '', name: '', url: pdfUrl }];
  const { setAwaitingLoadCompletion } = useContext(PDFLoadStateSetterContext);
  const { awaitingLoadCompletion } = useContext(PDFLoadStateContext);
  const [views, setViews] = useState<string[]>((new Array(activeViews)).fill(pdfUrl));
  const [activeView, setActiveView] = useState(0);
  const [activePdf, setActivePdf] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [baseDisplayWidth, setBaseDisplayWidth] = useState(0);
  const [zoomAdvanced, setZoomAdvanced] = useState<number>(1);
  const [zoomPreview, setZoomPreview] = useState<number>(1);
  const [zoomAlone, setZoomAlone] = useState<number>(1);
  const zoom = standalonePreview ? zoomAlone : useAdvancedMode ? zoomAdvanced : zoomPreview;
  const setZoom = standalonePreview ? setZoomAlone : useAdvancedMode ? setZoomAdvanced : setZoomPreview;
  const [localstorageUpdated, triggerLocalstorageUpdater] = useState({});
  const [zoomChangedAdvanced, setZoomChangedAdvanced] = useState(false);
  const [zoomChangedPreview, setZoomChangedPreview] = useState(false);
  const zoomChanged = useAdvancedMode ? zoomChangedAdvanced : zoomChangedPreview;
  const setZoomChanged = useAdvancedMode ? setZoomChangedAdvanced : setZoomChangedPreview;
  const containerRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergeRefs(containerRef, scrollContainerRef);
  const { width, height } = useSize(containerRef);
  const printRef = useRef<HTMLIFrameElement>(null);
  const [printFrameLoaded, setPrintFrameLoaded] = useState(false);
  const [printUrl, innerSetPrintUrl] = useState<string | undefined>(undefined);
  const [printClicked, setPrintClicked] = useState(false);
  const allowDownload = typeof allowDownloadRaw === 'undefined' || allowDownloadRaw;
  const setPrintUrl = (newUrl: string | undefined) => {
    if (!newUrl) setPrintFrameLoaded(false);
    innerSetPrintUrl(newUrl);
  };
  const [generatingPrint, setGeneratingPrint] = useState(false);
  const [generatingDownload, setGeneratingDownload] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [hasLandscape, setHasLandscape] = useState(false);

  useEffect(() => {
    // do stuff when width changes
    const usedZoomMode = localStorage.getItem(standalonePreview ? LOCALSTORAGE_ZOOM_KEY_STANDALONE : useAdvancedMode ? LOCALSTORAGE_ZOOM_KEY_WIZARDSPLIT : LOCALSTORAGE_ZOOM_KEY_WIZARDFULLPREVIEW);

    const savedZoomParams = usedZoomMode && JSON.parse(usedZoomMode);

    const sizingTestDiv = dpiGuessDiv?.current;
    if (!sizingTestDiv) return;
    const previewWantedWidth = calculateDisplayPageWidth(width, height, hasLandscape ? A4_ASPECT_RATIO_HW : A4_ASPECT_RATIO_WH);
    const a4BaseWidth = roughA4MaxWidth(sizingTestDiv);
    setBaseDisplayWidth(a4BaseWidth);

    let advancedZoom = a4BaseWidth / width;
    if (width < a4BaseWidth) {
      advancedZoom = 1;
    }

    let previewZoom = previewWantedWidth / width;
    if (width < previewWantedWidth && width < a4BaseWidth) {
      previewZoom = 1;
    }

    if (savedZoomParams) {
      if (width === 0) {
        return;
      }
      const newZoom = savedZoomParams.zoomValue * (savedZoomParams.viewportWidth||width) / width;
      setZoomChanged(true);
      setZoom(newZoom);
      return;
    }

    if (previewWantedWidth && !zoomChanged) {
      const zoomMult = hasLandscape ? A4_ASPECT_RATIO_WH : 1;
      const newZoom = useAdvancedMode
        ? advancedZoom
        : previewZoom;
      setZoom(newZoom * zoomMult);
    }
  }, [width, height, !!dpiGuessDiv?.current, standalonePreview, useAdvancedMode, localstorageUpdated, hasLandscape]);

  const applyZoomSteps = useCallback((steps: number) => {
    const previous = zoom;
    const next = getNextZoom(previous, steps);

    setZoomChanged(true);
    setZoom(next);

    localStorage.setItem(
      standalonePreview ? LOCALSTORAGE_ZOOM_KEY_STANDALONE : useAdvancedMode ? LOCALSTORAGE_ZOOM_KEY_WIZARDSPLIT : LOCALSTORAGE_ZOOM_KEY_WIZARDFULLPREVIEW,
      JSON.stringify({ viewportWidth: width, zoomValue: next })
    );
    triggerLocalstorageUpdater({});

    const container = containerRef.current;
    if (!container) return;
    const scaleDiff = next / previous;
    if (!scaleDiff) return;

    const { scrollTop, scrollHeight, scrollLeft, scrollWidth } = container;
    const pctScrollTop = scrollTop / scrollHeight;
    const pctScrollLeft = scrollLeft / scrollWidth;

    setTimeout(() => {
      // note: newScrollHeight / oldScrollHeight is approx. equal to newScale / oldScale
      // i.e. newScrollHeight ~= (newScale / oldScale) * oldScrollHeight
      // the approximation may be because gap between pages doesn't scale.
      // it may be that we can set the scroll top/left based on this difference, allowing to do it outside the setTimeout.
      // measure these at this point in time, because they should have changed by now.
      container.scrollTop = container.scrollHeight * pctScrollTop;
      container.scrollLeft = container.scrollWidth * pctScrollLeft;
    }, 1);
  }, [zoom, useAdvancedMode]);

  useEffect(() => {
    if (!containerRef.current) return;
    const container = containerRef.current;
    const wheel = (e: WheelEvent) => {
      const hover = container.matches(':hover');
      if (hover && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        // future: accumulate scroll ticks into multiple steps?
        if (e.deltaY < 0) {
          applyZoomSteps(1);
        } else {
          applyZoomSteps(-1);
        }
      }
    };
    window.addEventListener('wheel', wheel, { passive: false });
    return () => {
      window.removeEventListener('wheel', wheel);
    };
  }, [applyZoomSteps, containerRef]);

  const [documentViewRef, setDocumentViewRef] = useState<DocumentViewRefProps | null>(null);
  const documentViewRefHandler = useCallback((newRef: DocumentViewRefProps) => {
    setDocumentViewRef(newRef);
  }, []);

  useImperativeHandle(ref, () => {
    return ({
      applyZoomSteps,
      // will be used by pinch gesture
      setZoomValue: (value) => {
        setZoom(clamp(value, zoomMin, zoomMax));
      },
      getZoomValue: () => {
        return zoom;
      },
      getPageDimensions: index => {
        return documentViewRef?.getPageDimensions(index);
      }
    });
  }, [zoom, containerRef.current, documentViewRef]);

  useEffect(() => {
    const updatingRenderIndex = (activeView + 1) % activeViews;
    setViews(ps => {
      const rA = [...ps];
      rA[updatingRenderIndex] = pdfPreviews[activePdf]?.url;
      return rA;
    });
    setAwaitingLoadCompletion(true);
  }, [pdfUrl]);

  useEffect(()=>{
    setViews(new Array(activeViews).fill(pdfPreviews[activePdf]?.url));
    setAwaitingLoadCompletion(true);
  },[activePdf]);

  const print = useCallback(() => {
    setPrintClicked(true);
    if (onDocumentRegenerate) {
      // Clear URL so we remove the contentWindow ref, and allow a new print
      setPrintUrl(undefined);
      setGeneratingPrint(true);
      onDocumentRegenerate()
        .then(url=> makeSameOrigin(url))
        .then(url => setPrintUrl(url))
        .catch(console.error)
        .finally(() => setGeneratingPrint(false));
      return;
    }

    const url = views[activeView];
    makeSameOrigin(url)
      .then(url => setPrintUrl(url))
      .catch(console.error);
  }, [printRef.current?.contentWindow, onDocumentRegenerate, activeView]);

  const download = useCallback(() => {
    if (onDownloadClick) {
      onDownloadClick()
        .catch(console.error);
      return;
    }

    const expectedFileName = fileName || 'previewDownload.pdf';
    if (onDocumentRegenerate) {
      setGeneratingDownload(true);
      onDocumentRegenerate()
        .then(url => makeSameOrigin(url))
        .then(url => downloadObjectUrl(url, expectedFileName))
        .catch(console.error)
        .finally(() => setGeneratingDownload(false));
      return;
    }

    // download object
    const url = pdfPreviews[activePdf]?.url;
    if (url) {
      downloadObjectUrl(url, expectedFileName);
      return;
    }

    console.warn('download clicked but could not determine what to download');
  }, [onDownloadClick, onDocumentRegenerate, fileName, pdfPreviews[activePdf]?.url]);

  // there's a delay between clicking 'print' and being ready to show the print dialog because:
  // 1. generating a print url is an async call to either [regenerate the pdf] or [prepare the preview url], and then
  // 2. the iframe used for printing takes time to load the url and be 'ready' to display.
  // hence this effect is separate from the actual print click handler and detects when the iframe is ready to go.
  // another safety check is to confirm that the user actually did press 'print',
  // otherwise any variance in the iframe context where the pdf viewer instance is preserved
  // (e.g. from a parent page state change from voiding signing) would result in showing the print dialog again.
  useEffect(()=>{
    if (!printClicked) return;
    if (!printFrameLoaded) return;
    if (!printUrl) return;
    if (!printRef.current?.contentWindow) return;

    printRef.current.contentWindow.print();
    setPrintClicked(false);
  }, [!!onDocumentRegenerate, !!printUrl, printFrameLoaded, printClicked]);

  const [scrollObserver, setScrollObserver] = useState<IntersectionObserver | undefined>(undefined);
  //default the visible pages set, to the first page as the intersection observer doesnt fire initially
  const [visiblePages, setVisiblePages] = useState<Set<number>>(new Set([0]));
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        const pageNumber = tryParseInt((entry.target as HTMLElement).dataset.pageNumber, -1);
        if (entry.isIntersecting) {
          setVisiblePages(current => setMutateIfAdd(current, pageNumber));
        } else {
          setVisiblePages(current => setMutateIfDelete(current, pageNumber));
        }
      }
    });
    setScrollObserver(observer);
    return () => {
      observer.disconnect();
      setScrollObserver(undefined);
    };
  }, []);

  const handleAllPagesRendered = useCallback((rendererIdx: number) => {
    setActiveView(rendererIdx);
    setAwaitingLoadCompletion(false);
  }, []);

  const handleLoadSuccess = useLoadSuccessForCompletion
    ? () => {
      setAwaitingLoadCompletion(false);
    }
    : undefined;

  const onLandscape = useCallback((ls: boolean) => setHasLandscape(ls), []);

  return (<div onMouseOver={() => setIsMouseOver(true)} onMouseOut={() => setIsMouseOver(false)}>
    <div ref={dpiGuessDiv} style={{ position: 'fixed', left: '100%', top: '100%', width: '1in', height: '1in' }} />
    {allowPrint && printUrl && <iframe
      ref={printRef}
      id={'print-iframe'}
      src={printUrl}
      style={{ display: 'none' }}
      onLoad={()=>setPrintFrameLoaded(true)}
    >
    </iframe>}
    <div className='PDFViewerOuter'>

      <div className='PDFViewerToolbar'>
        <div style={{ width: '100%' }} className='toolbar-side side-left'>

          {allowDownload && !generatingDownload && <Button variant='secondary' className={'me-3'} onClick={download} title='Download print version'>
            <Icon name='download'/>
          </Button>}
          {generatingDownload && <div className="spinner-border spinner-border-sm text-light m-2 me-3" role="status" title='Generating Download...'>
            <span className="visually-hidden">Generating Download...</span>
          </div>}

          { !('ontouchstart' in window) && <>
            <Button className='d-md-inline' title="Zoom out" variant="secondary" onClick={() => applyZoomSteps(-1)}><Icon name="zoom_out"></Icon></Button>
            <Button className='d-md-inline' title="Zoom in" variant="secondary" onClick={() => applyZoomSteps(1)}>
              <Icon name="zoom_in"></Icon>
            </Button>
            <Button className='d-md-inline' title="Zoom auto" variant="secondary" onClick={() => {
              localStorage.removeItem(standalonePreview ? LOCALSTORAGE_ZOOM_KEY_STANDALONE : useAdvancedMode ? LOCALSTORAGE_ZOOM_KEY_WIZARDSPLIT : LOCALSTORAGE_ZOOM_KEY_WIZARDFULLPREVIEW );
              setZoomChanged(false);
              // if zoomChanged is already false, this will not trigger a render, as we are not
              // listening for localStrage events, so updating localstorage will not trigger the
              // rerender we would expect to reset zoom
              triggerLocalstorageUpdater({});
            }}>
              <Icon name="center_focus_weak" icoClass='reset-position-icon'></Icon>
            </Button>
          </>
          }

          {allowPrint && !generatingPrint && <Button title="Print" variant="secondary" onClick={print}><Icon name="printer"/></Button>}
          {generatingPrint && <div className="spinner-border spinner-border-sm text-light m-2" role="status" title='Generating Print...'>
            <span className="visually-hidden">Generating Print...</span>
          </div>}
          {pdfPreviews?.length > 1 &&
            <Form.Select className={'pt-0 pb-0 w-auto ms-auto'} style={{ height: '26px', fontSize: '13px' }} onChange={(e) => setActivePdf(Number(e.target.value))}>
              {pdfPreviews?.map((p,i) => <option key={i} value={i}>{p.name}</option>)}
            </Form.Select>}
          {<div className={'d-flex'} style={!awaitingLoadCompletion ? { visibility: 'hidden' } : {}}>
            <div className="spinner-border spinner-border-sm text-light m-2" role="status" title='Generating Preview...'>
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>}
        </div>
        <div className={clsJn('toolbar-side side-right ms-auto', offsetRightByDragBar && 'drag-bar-offset')}>
          {toolbarRight}
        </div>
      </div>
      <div className="document-swap-container" ref={mergedRef} tabIndex={-1}>
        <ErrorBoundary FallbackComponent={DocumentViewFallback}>
          {views.map((pdf, idxr) => <div
            key={idxr}
            className={clsJn('PDFContainer', idxr === activeView ? 'primary' : 'background')}
          >
            {/* We do need to stop the background renderer from looking up IDs, otherwise it will have
           an invalid document proxy while rendering a new one*/}

            <DocumentView
              ref={documentViewRefHandler}
              key={idxr}
              index={idxr}
              pdfUrl={pdf}
              bookmark={idxr === activeView ? bookmark : ''}
              zoom={zoom}
              onRenderComplete={handleAllPagesRendered}
              onLoadSuccess={handleLoadSuccess}
              containerWidth={width}
              containerHeight={height}
              renderTextLayer={renderTextLayer}
              scrollObserver={scrollObserver}
              visiblePages={visiblePages}
              useAdvancedMode={standalonePreview ? false : useAdvancedMode}
              isMouseOver={isMouseOver}
              pageWrapElement={pageWrapElement}
              onLandscape={onLandscape}
            />
            {toolbarBottom}
          </div>)}

        </ErrorBoundary>
      </div>
    </div>
  </div>);
});

async function calculateOffsetForDestination(documentProxy: PDFDocumentProxy, destination: any[]) {
  const pageNum = ((await documentProxy?.getPageIndex(destination[0])) || 0)+1;
  const page = await documentProxy?.getPage(pageNum);
  const pageHeightInPt = page?.originalHeight || 0; // Perhaps these values are actually set by react-pdf? Don't seem to be in the PageProxy definition
  const scaleFactor = page?.height/page?.originalHeight;
  // the ref positions from get destination seem to use the bottom left, not top left as the origin
  const intraPagetopOffset = (pageHeightInPt-destination[3])*scaleFactor;

  return { pageNum, intraPagetopOffset };
}

export interface PageDimensions {
  width: number,
  height: number,
  rotate: number
}

type PageWrapElementFunc = (props: React.PropsWithChildren<{
  pageIndex: number,
  // known after page load
  dimensions?: PageDimensions,
  zoom: number
}>) => ReactNode;

type DocumentViewRefProps = {
  getPageDimensions: (index: number) => undefined | PageDimensions;
};

type DocumentViewProps = PDFViewerProps & {
  index: number,
  zoom: number,
  onRenderComplete?: (index: number)=>void,
  containerWidth: number,
  containerHeight: number,
  scrollObserver: IntersectionObserver | undefined,
  visiblePages: Set<number>,
  useAdvancedMode: boolean,
  isMouseOver: boolean,
  onLoadSuccess?: () => void,
  onLandscape?: (present: boolean) => void
};
const DocumentView = forwardRef<DocumentViewRefProps, DocumentViewProps>(({
  index,
  pdfUrl: pdf,
  bookmark,
  onRenderComplete,
  zoom,
  renderTextLayer,
  scrollObserver,
  visiblePages,
  useAdvancedMode,
  containerWidth,
  containerHeight,
  isMouseOver,
  onLoadSuccess,
  pageWrapElement,
  onLandscape
}, ref) => {
  const maxAllowedPages = useBreakpointValue({ base: 6, md: 12, lg: 12 },  5);
  const [numPages, setNumPages] = useState<number|null>(null);
  // set of pages which have been rendered at least once
  const [renderedPages, setRenderedPages] = useState<Set<number>>(new Set());
  // essentially the list of loaded pages
  const [pageDimensions, setPageDimensions] = useState<Map<number, PageDimensions>>(new Map());
  // 0 => 1 at a time, 1 => 2 at a time
  const [scrollWait, setScrollWait] = useState(false);
  const [latestProxy, setLatestProxy] = useState<PDFDocumentProxy|null>(null);

  useImperativeHandle(ref, () => {
    return {
      getPageDimensions(index: number) {
        return pageDimensions.get(index);
      }
    };
  }, [pageDimensions]);

  //scroll PDF to bookmark - there maybe a better way to do this
  useEffect(()=> {
    setScrollWait(true);
  }, [numPages]);

  useEffect(() => {
    if (!bookmark) return;
    if (pageDimensions.size === numPages) {
      scrollToBookmark(bookmark);
    } else {
      setScrollWait(true);
    }
  }, [bookmark]);

  const pageNumbersToLoad = useMemo<Set<number>>(() => {
    if (!(numPages && visiblePages.size)) {
      return new Set(ArrayUtil.range(1, 1));
    }

    const sorted = [...visiblePages].sort(byNumericValueAsc);
    const mid = sorted[Math.floor(sorted.length / 2)];
    let start = Math.max(mid - maxAllowedPages/2, 1);
    const end = Math.min(start + maxAllowedPages, numPages);
    if (start > end) start = end;
    return new Set(ArrayUtil.range(end, start));
  }, [visiblePages, numPages]);

  /**
   *
   * @param bookmark
   * @param veryLatestProxy May be required if the set state is done in the same execution as when
   *  the document proxy is loaded
   * @returns
   */
  const scrollToBookmark = async (bookmark: string | undefined, veryLatestProxy?: PDFDocumentProxy) => {
    if (isMouseOver) return;

    const documentProxy = veryLatestProxy||latestProxy;
    if (!documentProxy) {
      return;
    }

    let destination;
    // Because this moves up the hierarchy, it is best to not make too broad field anchors,
    // otherwise the screen may scroll up to the start of the section and put the field you're
    // focussing on out of view
    let usedBookmarkString = bookmark || '';
    if (bookmark?.startsWith('field_focus_')) {
      let fieldPathSegs = bookmark.replace('field_focus_','').split('.');
      while (!destination && fieldPathSegs.length > 0) {
        usedBookmarkString = `field_focus_${fieldPathSegs.join('.')}`;
        destination = await documentProxy?.getDestination(usedBookmarkString);
        fieldPathSegs = fieldPathSegs.slice(0,fieldPathSegs.length-1);
      }
    } else {
      destination = await documentProxy?.getDestination(usedBookmarkString);
    }
    const endDest = await documentProxy?.getDestination(`${usedBookmarkString}_!END`);
    if (!destination) {
      return;
    }
    const scrollingElement = document.querySelector(`.PDFContainer.primary`)?.parentElement;

    const topBmPromise = calculateOffsetForDestination(documentProxy, destination);
    const bottomBmPromise = endDest ? calculateOffsetForDestination(documentProxy, endDest) : null;
    const { intraPagetopOffset, pageNum } = await topBmPromise;
    const { intraPagetopOffset: intraPageBottomOffset, pageNum: endPageNum } = bottomBmPromise
      ? await bottomBmPromise
      : { intraPagetopOffset: null, pageNum: null };

    const pageOffset = document.querySelector(`.PDFContainer.primary .react-pdf__Page[data-page-number="${pageNum}"]`)?.offsetTop;
    const fineTop = pageOffset + intraPagetopOffset;

    let fineBottom = fineTop + Math.max(300, (scrollingElement?.clientHeight||0)*0.6); // fallback value
    if (intraPageBottomOffset != null && endPageNum != null) {
      const pageOffset2 = document.querySelector(`.PDFContainer.primary .react-pdf__Page[data-page-number="${endPageNum}"]`)?.offsetTop;
      // +25 so the bottom can't be right near the bottom of the screen
      fineBottom = pageOffset2 + intraPageBottomOffset + 25;
    }
    if (!(fineTop > scrollingElement?.scrollTop && fineBottom < scrollingElement?.scrollTop+scrollingElement?.clientHeight)) {
      // Give 50px up top for a little extra context, but don't attempt to scroll beyond the top (0)
      const scrollToTopPosition = Math.max(fineTop - 50, 0);
      scrollingElement?.scrollTo({ top: scrollToTopPosition, behavior: 'smooth' });
    }
  };

  const onPageRenderSuccess = useCallback((page: PDFPageProxy, pageIdx: number) => {
    setRenderedPages(current => setMutateIfAdd(current, pageIdx));
    const [_, __, widthRaw, heightRaw] = page.view;
    const rotate = CoordinateMath.normaliseDegrees(page.rotate);

    const swapDims = (rotate === 90 || rotate === 270);
    const width = swapDims ? heightRaw : widthRaw;
    const height = swapDims ? widthRaw : heightRaw;

    setPageDimensions(current => mapMutateIfSet(
      current,
      pageIdx,
      { width, height, rotate },
      (a, b) => a.width === b.width && a.height === b.height && a.rotate === b.rotate));

    const element = pageEls.get(pageIdx + 1);
    if (!element) return;

    element.style.aspectRatio = (width / height).toString();
  }, [pdf]);
  const onPageLoadSuccess = useCallback((page: PDFPageProxy, pageIdx: number) => {
    const [_, __, widthRaw, heightRaw] = page.view;
    const rotate = CoordinateMath.normaliseDegrees(page.rotate);

    const swapDims = (rotate === 90 || rotate === 270);
    const width = swapDims ? heightRaw : widthRaw;
    const height = swapDims ? widthRaw : heightRaw;
    // console.log('page', idx, 'dims', width, height, rotate);
    setPageDimensions(current => mapMutateIfSet(
      current,
      pageIdx,
      { width, height, rotate },
      (a, b) => a.width === b.width && a.height === b.height && a.rotate === b.rotate));
  }, [pdf]);

  const onPageEl = useCallback((pageNumber: number, value: HTMLElement) => {
    setPageEls(current => mapMutateIfSet(current, pageNumber, value));
  }, []);

  const onDocumentLoadSuccess = useCallback((pdfProxy: PDFDocumentProxy) => {
    // Code to highlight what anchors have not been correctly linked.
    if (window && window.fnDev && window.fnDev.focusDebuggerEnabled) {
      pdfProxy.getDestinations().then(obj=>{
        const documentAnchors = Object.keys(obj).filter(k=>!(k.startsWith('bookmark_')||k.startsWith('field_focus_')||k.endsWith('_!END')));
        const inputAnchros = [...document.querySelectorAll('.scrollspy-target')].map(elem=>elem.dataset?.focusPath).filter(a=>a).filter(fp=>!fp.startsWith('field_focus_'));
        console.log('Missing in input form:', difference(documentAnchors, inputAnchros));
        console.log("Missing in pdf:", difference(inputAnchros, documentAnchors));
        console.log("Note: Conditional renders may cause false positives in missing anchor detection.");
      });
    }

    setNumPages(pdfProxy.numPages);
    setRenderedPages(new Set());
    setPageDimensions(new Map());
    setLatestProxy(pdfProxy);
    if (onLoadSuccess) {
      onLoadSuccess();
    }
  }, [pdf]);

  useEffect(() => {
    if ([...pageNumbersToLoad].every(page => renderedPages.has(page-1))) {
      onRenderComplete?.(index);
      if (scrollWait) {
        scrollToBookmark(bookmark);
        setScrollWait(false);
      }
    }
  },[renderedPages]);

  const [hasLandscape, setHasLandscape] = useState(false);
  useEffect(() => {
    for (const [_, { width, height }] of pageDimensions) {
      if (width > height) {
        setHasLandscape(true);
        return;
      }
    }
    setHasLandscape(false);
  }, [pageDimensions]);

  useEffect(() => {
    onLandscape?.(hasLandscape);
  }, [hasLandscape]);

  const [pageEls, setPageEls] = useState(new Map<number, HTMLElement>());

  return <div
    style={{
      width: '100%'
    }}>
    <Document
      file={pdf}
      onLoadSuccess={onDocumentLoadSuccess}
    >
      <React.Fragment key='pages'>
        {numPages && Array.from(new Array(numPages), (el, idx) => {
          const pageNode = (<CustomPage
            key={idx}
            idx={idx}
            dim={pageDimensions.get(idx)}
            containerWidth={containerWidth}
            containerHeight={containerHeight}
            renderContents={pageNumbersToLoad.has(idx + 1)}
            renderTexts={!!(renderTextLayer == null ? true : renderTextLayer)}
            zoom={zoom}
            scrollObserver={scrollObserver}
            onPageEl={onPageEl}
            onRenderSuccess={onPageRenderSuccess}
            onLoadSuccess={onPageLoadSuccess}
          />);

          return pageWrapElement
            ? pageWrapElement({
              pageIndex: idx,
              children: pageNode,
              dimensions: pageDimensions.get(idx),
              zoom
            })
            : pageNode;
        })}
      </React.Fragment>
    </Document>
  </div>;
});

function CustomPage({
  idx,
  scrollObserver,
  dim,
  onPageEl,
  renderContents,
  renderTexts,
  zoom,
  containerWidth,
  containerHeight,
  onRenderSuccess,
  onLoadSuccess
}: {
  idx: number,
  scrollObserver: IntersectionObserver | undefined,
  dim: PageDimensions | undefined,
  onPageEl: (pageNumber: number, value: HTMLElement) => void,
  renderContents: boolean,
  renderTexts: boolean,
  zoom: number,
  containerWidth: number,
  containerHeight: number,
  onRenderSuccess: (page: PDFPageProxy, idx: number) => void,
  onLoadSuccess: (page: PDFPageProxy, idx: number) => void
}) {
  const dpp = (window.devicePixelRatio || 1);
  const pageNumber = idx + 1;
  const isLandscape = (!!dim && dim.width > dim.height);

  const inputRef = useCallback((pg: HTMLDivElement | null) => {
    if (!scrollObserver) return;
    if (!pg) return;
    pg.dataset.pageIndex = idx.toString();
    pg.dataset.pageNumber = pageNumber.toString();
    scrollObserver.observe(pg);
    onPageEl(pageNumber, pg);
    if (dim && dim.height) {
      const isLandscape = (!!dim && dim.width > dim.height);
      pg.style.aspectRatio = (dim.width / dim.height).toString();
      pg.style.width = `calc(var(--scale-factor) * ${isLandscape ? dim.height : dim.width}px)`;
    } else {
      pg.style.width = '100%';
      pg.style.aspectRatio = A4_ASPECT_RATIO_HW.toString();
    }
  }, [scrollObserver, dim]);

  const onRender = useCallback((page: PDFPageProxy) => {
    onRenderSuccess(page, idx);
  }, [onRenderSuccess]);

  const onLoad = useCallback((page: PDFPageProxy) => {
    onLoadSuccess(page, idx);
  }, [onLoadSuccess]);

  return <Page
    inputRef={inputRef}
    renderMode={renderContents ? 'canvas' : 'none'}
    renderTextLayer={renderContents && renderTexts}
    renderAnnotationLayer={false}
    scale={zoom*dpp}
    width={isLandscape ? undefined : containerWidth/dpp || undefined}
    height={isLandscape ? containerWidth/dpp || undefined : undefined}
    key={`page_${pageNumber}`}
    pageNumber={pageNumber}
    onRenderSuccess={onRender}
    onLoadSuccess={onLoad}
  />;
}

function downloadObjectUrl(objectUrl: string, fileName: string) {
  const linkElement = document.createElement('a');
  linkElement.href = objectUrl;
  linkElement.setAttribute('download', fileName);
  document.body.appendChild(linkElement);
  linkElement.click();
  linkElement.parentNode?.removeChild(linkElement);
}
