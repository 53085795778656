import { useEffect, useState } from 'react';
import { useTimeout } from '../hooks/useTimeout';
import { formatTimestamp } from '@property-folders/common/util/formatting';

const rtf = new Intl.RelativeTimeFormat('en', {
  numeric: 'auto'
});
const YEAR_MILLISECONDS = 1000 * 60 * 60 * 24 * 365;
const MONTH_MILLISECONDS = 1000 * 60 * 60 * 24 * 31;
const WEEK_MILLISECONDS = 1000 * 60 * 60 * 24 * 7;
const DAY_MILLISECONDS = 1000 * 60 * 60 * 24;
const HOUR_MILLISECONDS = 1000 * 60 * 60;
const MINUTE_MILLISECONDS = 1000 * 60;
const SECOND_MILLISECONDS = 1000;

export enum SupportedRelativeTimeFormatUnit {
  year = 0,
  month = 1,
  week = 2,
  day = 3,
  hour = 4,
  minute = 5,
  second = 6
}

function calcHumanTimestampResult(unit: Intl.RelativeTimeFormatUnit, magnitudeMs: number, diffMs: number, absMs: number) {
  const text = rtf.format(Math.ceil(diffMs / magnitudeMs), unit);
  const next = 100 + magnitudeMs - (absMs % magnitudeMs);
  return {
    text,
    next
  };
}

function humanTimestampWithDelayUntilNextRender(
  timestampMs: number,
  maxInterval: SupportedRelativeTimeFormatUnit | -1 = -1,
  timeZone: string = 'Australia/Adelaide'
): {
  text: string,
  next: number
} {
  const nowMs = Date.now();
  const diffMs = timestampMs - nowMs;
  const absMs = Math.abs(diffMs);

  if (absMs < MINUTE_MILLISECONDS) {
    return { text: 'just now', next: MINUTE_MILLISECONDS - (absMs % MINUTE_MILLISECONDS) };
  }

  if (absMs < HOUR_MILLISECONDS - SECOND_MILLISECONDS && maxInterval < SupportedRelativeTimeFormatUnit.minute) {
    return calcHumanTimestampResult('minute', MINUTE_MILLISECONDS, diffMs, absMs);
  }

  if (absMs < DAY_MILLISECONDS - SECOND_MILLISECONDS && maxInterval < SupportedRelativeTimeFormatUnit.hour) {
    return calcHumanTimestampResult('hour', HOUR_MILLISECONDS, diffMs, absMs);
  }

  if (absMs < WEEK_MILLISECONDS - SECOND_MILLISECONDS && maxInterval < SupportedRelativeTimeFormatUnit.day) {
    return calcHumanTimestampResult('day', DAY_MILLISECONDS, diffMs, absMs);
  }

  if (absMs < MONTH_MILLISECONDS - SECOND_MILLISECONDS && maxInterval < SupportedRelativeTimeFormatUnit.week) {
    return calcHumanTimestampResult('week', WEEK_MILLISECONDS, diffMs, absMs);
  }

  if (absMs < YEAR_MILLISECONDS - SECOND_MILLISECONDS && maxInterval < SupportedRelativeTimeFormatUnit.month) {
    return calcHumanTimestampResult('month', MONTH_MILLISECONDS, diffMs, absMs);
  }

  if (maxInterval === -1) {
    return calcHumanTimestampResult('year', YEAR_MILLISECONDS, diffMs, absMs);
  }

  return { text: formatTimestamp(timestampMs, timeZone, false), next: Number.MAX_SAFE_INTEGER };
}

export function HumanTimestampText(props: ({ timestampMs: number } | { timestamp: Date }) & {
  maxInterval?: SupportedRelativeTimeFormatUnit,
  timeZone?: string;
}) {
  const timestampMs = 'timestampMs' in props ? props.timestampMs : props.timestamp.getTime();
  const [state, setState] = useState({ text: '', next: 0 });
  useTimeout(() => {
    if (!timestampMs) {
      return;
    }

    setState(humanTimestampWithDelayUntilNextRender(timestampMs, props.maxInterval, props.timeZone));
  }, state.next, timestampMs ?? 1);

  //force update when timestamp changes
  useEffect(() => {
    setState(humanTimestampWithDelayUntilNextRender(timestampMs, props.maxInterval, props.timeZone));
  }, [props?.timestamp, props?.timestampMs]);

  if (!timestampMs) {
    return <></>;
  }

  return <>{state.text}</>;
}
