import React, { useRef, useState } from 'react';
import { useLightweightTransaction } from '../hooks/useTransactionField';
import {
  AgentSessionInfoResult,
  ContentType,
  ExtraFormCode,
  FormCode,
  FormCodeUnion,
  FormFamilyState,
  FormInstance,
  Maybe,
  TransactionMetaData,
  FormOrderState,
  FormOrderType
} from '@property-folders/contract';
import { ErrorBoundary } from '../telemetry/ErrorBoundary';
import { GenericCardFallback } from '../display/errors/card';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { uploadForm1 } from './signing/Form1RequiredSection';
import { FormTypes, rsaaFamilyExecuted } from '@property-folders/common/yjs-schema/property/form';
import { useNavigate } from 'react-router-dom';
import { AnyAction, Store } from 'redux';
import { FileSync } from '@property-folders/common/offline/fileSync';
import * as Y from 'yjs';
import { handleNewFormOrder } from '@property-folders/web/src/pages/TransactionHomePage';
import { SubscriptionFormCode } from '@property-folders/common/subscription-forms';
import { handleNewForm } from '@property-folders/common/util/handleNewForm';
import { allVendorsSigned } from './signing/ServeToPurchaserSection';
import { useEntity } from '../hooks/useEntity';
import { EntitySettingsEntity } from '@property-folders/contract/yjs-schema/entity-settings';
import { InstanceCardBase } from './InstanceCardBase';
import { SplitIfManyButton } from './SplitIfManyButton';
import { processPdf } from '@property-folders/common/util/pdf/pdf-process';
import { FormWarningDialog, setupWarnIntercept, ShowWarnParams } from './Wizard/FormWarningDialog';
import { SpinnerButton } from './AsyncButton';
import { Icon } from './Icon';
import { IconPacks } from '@property-folders/common/types/IconProps';
import { FormInstanceSigningProgressBar } from './FormInstanceSigningProgressBar';
import { Button } from 'react-bootstrap';
import { FormInstanceInfoSnippets } from './FormInstanceInfoSnippets';
import { clickNoBubble } from '@property-folders/common/util/clickNoBubble';
import { Form1ReplaceModal } from '@property-folders/web/src/pages/forms/Form1ReplaceModal';

type ProcessingAction = '' | 'uploading' | 'ordering' | 'opening' | 'creating';
type Action = 'upload' | 'order' |'create';

export function Form1Card({
  fileSync,
  headline,
  propertyId,
  sessionInfo,
  store,
  ydoc,
  setShowServeToNewPurchaserParams
}: {
  fileSync?: FileSync,
  headline: string,
  propertyId?: string,
  sessionInfo?: AgentSessionInfoResult,
  store?: Store<unknown, AnyAction>,
  ydoc?: Y.Doc,
  setShowServeToNewPurchaserParams: (newState: { formCode: string, formId: string } | undefined) => void
}) {
  const navigate = useNavigate();
  const { value: rsaaFamily } = useLightweightTransaction<FormFamilyState>({
    parentPath: `formStates.${FormCode.RSAA_SalesAgencyAgreement}`,
    bindToMetaKey: true
  });
  const { value: family } = useLightweightTransaction<FormFamilyState>({
    parentPath: `formStates.${FormCode.Form1}`,
    bindToMetaKey: true
  });
  const { value: meta } = useLightweightTransaction<TransactionMetaData>({
    parentPath: '',
    bindToMetaKey: true
  });
  const localEntity = useEntity(meta?.entity?.id) as EntitySettingsEntity;

  const [forceFocus, setForceFocus] = useState(false);
  const [replaceForm1Modal, setReplaceForm1Modal] = useState<Action|undefined>(undefined);

  // latest is always the latest instance.
  // display is the latest instance when it makes sense to display it, and undefined otherwise.
  // this is mostly to hide the case where the form 1 order screen has not been cleanly closed.
  const { display, orphanOrder } = extractDisplayInstance(family?.instances);
  const canOrder = localEntity?.epfAgencyId;

  const navigateToForm = (instanceId: string, formCode: string) => {
    if (!propertyId) return;

    const formType = FormTypes[formCode];
    navigate(LinkBuilder.documentPath(
      {
        id: propertyId,
        nicetext: headline
      },
      {
        id: instanceId,
        nicetext: formType.label
      },
      !!formType.subscription));
  };

  const onOrderBase = async () => {
    if (orphanOrder?.order?.state === FormOrderState.ClientOrdering) {
      navigateToForm(orphanOrder.id, SubscriptionFormCode.SAF001V2_Form1);
      return;
    }

    const newId = await handleNewFormOrder(ydoc, SubscriptionFormCode.SAF001V2_Form1);
    if (!newId) {
      return;
    }
    navigateToForm(newId, SubscriptionFormCode.SAF001V2_Form1);
  };

  const onCreateBase = async () => {
    const newForm = await handleNewForm(ydoc, SubscriptionFormCode.SAF001V2_Form1 as FormCodeUnion, fileSync);
    if (!newForm) {
      return;
    }
    navigateToForm(newForm.formId, SubscriptionFormCode.SAF001V2_Form1);
  };

  const [showWarn, setShowWarn] = useState<Maybe<ShowWarnParams>>(undefined);
  const [processingAction, setProcessingAction] = useState<ProcessingAction>('');
  const disableActions = Boolean(processingAction);
  const onUploadFileSelected = async (data: Uint8Array) => {
    if (!ydoc) return;
    if (!propertyId) return;
    setProcessingAction('uploading');
    try {
      const applied = await uploadForm1({
        store,
        ydoc,
        propertyId,
        fileSync,
        sessionInfo,
        data
      });
      if (applied) {
        const formType = FormTypes[applied.formCode];
        navigate(LinkBuilder.documentPath(
          { id: propertyId, nicetext: headline },
          { id: applied.formId, nicetext: formType.label },
          !!formType.subscription));
      }
    } finally {
      setProcessingAction('');
    }
  };

  const uploadInput = useRef<HTMLInputElement | null>(null);
  const onUploadBase = () =>  {
    if (!uploadInput?.current) return;
    uploadInput.current.click();
  };

  const rsaaExecuted = rsaaFamilyExecuted(rsaaFamily);
  const title = 'Sales Agency Agreement not fully executed';
  const message = 'The Form 1 should not be created until the Sales Agency Agreement has been fully executed';
  const onOrder = setupWarnIntercept(() => {
    setProcessingAction('ordering');
    setShowWarn(undefined);
    onOrderBase().finally(() => setProcessingAction(''));
  }, rsaaExecuted ? undefined : (inner) => setShowWarn({
    formCode: SubscriptionFormCode.SAF001V2_Form1,
    title,
    message,
    actionName: 'order',
    continueFn: inner
  }));
  const onUpload = setupWarnIntercept(() => {
    setShowWarn(undefined);
    onUploadBase();
  }, rsaaExecuted ? undefined : (inner) => setShowWarn({
    formCode: ExtraFormCode.Form1Upload,
    title,
    message,
    actionName: 'upload',
    continueFn: inner
  }));
  const onCreate = setupWarnIntercept(() => {
    setProcessingAction('creating');
    setShowWarn(undefined);
    onCreateBase().finally(() => setProcessingAction(''));
  }, rsaaExecuted ? undefined : (inner) => setShowWarn({
    formCode: SubscriptionFormCode.SAF001V2_Form1,
    title,
    message,
    actionName: 'create',
    continueFn: inner
  }));
  const onView = display
    ? () => {
      console.log('onView');
      navigateToForm(display.id, display.formCode);
    }
    : undefined;
  const onServeStart = display && allVendorsSigned(display.signing)
    ? () => setShowServeToNewPurchaserParams({ formId: display.id, formCode: display.formCode })
    : undefined;

  const invokeAction = (action: Action) => {
    action === 'upload' && onUpload?.();
    action === 'order' && onOrder?.();
    action === 'create' && onCreate?.();
  };

  const handleNew = (action: Action) => {
    if (display) {
      setReplaceForm1Modal(action);
    } else {
      invokeAction(action);
    }
  };

  const buttonConfig: { text: string, icon?: { name: string, pack: IconPacks }, handler: (() => void) | undefined, action: ProcessingAction }[] = [];
  canOrder && buttonConfig.push({ text: 'Order', icon: { name: 'add_shopping_cart', pack: 'material-icons' }, action: 'ordering', handler: ()=> handleNew('order') });
  buttonConfig.push({ text: 'Upload', icon: { name: 'upload', pack: 'material-symbols' }, action: 'uploading', handler: ()=> handleNew('upload') });
  buttonConfig.push({ text: 'Create', icon: { name: 'add', pack: 'material-icons' }, action: 'creating', handler: ()=> handleNew('create') });

  return <ErrorBoundary fallbackRender={fallback=><GenericCardFallback {...fallback} cardTitle={title} />}>
    <InstanceCardBase
      title={FormTypes[display?.formCode || FormCode.Form1].label}
      forceFocus={forceFocus}
      clickable={Boolean(onView)}
      onOpen={onView}
      footerButtons={<>
        {onView
          ? <SplitIfManyButton onForceFocus={forceMe => setForceFocus(forceMe)}>
            <Button variant='outline-secondary' disabled={disableActions} onClick={clickNoBubble(onView)}>View</Button>
            {onServeStart && <Button key='serve' variant='outline-secondary' disabled={disableActions} onClick={clickNoBubble(onServeStart)}>Serve</Button>}
            {buttonConfig.map(({ text, handler, action }) => (
              <SpinnerButton key={action} variant='outline-secondary' disabled={disableActions} processing={processingAction === action} onClick={clickNoBubble(handler)}>{text}</SpinnerButton>
            ))}
          </SplitIfManyButton>
          : <>
            {buttonConfig.map(({ text, handler, action }, index) => (
              <SpinnerButton key={index} variant='outline-secondary' disabled={disableActions} processing={processingAction === action} onClick={clickNoBubble(handler)}>{text}</SpinnerButton>
            ))}
          </>}
      </>}
    >
      {display
        ? <div>
          <div className='mb-2'>
            <FormInstanceSigningProgressBar instance={display} />
          </div>
          <div className='psuedoform-content mb-2'>
            {/* todo: do we need the offerManagement passed in? */}
            {propertyId && <FormInstanceInfoSnippets propertyId={propertyId} familyState={family} instance={display} offerManagement={undefined} />}
          </div>
        </div>
        : <div>
          <div className='status create-ops' style={{ width: 'max-content', marginInline: 'auto' }}>
            {buttonConfig.map(({ text, icon, handler, action }, index) => {
              return <SpinnerButton
                key={index}
                variant='tertiary'
                className='border-0 d-flex flex-row justify-content-between gap-2'
                disabled={disableActions}
                processing={processingAction === action}
                onClick={clickNoBubble(handler)}
              >
                {icon && <Icon name={icon.name} pack={icon.pack}/>}<span>{text}</span>
              </SpinnerButton>;
            })}
          </div>
        </div>}
    </InstanceCardBase>
    <UploadPdfInput
      ref={uploadInput}
      onStart={() => {}}
      onEnd={() => {}}
      onFile={onUploadFileSelected}
    />
    {showWarn ? <FormWarningDialog
      formCode={showWarn.formCode}
      title={showWarn.title}
      message={showWarn.message}
      actionName={showWarn.actionName}
      onCancel={() => setShowWarn(undefined)}
      onContinue={() => showWarn?.continueFn()}
    /> : <></>}
    {replaceForm1Modal && <Form1ReplaceModal
      type={replaceForm1Modal}
      onClose={()=> setReplaceForm1Modal(undefined)}
      onOk={()=> {
        invokeAction(replaceForm1Modal);
        setReplaceForm1Modal(undefined);
      }}
    />}
  </ErrorBoundary>;
}

function isDisplayable(instance: FormInstance) {
  if (instance.order) {
    if (instance.order.state === FormOrderState.ClientOrdering) return false;
    return Boolean(instance.order.job?.id || instance.order.type === FormOrderType.Filler);
  }

  // simply: if they're not an order, they're always displayable
  return true;
}

export function extractDisplayInstance(instances?: FormInstance[]) {
  if (!instances?.length) return {};
  const active = instances.filter(i => !i.archived);
  const display = active.filter(isDisplayable).at(-1);
  const latest = active.at(-1);

  if (latest !== display) {
    return {
      display,
      orphanOrder: latest
    };
  }

  return {
    display
  };
}

export interface UploadPdfInputProps {
  onStart: () => void,
  onFile: (file: Uint8Array, contentType: string) => Promise<void>,
  onEnd: () => void,
}
export const UploadPdfInput = React.forwardRef<HTMLInputElement, UploadPdfInputProps>(({ onStart, onFile, onEnd }, ref) => {
  return <input
    ref={ref} className='d-none' type='file' accept='.pdf,application/pdf'
    onChange={async event => {
      if (!event.target.files?.length) return;
      const file = event.target.files[0];
      if (!file) return;
      onStart();
      try {
        const pdfFile = await processPdf(await file.arrayBuffer());
        if (!pdfFile?.pdf || pdfFile.isEncrypted) return;
        await onFile(pdfFile.pdf, ContentType.Pdf);
      } finally {
        onEnd();
      }
    }}
  />;
});
