import { useLocalStorage } from 'react-use';
import { merge } from 'lodash';

interface DevicePreferences {
  sidebar: {
    collapseAt: number;
  }
}

const initialDevicePreferences: DevicePreferences = {
  sidebar: {
    collapseAt: 992
  }
};

function deletePropertyPath(obj: any, path: string | string[]) {
  if (!obj || !path) {
    return;
  }

  if (typeof path === 'string') {
    path = path.split('.');
  }

  for (let i = 0; i < path.length - 1; i++) {

    obj = obj[path[i]];

    if (typeof obj === 'undefined') {
      return;
    }
  }

  const prop = path.pop();
  if (prop !== undefined) {
    delete obj[prop];
  }
}

function migrateUp(preferences: any): DevicePreferences {
  const updatedPreferences = merge(initialDevicePreferences, preferences);

  const keysToRemove = [
    'sidebar.expanded',
    'sidebar.overridden'
  ];

  for (const key of keysToRemove) {
    try {
      deletePropertyPath(updatedPreferences, key);
    } catch (e) {
      console.warn('failed to remove property: ', key, e);
    }
  }

  return updatedPreferences;
}

export function useDevicePreferences() {
  const [devicePreferences, setDevicePreferences] = useLocalStorage<DevicePreferences>('devicePreferences', initialDevicePreferences);

  const set = (fn: (d: DevicePreferences) => DevicePreferences) => {
    const r = fn(devicePreferences ?? initialDevicePreferences);

    setDevicePreferences(migrateUp(r));
  };

  return {
    devicePreferences: migrateUp(devicePreferences) ?? initialDevicePreferences,
    setDevicePreferences: set
  };
}
