import {
  Annexure,
  InlineAnnexureRef,
  MaterialisedPropertyData, TransactionMetaData
} from '@property-folders/contract';
import { FormUtil } from '@property-folders/common/util/form';
import { useYdocBinder } from '../../hooks/useYdocBinder';
import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FileStorage } from '@property-folders/common/offline/fileStorage';
import { useLightweightTransaction, useTransactionField } from '../../hooks/useTransactionField';
import { FileSyncContext } from '../../context/fileSyncContext';
import { useDropzone } from 'react-dropzone';
import clsJn from '@property-folders/common/util/classNameJoin';
import { Maybe } from '@property-folders/common/types/Utility';
import { Button } from 'react-bootstrap';
import _ from 'lodash';
import { DataGeneration } from '@property-folders/common/util/dataExtractTypes';
import { LineageContext } from '../../hooks/useVariation';
import { PathType } from '@property-folders/contract/yjs-schema/model';

import { getHierarchyNode, getPathParentAndIndex, normalisePathToStr, normalisePathToStrArray } from '@property-folders/common/util/pathHandling';
import { composeErrorPathClassName } from '@property-folders/common/util/formatting';
import { useSelector, useStore } from 'react-redux';
import { preservedAnnexureOrderHistoryList } from '@property-folders/common/util/form/annexure';
import { createAnnexure, deleteAnnexure } from '@property-folders/common/util/annexure';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { FormContext } from '../../context/FormContext';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { FormUserInteractionContext } from '../../context/FormUserInteractionContext';
import '../../dragged-components/form/ProposedLotAnnexure.scss';
import { activeFormSectionClick } from '@property-folders/common/redux-reducers/navigation';
import { useDispatch } from 'react-redux';
import { CollectionRemoveButton } from '../../dragged-components/form/CollectionRemoveButton';

/**
 *
 * @param param0.parentPath Path to proposedLot object parent (as applicable)
 * @param param0.myPath Path to proposedLot object
 * @returns
 */
export function SectionReplacementAnnexure({ parentPath = ['comparableSales'], myPath = ['annexureRef'], buttonText, documentTitle }: {
  parentPath?: PathType
  myPath?: PathType,
  buttonText?: string,
  documentTitle?: string
}) {
  const dispatch = useDispatch();
  const store = useStore();
  const { transactionRootKey, docName } = useContext(YjsDocContext);
  const { formId, formName: formCode } = useContext(FormContext);

  const fullPathSegs = normalisePathToStr([...normalisePathToStrArray(parentPath??[]), ...normalisePathToStrArray(myPath??[])]);

  const formFamilyCode = FormTypes[formCode].formFamily;
  const familyCodePath = `family_${formFamilyCode}`;

  const { value: annexureRef, handleUpdate: updateProposalref, fullPath } = useTransactionField({ parentPath: fullPathSegs, myPath: familyCodePath });

  const { snapshotHistory, variationsMode } = useContext(LineageContext);
  const formParentPath = FormUtil.getFormPath(formCode, formId) || '';
  const annexuresPath = `${formParentPath}.annexures`;
  const { updateDraft: updateAnnexures } = useYdocBinder<Annexure[]>({ path: annexuresPath, bindToMetaKey: true });

  const { updateDraft: updateRef } = useYdocBinder<InlineAnnexureRef>({ path: fullPathSegs });

  const { updateDraft: updateMetaRoot } = useYdocBinder<TransactionMetaData>({ path: '', bindToMetaKey: true });
  const annexuresForm = useLightweightTransaction<Annexure[]>({ parentPath: formParentPath, myPath: 'annexures', bindToMetaKey: true })?.value??[];

  const { annexures, usingPrevious, orderList } = useMemo(()=>{
    return preservedAnnexureOrderHistoryList(annexuresForm, snapshotHistory);
  }, [annexuresForm, snapshotHistory]);

  const shouldShowFullValidationError = useContext(FormUserInteractionContext).userShouldSeeAllValidation;
  const fullValidationError = useSelector((state: any) => {
    const baseTree = state?.validation?.errorPaths?.[docName ?? '']?.[transactionRootKey ?? '']?.[formCode];
    const result = !!getHierarchyNode(normalisePathToStrArray(fullPath), baseTree);
    return !!result;
  });

  const flagValidationError = shouldShowFullValidationError && fullValidationError;

  const { value: property } = useLightweightTransaction<MaterialisedPropertyData>({ myPath: '' });
  const { instance: fileSync } = useContext(FileSyncContext);
  const [ errorMessage, setErrorMessage ] = useState<string>('');
  const uploadInput = useRef<HTMLInputElement | null>(null);

  useEffect(()=> {
    updateMetaRoot?.(draft => {
      const { indexer, parent } = getPathParentAndIndex(annexuresPath, draft, true);
      if (parent && !parent[indexer]) parent[indexer] = [];
    });
  }, []);

  useEffect(()=>{
    if (!Array.isArray(annexures) || !annexureRef || (variationsMode && !snapshotHistory)) {
      // probably hasn't loaded yet if created
      return;
    }
    const found = _.find(annexures, annex=>annex.id === annexureRef);

    if (!found) {
      // Largely copied from ProposedLotAnnexure.tsx
      updateRef?.(draft=>{
        const normalPath = normalisePathToStrArray(familyCodePath);
        if (normalPath.length === 1) {
          delete draft[normalPath[0]];
          return;
        }
        const { indexer,parent } = getPathParentAndIndex(draft, normalPath, true);
        if (parent && indexer) {
          delete parent[indexer];
        }
      });
      return;
    }
  }, [annexures, annexureRef]);

  const handleDrop = (acceptedFiles: File[], idx: Maybe<number>) => {
    idx = (idx??0) < 0 ? 0 : idx;
    createAnnexures(acceptedFiles, idx);
  };

  const handleInsert = () => uploadInput?.current?.click();

  const updateAnnexureLabelsDraft = useCallback((draft: Annexure[]) => {

    let label = 'A';

    (orderList??[]).forEach((a, idx) => {
      const draftObj = _.find(draft, annex => annex.id === a.id);
      if (draftObj && !draftObj._removedMarker && !draftObj._restoredMarker) {
        return;
      }
      label = nextAnnexure(label);
    });

    draft.forEach((draft, idx) => {
      if (draft._removedMarker) {
        return;
      }

      draft.label = label;
      label = nextAnnexure(label);
    });
  }, [orderList]);

  const updateAnnexureLabels = () => updateAnnexures?.(draft => updateAnnexureLabelsDraft(draft));

  const handleDelete = useCallback(async (deleted: Annexure) => {
    if (!deleted?.id) return;
    await deleteAnnexure(deleted, usingPrevious, annexures, updateAnnexures);
    updateRef?.(draft=>{
      const { indexer,parent } = getPathParentAndIndex(draft, familyCodePath, true);
      if (parent?.[indexer] === deleted.id) {
        delete parent[indexer];
      }
    });
    updateAnnexureLabels();
  }, [usingPrevious, annexures, updateAnnexures, updateRef]);

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {

    event.target.files?.length && createAnnexures([...event.target.files], undefined);
  };

  const nextAnnexure = (str:string): string => {
    if (! str) return 'A';
    let tail = '';
    let i = str.length -1;
    let char = str[i];
    while (char === 'Z' && i > 0) {
      char = str[i--];
      tail = 'A' + tail;
    }
    if (char === 'Z') return 'AA' + tail;
    return str.slice(0, i) + String.fromCharCode(char.charCodeAt(0) + 1) + tail;
  };

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    onDrop: (files) => handleDrop(files, undefined),
    noClick: true,
    accept: { 'application/pdf': [] }
  });

  const createAnnexures = async (files: File[], idx: Maybe<number>) => {
    //only allow 100MB of annexures
    const fileMeta = await Promise.all(annexures.map(a => FileStorage.readMeta(a.data.id)));
    let existingSize = fileMeta?.map(f => f?.size ?? 0)?.reduce((a, f) => a + f, 0);
    if (files.length > 1) {
      setErrorMessage(`Only one file is supported for uploading ${documentTitle} documents.`);
      return;
    }
    for (const file of files) {
      if (existingSize + file.size > 104857600) {
        setErrorMessage('The Annexures you have attached are too large. Only 100MB of Annexures can be attached.');
        return;
      }
      existingSize += file.size;
      createAnnexureCallback(file, idx);
    }
  };

  const createAnnexureCallback = useCallback(async (file: File, idx?: Maybe<number>, linkedFormId?: Maybe<string>, coversheetText?: string) => {
    const newId = await createAnnexure({ file,
      idx,
      linkedFormId,
      coversheetText,
      property,
      formId,
      formCode,
      setErrorMessage,
      updateAnnexures,
      updateAnnexureLabelsDraft,
      fileSync,
      store,
      nameOverride: documentTitle
    });
    updateProposalref(newId, true);
  },[annexures, updateAnnexureLabelsDraft]);

  const areNoAnnexures = (annexures?.length??0) === 0;

  const proposalAnnexureCheck = _.find(annexures, annex=>annex.id === annexureRef);

  const proposalAnnexure = proposalAnnexureCheck && typeof proposalAnnexureCheck === 'object' && proposalAnnexureCheck.state !== DataGeneration.Removed ? proposalAnnexureCheck : null;

  const compositeErrorMessage = errorMessage || flagValidationError ? 'A Plan is required' : null;

  const errorFocusClass = composeErrorPathClassName(fullPath, undefined);
  return <>
    {proposalAnnexure && <div style={{ flexBasis: '100%', height: 0 }} className='mt-4' />}
    <div
      {...(!proposalAnnexure ? getRootProps() : {})}
      className={clsJn(
        'comparable-sales mb-3',
        errorFocusClass,
        isDragAccept && 'drop-accept',
        !proposalAnnexure && 'ms-auto'
      )}>
      <div>
        <input ref={uploadInput} className={'d-none'} type="file" accept={'.pdf'} multiple={false} onChange={handleUpload} />
        <input {...(areNoAnnexures ? getInputProps() : {})} className={'d-none'} />
        <div className=''>

          {proposalAnnexure && <div className='upload-detail d-flex'>
            <Button
              className='fs-5 link-button-nopad undecorated'
              variant='link'
              onClick={()=>dispatch(activeFormSectionClick('annexures'))}
            >
              Annexure {proposalAnnexure?.data?.label} - {documentTitle}
            </Button>
            <div className='ms-2'>
              <CollectionRemoveButton onRemove={()=>handleDelete(proposalAnnexure)} removable={true} />
            </div>
          </div>}
          {!proposalAnnexure &&<Button
            variant={'light'}
            onClick={handleInsert}
          >
            {buttonText}
          </Button>
          }

        </div>
      </div>

      {compositeErrorMessage && <div className='pseudo-invalid-feedback'>{compositeErrorMessage}</div>}
    </div>

  </>;
}
