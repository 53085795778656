import './SigningFieldsConfiguration.scss';
import {
  CustomFieldConfiguration,
  FormCodeUnion,
  FormInstance,
  MasterRootKey,
  MaterialisedPropertyData,
  SigningParty,
  SigningPartyTypeOptions,
  TransactionMetaData
} from '@property-folders/contract/yjs-schema/property';
import {
  CustomFieldMetaGroup,
  customFieldMetas,
  defaultCheckmark,
  defaultFontSize,
  defaultLineHeight,
  fallbackFieldText
} from '@property-folders/contract/property/meta';
import { SigningConfigurationProps } from '../SigningConfiguration';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, ListGroup, Modal } from 'react-bootstrap';
import { DndProvider } from 'react-dnd';
import { DroppablePositioned } from './DroppablePositioned';
import { DraggablePositioned } from './DraggablePositioned';
import clsJn from '@property-folders/common/util/classNameJoin';
import { uuidv4 } from 'lib0/random';
import { useLightweightTransaction, useTransactionField } from '../../../hooks/useTransactionField';
import {
  FormUtil,
  getSigningOrderVersion,
  PartySnapshotLoader,
  SigningOrderVersion
} from '@property-folders/common/util/form';
import { MultiBackend, MultiBackendOptions, PointerTransition, TouchTransition } from 'react-dnd-multi-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { CreateCustomFieldDetails, CustomFieldDetails, DraggableType, ExistingCustomFieldDetails } from './types';
import { CreateCustomField, CustomFieldPartyInfo, ExistingCustomField } from './CustomField';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DragLayer } from './DragLayer';
import Select from 'react-select';
import { BinderFn, useImmerYjs } from '../../../hooks/useImmerYjs';
import { noBubble } from '@property-folders/portal/src/util';
import { Icon } from '../../Icon';
import { useLiveQuery } from 'dexie-react-hooks';
import { FileStorage } from '@property-folders/common/offline/fileStorage';
import { PageDimensions, PDFViewer, PDFViewerRefProps, ZoomMode } from '../../PDFViewer/PDFViewer';
import { SetupPdfLoadStateContext } from '../../../context/pdfLoadStateContext';
import { clamp, upperFirst } from 'lodash';
import { ContentType, CustomFieldType, FieldPosition, Maybe } from '@property-folders/contract';
import { CustomFieldAttributesEditor, setCustomAttribute } from './CustomFieldAttributesEditor';
import { CoordinateMath } from '@property-folders/common/util/coords';
import { materialisePropertyData } from '@property-folders/common/yjs-schema/property';
import { PDFMinimap } from '../../PDFViewer/PDFMinimap';
import {
  applyPdfChanges,
  getTextFieldText,
  makeUploadPdfChangeFns,
  SupportedFontFamily,
  TextDimensionEstimator
} from '@property-folders/common/util/pdf';
import {
  FormTypes,
  getOrderedParties,
  mapSigningPartySourceTypeToCategory
} from '@property-folders/common/yjs-schema/property/form';
import { autoResize } from './common';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { useEntities } from '../../../hooks/useEntity';
import { PlonkLayer } from './PlonkLayer';
import { clickNoBubble } from '@property-folders/common/util/clickNoBubble';

const scaleValue = CoordinateMath.scaleValue;
const dndBackends: MultiBackendOptions = {
  backends: [
    {
      id: 'html5',
      backend: HTML5Backend,
      transition: PointerTransition
    },
    {
      id: 'touch',
      backend: TouchBackend,
      options: { enableMouseEvents: true },
      transition: TouchTransition
    }
  ]
};

const allFields = (Object.keys(CustomFieldType) as CustomFieldType[]);
const signingFields = allFields.filter(key => customFieldMetas[key].displayGroup === 'signing');
const contactFields = allFields.filter(key => customFieldMetas[key].displayGroup === 'contact');
const propertyFields = allFields.filter(key => customFieldMetas[key].displayGroup === 'property');
const serveFields = allFields.filter(key => customFieldMetas[key].displayGroup === 'serve');
const otherFields = allFields.filter(key => customFieldMetas[key].displayGroup === 'other');

const defaultCustomFieldDisplay: Record<CustomFieldMetaGroup, 'full' | 'restricted' | 'hidden'> = {
  signing: 'full',
  property: 'full',
  contact: 'full',
  other: 'full',
  serve: 'hidden'
};

const requiresRemoteFeatureFlag = [CustomFieldType.remoteText, CustomFieldType.remoteCheck, CustomFieldType.remoteRadio];

function decideCustomFieldDisplay(formCode: FormCodeUnion, isFirstParty: boolean, remoteFeatureFlag: boolean, signingOrderVersion: number): Record<CustomFieldMetaGroup, CustomFieldType[]> {
  const base = FormTypes[formCode]?.customiseFieldTypes;
  const config = {
    signing: base?.signing || defaultCustomFieldDisplay.signing,
    property: base?.property || defaultCustomFieldDisplay.property,
    contact: base?.contact || defaultCustomFieldDisplay.contact,
    other: base?.other || defaultCustomFieldDisplay.other,
    serve: base?.serve || defaultCustomFieldDisplay.serve
  };

  return {
    signing: signingFields.filter(key => {
      if (config.signing === 'hidden') return false;
      if (config.signing === 'restricted' && customFieldMetas[key].displayRestricted) return false;
      if (customFieldMetas[key].firstPartyOnly && (!isFirstParty || signingOrderVersion !== SigningOrderVersion.Flat)) return false;
      if (!remoteFeatureFlag && requiresRemoteFeatureFlag.includes(key)) return false;
      return true;
    }),
    property: config.property === 'full' ? propertyFields : [],
    contact: config.contact === 'full' ? contactFields : [],
    other: config.other === 'full' ? otherFields : [],
    serve: config.serve === 'full' ? serveFields : []
  };
}

export function SigningFieldsConfiguration({
  formCode,
  formId,
  ydoc,
  yMetaRootKey = MasterRootKey.Meta,
  prepareForSigning
}: SigningConfigurationProps) {
  const { value: data } = useTransactionField<MaterialisedPropertyData>({
    myPath: ''
  });
  const { value: instance, fullPath: formPath } = useLightweightTransaction<FormInstance>({
    parentPath: FormUtil.getFormPath(formCode, formId),
    bindToMetaKey: true
  });
  const { value: parties } = useTransactionField<SigningParty[]>({
    parentPath: formPath,
    myPath: 'signing.parties',
    bindToMetaKey: true
  });
  const {
    value: customFields
  } = useLightweightTransaction<CustomFieldConfiguration[]>({
    parentPath: formPath,
    myPath: 'signing.customFields',
    bindToMetaKey: true
  });
  const {
    value: customFieldDefaults
  } = useLightweightTransaction<Record<string, any>>({
    parentPath: formPath,
    myPath: 'signing.customFieldDefaults',
    bindToMetaKey: true
  });
  const { orderedParties, firstOrderedParty } = useMemo(() => {
    const orderedParties = getOrderedParties(parties, instance?.signing);
    return {
      orderedParties,
      firstOrderedParty: orderedParties.at(0)
    };
  }, [parties, instance]);

  const memberEntities = useEntities() ?? {};

  const uploadedFileUrl = useLiveQuery(async () => {
    const uploadId = instance?.upload?.id;
    if (!uploadId) return undefined;
    const blob = (await FileStorage.read(uploadId))?.data;
    if (!blob) return undefined;

    if (!instance?.upload?.actions?.length) {
      return URL.createObjectURL(blob);
    }

    return URL.createObjectURL(new Blob(
      [await applyPdfChanges(
        await blob.arrayBuffer(),
        makeUploadPdfChangeFns(instance.upload.actions)
      )],
      { type: ContentType.Pdf }
    ));
  }, [instance?.upload?.id, instance?.upload?.actions?.length]);
  const deletedPageIndexes = useMemo(() => {
    return new Set((instance?.upload?.actions || [])
      .filter(a => a.action === 'delete')
      .map(a => a.pageIndex));
  }, [instance?.upload?.actions?.length]);
  const { binder } = useImmerYjs<TransactionMetaData>(ydoc, yMetaRootKey);
  const partyInfos = useMemo<CustomFieldPartyInfo[]>(() => {
    if (!ydoc) return [];

    const snapshotLoader = new PartySnapshotLoader(
      FormUtil.getSigningSessionSignatureSnapshots(orderedParties, data, { memberEntities }),
      orderedParties,
      memberEntities,
      sublineageId => materialisePropertyData(ydoc, sublineageId)
    );
    const result = new Map<string, CustomFieldPartyInfo>();
    for (const party of orderedParties) {
      const snap = snapshotLoader.getSnapshot(party);
      if (snap) {
        result.set(party.id, {
          id: party.id,
          type: party.type,
          colour: party?.colour || 'white',
          category: mapSigningPartySourceTypeToCategory(party.source.type),
          snapshot: snap,
          isFirstOrdered: Boolean(firstOrderedParty?.id && firstOrderedParty.id === party.id)
        });
      }
    }

    return [...result.values()];
  }, [data, orderedParties, !!ydoc, firstOrderedParty?.id, memberEntities]);
  const [selectedParty, setSelectedParty] = useState<CustomFieldPartyInfo | undefined>(partyInfos.at(0));
  const [selectedFieldId, setSelectedFieldId] = useState('');
  const [plonkable, setPlonkable] = useState<Maybe<CreateCustomFieldDetails>>(undefined);
  const [showContext, setShowContext] = useState<{
    x: number,
    y: number,
    id: string,
    target: Element,
    // note, this is a snapshot of the field
    field: CustomFieldConfiguration,
    pdfDimensions: PageDimensions
  } | undefined>(undefined);
  const { selectedField, groupBoxes } = useMemo<{
    selectedField?: CustomFieldConfiguration,
    groupBoxes?: Record<string, GroupBoxDimensions | undefined>
  }>(() => {
    if (!customFields) return {};

    const selectedField = selectedFieldId ? customFields?.find(cf => cf.id === selectedFieldId) : undefined;
    if (selectedField && 'group' in selectedField && selectedField.group) {
      const group = selectedField.group;
      const type = selectedField.type;
      const groupBoxes: Record<string, {minX: number, minY: number, maxX: number, maxY: number} | undefined> = {};
      const groupFields = customFields
        .filter(cf => 'group' in cf
          && cf.group
          && cf.group === group
          && cf.type === type);
      if (groupFields.length <= 1) {
        return { selectedField };
      }

      groupFields.forEach(cf => {
        const { y, x, width, height, page } = cf.position;
        const match = groupBoxes[page];
        if (match) {
          match.minY = Math.min(match.minY,y, y+height);
          match.maxY = Math.max(match.maxY,y, y+height);
          match.minX = Math.min(match.minX,x, x+width);
          match.maxX = Math.max(match.maxX,x, x+width);
        } else {
          groupBoxes[cf.position.page] = {
            minY: Math.min(y, y+height),
            maxY: Math.max(y, y+height),
            minX: Math.min(x, x+width),
            maxX: Math.max(x, x+width)
          };
        }
      });

      if (Object.keys(groupBoxes).length) {
        return {
          selectedField,
          groupBoxes: Object.fromEntries(Object.entries(groupBoxes)
            .map<[string, GroupBoxDimensions | undefined]>(([key, value]) => [key,
              value ? {
                x: value.minX,
                y: value.minY,
                width: value.maxX - value.minX,
                height: value.maxY - value.minY
              } : undefined
            ]))
        };
      }
    }

    return {
      selectedField
    };
  }, [selectedFieldId, customFields]);
  if (groupBoxes) {
    console.log('groupBox', groupBoxes);
  }
  const [viewerRef, setViewerRef] = useState<PDFViewerRefProps | null>(null);
  const estimator = useMemo(() => new TextDimensionEstimator(), []);

  const deleteSelected = useCallback(() => {
    if (!selectedFieldId) return;
    if (!binder) return;
    binder.update(draft => {
      const signing = FormUtil.getSigning(formCode, formId, draft);
      if (!signing?.customFields) return;
      const idx = signing.customFields.findIndex(cf => cf.id === selectedFieldId);
      if (idx < 0) return;
      signing.customFields.splice(idx, 1);
    });
    setSelectedFieldId('');
  }, [selectedFieldId, binder]);

  const translateSelected = useCallback((x: number, y: number) => {
    if (!selectedFieldId) return false;
    if (!binder) return false;
    if (!viewerRef) return false;
    binder.update(draft => {
      const signing = FormUtil.getSigning(formCode, formId, draft);
      if (!signing?.customFields) return;
      const match = signing.customFields.find(cf => cf.id === selectedFieldId);
      if (!match) return;
      // just apply the increments directly, don't apply scaling.
      const pdfDimensions = viewerRef?.getPageDimensions(match.position.page);
      if (!pdfDimensions) return;
      match.position.x += x;
      match.position.y += y;

      clampFieldPosition(match, pdfDimensions);
    });
    return true;
  }, [selectedFieldId, binder, viewerRef]);

  const addField = (item: CustomFieldDetails, location: { x: number, y: number }, zoneRect: DOMRect, pageIndex: number, page?: PageDimensions) => {
    if (!page) return;
    if (item?.mode !== DraggableType.Create) return;
    if (!binder) return;
    const scaleX = (value: number) => scaleValue(value, zoneRect.width, page.width);
    const scaleY = (value: number) => scaleValue(value, zoneRect.height, page.height);
    let newId: string | undefined;
    binder.update(draft => {
      const signing = FormUtil.getSigning(formCode, formId, draft);
      if (!signing) return;
      if (!signing.customFields) {
        signing.customFields = [];
      }
      newId = uuidv4();
      const newCf = safeGenerateCustomField({
        field: item,
        partyId: selectedParty?.id,
        position: {
          page: pageIndex,
          // note: may need to perform some translation from UI coordinate space to pdf page's coordinate space.
          x: scaleX(location.x),
          y: scaleY(location.y),
          width: scaleX(item.width || 100),
          height: scaleY(item.height || 25)
        },
        newId: uuidv4(),
        attrs: new AttrDefaultLoader(customFieldDefaults || {}),
        party: partyInfos.find(p => p.id === selectedParty?.id),
        data,
        estimator,
        existingFields: customFields
      });
      if (newCf) {
        newId = newCf.id;
        clampFieldPosition(newCf, page);
        signing.customFields.push(newCf);
      }
    });
    return newId;
  };

  const cloneField = useCallback((
    id: string,
    page: PageDimensions,
    mutateFn?: (
      clone: CustomFieldConfiguration,
      opts: {
        original: CustomFieldConfiguration
        fields: CustomFieldConfiguration[]
      }
    ) => void
  ) => {
    if (!binder) return undefined;
    let newId: string | undefined;
    binder.update(draft => {
      const signing = FormUtil.getSigning(formCode, formId, draft);
      if (!signing) return;
      if (!signing.customFields) {
        signing.customFields = [];
      }
      const original = signing.customFields.find(cf => cf.id === id);
      if (!original) return;
      newId = uuidv4();
      const clone = JSON.parse(JSON.stringify(original)) as CustomFieldConfiguration;
      clone.id = newId;
      mutateFn?.(clone, { original, fields: signing.customFields });
      clampFieldPosition(clone, page);
      signing.customFields.push(clone);
    });
    return newId;
  }, [binder]);

  const cloneRadio = useCallback((id: string, page: PageDimensions) => {
    return cloneField(id, page, (clone, opts) => {
      if (clone.type === CustomFieldType.remoteRadio) {
        delete clone.on;
      }
      autoResize(clone, { estimator });
      // "append" the clone to the bottom of the original.
      clone.position.y = opts.original.position.y + opts.original.position.height;
    });
  }, [cloneField]);

  const cloneCheckbox = useCallback((id: string, page: PageDimensions) => {
    return cloneField(id, page, (clone, opts) => {
      if ('group' in clone && clone.group) {
        clone.group = getNextGroupId(customFields || [], 'check-');
      }
      autoResize(clone, { estimator });
      // "append" the clone to the bottom of the original.
      clone.position.y = opts.original.position.y + opts.original.position.height;
    });
  }, [cloneField, customFields]);

  useEffect(() => {
    const unselectHandler = () => {
      setSelectedFieldId('');
    };
    document.body.addEventListener('click', unselectHandler);

    return () => {
      document.body.removeEventListener('click', unselectHandler);
    };
  }, []);

  useEffect(() => {
    const translationsMap: Record<string, [number, number]> = {
      ArrowDown: [0, 1],
      ArrowUp: [0, -1],
      ArrowLeft: [-1, 0],
      ArrowRight: [1, 0]
    };
    const keyHandler = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'Backspace':
        case 'Delete':
          deleteSelected();
          break;
        case 'Escape':
          setPlonkable(undefined);
          break;
        default: {
          const translation = translationsMap[e.key];
          if (translation && translateSelected(...translation)) {
            e.preventDefault();
            break;
          }
          break;
        }
      }
    };
    document.body.addEventListener('keydown', keyHandler);

    return () => {
      document.body.removeEventListener('keydown', keyHandler);
    };
  }, [deleteSelected, translateSelected]);

  useEffect(() => {
    if (!showContext) return;
    // hook into body click event to clear context
    const handler = () => {
      setShowContext(undefined);
    };
    document.body.addEventListener('click', handler);
    document.body.addEventListener('contextmenu', handler);
    return () => {
      document.body.removeEventListener('click', handler);
      document.body.removeEventListener('contextmenu', handler);
    };
  }, [!!showContext]);

  const viewerRefCallback = useCallback((newRef: PDFViewerRefProps) => {
    setViewerRef(newRef);
  }, []);
  const pdfScrollContainer = useRef<HTMLElement>(null);

  const drawCustomFieldTypeBlock = (title: string, fields: CustomFieldType[], makeItem: (type: CustomFieldType) => CreateCustomFieldDetails) => {
    if (!fields.length) {
      return <></>;
    }
    return <>
      <h5 className='m-0'>{title}</h5>
      {fields.map((type) => {
        const item = makeItem(type);
        if (customFieldMetas[type].firstPartyOnly && !instance?.signing?.useSigningOrder && getSigningOrderVersion(instance?.signing) !== SigningOrderVersion.Flat) {
          return <div className='w-100' style={{ cursor: 'not-allowed' }}>
            <CreateCustomField
              details={item}
              parties={partyInfos}
              dragging={false}
              fillWidth={true}
              disableReason={'Parties must be invited individually to use this field.'}
            />
          </div>;
        }
        const onlyPartyTypes = customFieldMetas[type].onlyPartyTypes;
        if (onlyPartyTypes && (!selectedParty || !onlyPartyTypes.includes(selectedParty.type))) {
          const signingMethod = selectedParty
            ? SigningPartyTypeOptions[selectedParty.type]
            : 'unknown';
          return <div className='w-100' style={{ cursor: 'not-allowed' }}>
            <CreateCustomField
              details={item}
              parties={partyInfos}
              dragging={false}
              fillWidth={true}
              disableReason={`The party's configured signing method is not supported (${signingMethod}).`}
            />
          </div>;
        }

        return (
          <DraggablePositioned<CreateCustomFieldDetails>
            key={`create-${type}`}
            type={DraggableType.Create}
            item={item}
          >
            {(provided) => (<div
              ref={provided.innerRef}
              className={clsJn('draggable w-100')}
              onClick={clickNoBubble(() => setPlonkable(item))}
            >
              <CreateCustomField
                details={item}
                parties={partyInfos}
                dragging={false}
                fillWidth={true}
              />
            </div>
            )}
          </DraggablePositioned>
        );
      })}
    </>;
  };

  const { pfRemoteCompletion } = useFeatureFlags();

  const {
    signing,
    contact,
    serve,
    property,
    other
  } = useMemo(() => decideCustomFieldDisplay(
    formCode as FormCodeUnion,
    Boolean(firstOrderedParty?.id && firstOrderedParty.id === selectedParty?.id),
    Boolean(pfRemoteCompletion),
    getSigningOrderVersion(instance?.signing)
  ), [formCode, firstOrderedParty?.id, selectedParty?.id, pfRemoteCompletion, instance?.signing?.signingOrderVersion]);

  useEffect(() => {
    if (!plonkable) return;
    const handler = () => {
      setPlonkable(undefined);
    };
    document.addEventListener('click', handler);
    return () => {
      document.removeEventListener('click', handler);
    };
  }, [!!plonkable]);

  return <div className='overflow-auto d-flex flex-row h-100'>
    {!!showContext && <div
      className='bg-dark light'
      style={{ position: 'fixed', top: `${showContext.y}px`, left: `${showContext.x}px`, zIndex: 1000 }}
    >
      <ListGroup>
        {showContext.field.type === CustomFieldType.remoteRadio && <ListGroup.Item action onClick={noBubble(() => {
          if (!showContext.field) return;
          const id = cloneRadio(showContext.field.id, showContext.pdfDimensions);
          setSelectedFieldId(id || '');
          setShowContext(undefined);
        })}>Add option</ListGroup.Item>}
        {showContext.field.type === CustomFieldType.remoteCheck && <ListGroup.Item action onClick={noBubble(() => {
          if (!showContext.field) return;
          const id = cloneCheckbox(showContext.field.id, showContext.pdfDimensions);
          setSelectedFieldId(id || '');
          setShowContext(undefined);
        })}>Clone</ListGroup.Item>}
        <ListGroup.Item action onClick={() => {
          deleteSelected();
          setSelectedFieldId('');
          setShowContext(undefined);
        }}>Remove</ListGroup.Item>
      </ListGroup>
    </div>}
    <DndProvider backend={MultiBackend} options={dndBackends}>
      <PlonkLayer
        parties={partyInfos}
        property={data}
        plonkable={plonkable}
        contentScale={1}
      />
      <DragLayer
        parties={partyInfos}
        property={data}
        dragToScrollContainer={pdfScrollContainer?.current}
        onDragStart={() => setPlonkable(undefined)}
      />
      <DroppablePositioned<ExistingCustomFieldDetails>
        // if we want to allow dragging back to delete, then populate with DraggableType.Existing type
        accept={[]}
        getDroppedItemDimensions={item => ({ width: item.position.width, height: item.position.height })}
        onDrop={(_, item) => {
          if (!binder) return;
          binder.update(draft => {
            const signing = FormUtil.getSigning(formCode, formId, draft);
            if (!signing?.customFields) return;
            const index = signing.customFields.findIndex(x => x.id === item.id);
            if (index >= 0) {
              signing.customFields.splice(index, 1);
            }
          });
        }}>
        {(provided) => (<Col
          ref={provided.innerRef}
          md={2}
          className={clsJn(
            'd-flex flex-column align-items-center justify-space-between',
            provided.canDrop && provided.isOver && 'droppable-over cursor-delete'
          )}
        >
          <div
            className={clsJn('d-flex w-100 flex-column gap-3 p-3 align-items-start flex-grow-1 overflow-auto')}
          >
            {partyInfos.length > 0 && <Select
              className={'w-100'}
              options={partyInfos}
              value={selectedParty}
              placeholder={'Select a party...'}
              isSearchable={false}
              isOptionSelected={(option, value) => {
                return value.at(0)?.id === option.id;
              }}
              onChange={newValue => setSelectedParty(newValue ? newValue : undefined)}
              formatOptionLabel={(data, meta) => {
                const small = [
                  upperFirst(data.category || '')
                ].map(x => x.trim()).filter(x => !!x).join(', ');
                return <div>
                  <div><span>{data.snapshot?.name || 'Unknown'}</span></div>
                  <div><small>{small}</small></div>
                </div>;
              }}
              styles={{
                control: (base, control) => {
                  if (!control.hasValue) {
                    return { ...base, borderRadius: '0' };
                  }
                  const value = control.getValue().at(0);
                  if (!value?.colour) {
                    return { ...base, borderRadius: '0' };
                  }
                  return {
                    ...base,
                    borderRadius: '0',
                    borderLeft: `4px solid ${value.colour}`
                  };
                },
                menu: base => ({ ...base, borderRadius: '0' }),
                option: (base, option) => ({ ...base, borderLeft: `4px solid ${option.data.colour}` })
              }}
            />}
            {!!selectedParty && drawCustomFieldTypeBlock('Signing fields', signing, type => ({
              mode: DraggableType.Create,
              type,
              width: 150,
              height: 40,
              partyId: selectedParty.id
            }))}
            {!!selectedParty && drawCustomFieldTypeBlock('Contact fields', contact, type => ({
              mode: DraggableType.Create,
              type,
              width: 150,
              height: 40,
              partyId: selectedParty.id
            }))}
            {drawCustomFieldTypeBlock('Property fields', property, type => ({
              mode: DraggableType.Create,
              type,
              width: 150,
              height: 40
            }))}
            {drawCustomFieldTypeBlock('Serve Fields', serve, type => ({
              mode: DraggableType.Create,
              type,
              width: 150,
              height: 40
            }))}
            {drawCustomFieldTypeBlock('Other', other, type => ({
              mode: DraggableType.Create,
              type,
              width: 150,
              height: 40
            }))}
          </div>
          {!!(selectedFieldId && selectedField) && <ListGroup
            onClick={noBubble()}
            className={clsJn('w-100 sticky-footer-shadow')}
          >
            <ListGroup.Item className={'d-flex flex-row align-items-center gap-1'}>
              <Icon {...customFieldMetas[selectedField.type].icon} />
              <span className={'fw-bold'}>{customFieldMetas[selectedField.type].title}</span>
            </ListGroup.Item>
            <ListGroup.Item>{customFieldMetas[selectedField.type].description}</ListGroup.Item>
            {/*conditionally show value and other things*/}
            {customFieldMetas[selectedField.type].attributes.length > 0 && <ListGroup.Item>
              <CustomFieldAttributesEditor
                meta={customFieldMetas[selectedField.type]}
                field={selectedField}
                estimator={estimator}
              />
            </ListGroup.Item>}
            <ListGroup.Item className={'d-flex flex-column gap-2'}>
              {selectedField.type === CustomFieldType.remoteRadio && <Button variant='outline-secondary' onClick={() => {
                if (!selectedField) return;
                const dims = viewerRef?.getPageDimensions(selectedField.position.page);
                if (!dims) return;
                const id = cloneRadio(selectedField.id, dims);
                setSelectedFieldId(id || '');
              }}>Add option</Button>}
              {selectedField.type === CustomFieldType.remoteCheck && <Button variant='outline-secondary' onClick={() => {
                if (!selectedField) return;
                const dims = viewerRef?.getPageDimensions(selectedField.position.page);
                if (!dims) return;
                const id = cloneCheckbox(selectedField.id, dims);
                setSelectedFieldId(id || '');
              }}>Clone</Button>}
              <Button variant={'outline-danger'} onClick={() => deleteSelected()}>Remove field</Button>
            </ListGroup.Item>
          </ListGroup>}
        </Col>)}
      </DroppablePositioned>
      <Col
        md={8}
        className='d-flex flex-column align-items-center justify-content-start gap-3'
        style={{ background: 'var(--clr-bg-pdf-preview)' }}
      >
        <div className='w-100 h-100 position-relative'>
          {uploadedFileUrl && <SetupPdfLoadStateContext><PDFViewer
            ref={viewerRefCallback}
            scrollContainerRef={pdfScrollContainer}
            pdfUrl={uploadedFileUrl}
            bookmark=''
            activeViews={2}
            filename={instance?.upload?.name || 'uploaded.pdf'}
            allowPrint={false}
            allowDownload={false}
            renderTextLayer={false}
            zoomMode={ZoomMode.Manual}
            useLoadSuccessForCompletion={true}
            // page rendering here:
            // - page drop zone for new/existing fields
            // - renders existing fields for the page
            pageWrapElement={({ pageIndex, dimensions: pdfDimensions, children: pageContent }) => {
              if (deletedPageIndexes.has(pageIndex)) return <div className='d-none'>{pageContent}</div>;
              return <DroppablePositioned<CustomFieldDetails>
                accept={pdfDimensions ? [DraggableType.Create, DraggableType.Existing] : []}
                getDroppedItemDimensions={item => {
                  switch (item.mode) {
                    case DraggableType.Create:
                      return { width: item.width, height: item.height };
                    case DraggableType.Existing:
                      return { width: item.position.width, height: item.position.height };
                  }
                }}
                onDrop={(_, item, location, zoneRect) => {
                  if (!binder) return;
                  if (!pdfDimensions) return;

                  switch (item?.mode) {
                    case DraggableType.Create: {
                      const newId = addField(item, location, zoneRect, pageIndex, pdfDimensions);
                      if (newId) {
                        setSelectedFieldId(newId);
                      }
                      return;
                    }
                    case DraggableType.Existing: {
                      const scaleX = (value: number) => scaleValue(value, zoneRect.width, pdfDimensions.width);
                      const scaleY = (value: number) => scaleValue(value, zoneRect.height, pdfDimensions.height);
                      binder.update(draft => {
                        const signing = FormUtil.getSigning(formCode, formId, draft);
                        if (!signing) return;
                        if (!signing.customFields) {
                          signing.customFields = [];
                        }
                        const matching = signing.customFields.find(f => f.id === item.id);
                        if (!matching) return;

                        matching.position.page = pageIndex;
                        // note: may need to perform some translation from UI coordinate space to pdf page's coordinate space.
                        matching.position.x = scaleX(location.x);
                        matching.position.y = scaleY(location.y);
                      });
                      setSelectedFieldId(item.id);
                      return;
                    }
                  }
                }}>
                {(droppableProvided) => {
                  const displayDimensions = droppableProvided.getZoneRect();
                  return (<div
                    ref={droppableProvided.innerRef}
                    style={{ width: 'min-content', marginInline: 'auto' }}
                    className={clsJn(
                      'mt-3 position-relative',
                      droppableProvided.canDrop && !droppableProvided.isOver && 'droppable',
                      droppableProvided.isOver && droppableProvided.canDrop && 'droppable-over',
                      plonkable && 'droppable-over plonkable'
                    )}
                    onClick={e => {
                      if (!plonkable) return;

                      e.stopPropagation();
                      const zoneRect = e.currentTarget.getBoundingClientRect();
                      const { clientX, clientY } = e;
                      addField(
                        plonkable,
                        {
                          x: Math.max(0, clientX - zoneRect.x),
                          y: Math.max(0, clientY - zoneRect.y)
                        },
                        zoneRect,
                        pageIndex,
                        pdfDimensions
                      );
                    }}
                  >
                    {/*the actual pdf page content*/}
                    {pageContent}
                    {/*custom fields belonging to this page*/}
                    {pdfDimensions && displayDimensions && <PageFields
                      pdfDimensions={pdfDimensions}
                      displayDimensions={displayDimensions}
                      partyInfos={partyInfos}
                      formId={formId}
                      fields={(customFields || []).filter(cf => cf.position.page === pageIndex)}
                      property={data}
                      binder={binder}
                      selectedFieldId={selectedFieldId}
                      formCode={formCode as FormCodeUnion}
                      onSelectField={id => setSelectedFieldId(id)}
                      onContext={(x, y, id, target, field) => {
                        setSelectedFieldId(id);
                        setShowContext({ x, y, id, target, field, pdfDimensions });
                      }}
                      estimator={estimator}
                      groupBox={groupBoxes?.[pageIndex]}
                    />}
                  </div>);
                }}
              </DroppablePositioned>;
            }}
          /></SetupPdfLoadStateContext>}
        </div>

      </Col>
      <Col md={2} style={{ background: 'var(--clr-bg-pdf-preview)' }}>
        {uploadedFileUrl && <PDFMinimap
          url={uploadedFileUrl}
          thumbnailWrapElement={props => {
            return <MinimapActionWrapper
              key={props.pageIndex}
              pageIndex={props.pageIndex}
              allowDelete={props.pageCount > (deletedPageIndexes.size + 1)}
              deleted={deletedPageIndexes.has(props.pageIndex)}
              onAction={(actionIndex, action) => {
                if (!binder) return Promise.resolve();
                binder.update(draft => {
                  const formInstance = FormUtil.getFormState(formCode, formId, draft);
                  if (!formInstance?.upload) return;
                  if (!formInstance.upload.actions) {
                    formInstance.upload.actions = [];
                  }

                  const actions = formInstance.upload.actions;
                  switch (action) {
                    case 'undelete':
                      for (let i = actions.length - 1; i >= 0; i--) {
                        if (actions[i].action === 'delete' && actions[i].pageIndex === actionIndex) {
                          actions.splice(i, 1);
                        }
                      }
                      return;
                    default:
                      actions.push({
                        action,
                        pageIndex: actionIndex
                      });
                      return;
                  }
                });
                return Promise.resolve();
              }}
            >
              {props.children}
            </MinimapActionWrapper>;
          }}
        />}
      </Col>
    </DndProvider>
  </div>;
}

interface ScalingDimensions {
  width: number;
  height: number;
}

interface GroupBoxDimensions {
  x: number,
  y: number,
  width: number,
  height: number
}

function PageFields({
  binder,
  displayDimensions,
  fields,
  formCode,
  formId,
  onSelectField,
  onContext,
  partyInfos,
  property,
  pdfDimensions,
  selectedFieldId,
  estimator,
  groupBox
}: {
  binder: BinderFn<TransactionMetaData>,
  displayDimensions: ScalingDimensions,
  fields: CustomFieldConfiguration[],
  formCode: FormCodeUnion,
  formId: string,
  onSelectField: (id: string) => void,
  onContext: (x: number, y: number, id: string, target: Element, field: CustomFieldConfiguration) => void,
  partyInfos: CustomFieldPartyInfo[],
  property: MaterialisedPropertyData,
  pdfDimensions: ScalingDimensions,
  selectedFieldId: string,
  estimator: TextDimensionEstimator,
  groupBox?: GroupBoxDimensions
}) {
  const pdfToDisplayScaleX = (value: number) => scaleValue(value, pdfDimensions.width, displayDimensions.width);
  const pdfToDisplayScaleY = (value: number) => scaleValue(value, pdfDimensions.height, displayDimensions.height);
  const displayToPdfScaleX = (value: number) => scaleValue(value, displayDimensions.width, pdfDimensions.width);
  const displayToPdfScaleY = (value: number) => scaleValue(value, displayDimensions.height, pdfDimensions.height);
  const contentScale = pdfToDisplayScaleX(1);

  return <>
    {groupBox && <div style={{
      position: 'absolute',
      top: `${pdfToDisplayScaleY(groupBox.y - 4)}px`,
      left: `${pdfToDisplayScaleX(groupBox.x - 4)}px`,
      width: `${pdfToDisplayScaleY(groupBox.width + 8)}px`,
      height: `${pdfToDisplayScaleX(groupBox.height + 8)}px`,
      border: '1px solid blue',
      borderRadius: '2px'
    }}></div>}
    {fields.map(cf => {
      const f: ExistingCustomFieldDetails = {
        ...cf,
        mode: DraggableType.Existing,
        position: {
          y: pdfToDisplayScaleY(cf.position.y),
          x: pdfToDisplayScaleX(cf.position.x),
          width: pdfToDisplayScaleX(cf.position.width),
          height: pdfToDisplayScaleY(cf.position.height),
          page: cf.position.page
        }
      };
      return (<DraggablePositioned
        key={f.id}
        type={f.mode}
        item={f}
        otherAttrs={{ contentScale }}
      >
        {(draggableProvided) => {
          return (<div
            ref={draggableProvided.innerRef}
            style={{
              position: 'absolute',
              top: `${f.position.y}px`,
              left: `${f.position.x}px`,
              width: `${f.position.width}px`,
              height: `${f.position.height}px`
            }}
            className={clsJn(
              draggableProvided.isDragging && 'd-none',
              'movable'
            )}
          >
            <ExistingCustomField
              details={f}
              parties={partyInfos}
              property={property}
              dragging={false}
              resizable={customFieldMetas[f.type].resize === 'manual'}
              onSelect={() => onSelectField(f.id)}
              onContext={(x, y, target) => onContext(x, y, f.id, target, f)}
              selected={f.id === selectedFieldId}
              otherSelected={Boolean(selectedFieldId && f.id !== selectedFieldId)}
              contentScale={contentScale}
              onResize={(delta) => {
                if (!binder) return;
                binder.update(draft => {
                  const signing = FormUtil.getSigning(formCode, formId, draft);
                  if (!signing?.customFields) return;
                  const match = signing.customFields.find(field => f.id === field.id);
                  if (!match) return;

                  // based on 12 pt font

                  // note: may need to perform coordinate space translation
                  match.position.x += displayToPdfScaleX(delta.left);
                  match.position.y += displayToPdfScaleY(delta.top);
                  match.position.width += displayToPdfScaleX(delta.width);
                  match.position.height += displayToPdfScaleY(delta.height);

                  // do we need to round to a couple of decimal places?

                  clampFieldPosition(match, pdfDimensions);
                });
              }}
              onEdit={(text) => {
                if (!binder) return;
                binder.update(draft => {
                  const signing = FormUtil.getSigning(formCode, formId, draft);
                  if (!signing?.customFields) return;
                  const match = signing.customFields.find(field => f.id === field.id);
                  switch (match?.type) {
                    case CustomFieldType.text:
                    case CustomFieldType.remoteText:
                      match.text = text;
                      break;
                    case CustomFieldType.remoteCheck:
                      match.label = text;
                      autoResize(match, { estimator });
                      break;
                    case CustomFieldType.remoteRadio:
                      match.label = text;
                      autoResize(match, { estimator });
                      break;
                  }
                });
              }}
              onAttrChange={(name, raw) => {
                const meta = customFieldMetas[f.type];
                const attr = meta.attributes.find(a => a.name === name);
                if (!attr) return;
                setCustomAttribute({
                  binder,
                  attr,
                  raw,
                  field: f,
                  formId,
                  formCode,
                  estimator
                });
              }}
            />
          </div>);
        }}
      </DraggablePositioned>);
    })}
  </>;
}

class AttrDefaultLoader {
  constructor(private customFieldDefaults: Record<string, any>) { }

  public load<TForceType>(type: CustomFieldType, name: string): TForceType | undefined {
    const attr = customFieldMetas[type].attributes.find(a => a.name === name);
    const overrideDefault = this.customFieldDefaults[name];

    if (overrideDefault != null) return overrideDefault as TForceType;
    if (attr?.typeInfo && 'defaultValue' in attr.typeInfo) return attr.typeInfo.defaultValue as TForceType;

    return undefined;
  }
}

function safeGenerateCustomField({
  field,
  partyId,
  position,
  newId,
  attrs,
  data,
  party,
  estimator,
  existingFields
}: {
  field: CreateCustomFieldDetails,
  partyId: string | undefined,
  position: FieldPosition,
  newId: string,
  attrs: AttrDefaultLoader,
  data: MaterialisedPropertyData,
  party: CustomFieldPartyInfo | undefined,
  estimator: TextDimensionEstimator,
  existingFields?: CustomFieldConfiguration[]
}): CustomFieldConfiguration | undefined {
  switch (field.type) {
    case CustomFieldType.name:
    case CustomFieldType.authority:
    case CustomFieldType.address:
    case CustomFieldType.phone:
    case CustomFieldType.email:
    case CustomFieldType.company:
    case CustomFieldType.abn:
      if (!partyId) {
        console.warn('attempted to add party field without selected party information');
        return undefined;
      }
      return {
        id: newId,
        type: field.type,
        partyId,
        position: {
          ...position,
          ...(estimator.estimateTextDimensions(
            getTextFieldText(field.type, party?.snapshot, data) || fallbackFieldText,
            attrs.load<string>(field.type, 'fontFamily'),
            attrs.load<number>(field.type, 'fontSize') || defaultFontSize
          ))
        },
        fontColour: attrs.load<string>(field.type, 'fontColour'),
        fontSize: attrs.load<number>(field.type, 'fontSize') || defaultFontSize,
        fontFamily: attrs.load<string>(field.type, 'fontFamily'),
        lineHeight: attrs.load<number>(field.type, 'lineHeight') || defaultLineHeight,
        bg: attrs.load<boolean>(field.type, 'bg'),
        bgColour: attrs.load<string>(field.type, 'bgColour')
      };
    case CustomFieldType.saleAddress:
    case CustomFieldType.saleTitle:
      return {
        id: newId,
        type: field.type,
        position: {
          ...position,
          ...(estimator.estimateTextDimensions(
            getTextFieldText(field.type, party?.snapshot, data) || fallbackFieldText,
            attrs.load<string>(field.type, 'fontFamily'),
            attrs.load<number>(field.type, 'fontSize') || defaultFontSize
          ))
        },
        fontColour: attrs.load<string>(field.type, 'fontColour'),
        fontSize: attrs.load<number>(field.type, 'fontSize') || defaultFontSize,
        fontFamily: attrs.load<string>(field.type, 'fontFamily'),
        lineHeight: attrs.load<number>(field.type, 'lineHeight') || defaultLineHeight,
        bg: attrs.load<boolean>(field.type, 'bg'),
        bgColour: attrs.load<string>(field.type, 'bgColour')
      };
    case CustomFieldType.text:
      return {
        id: newId,
        type: CustomFieldType.text,
        text: 'Custom text',
        position: {
          ...position,
          ...(estimator.estimateTextDimensions(
            'Custom text',
            attrs.load<string>(field.type, 'fontFamily'),
            attrs.load<number>(field.type, 'fontSize') || defaultFontSize
          ))
        },
        fontColour: attrs.load<string>(field.type, 'fontColour'),
        fontSize: attrs.load<number>(field.type, 'fontSize') || defaultFontSize,
        fontFamily: attrs.load<string>(field.type, 'fontFamily'),
        lineHeight: attrs.load<number>(field.type, 'lineHeight') || defaultLineHeight,
        bg: attrs.load<boolean>(field.type, 'bg'),
        bgColour: attrs.load<string>(field.type, 'bgColour')
      };
    case CustomFieldType.checkmark:
      return {
        id: newId,
        type: CustomFieldType.checkmark,
        checkmark: attrs.load<string>(field.type, 'checkmark') || defaultCheckmark,
        position: {
          ...position,
          ...(estimator.estimateTextDimensions(
            attrs.load<string>(field.type, 'checkmark') || defaultCheckmark,
            SupportedFontFamily.DejaVuSansCheckmarks,
            attrs.load<number>(field.type, 'fontSize') || defaultFontSize,
            { nopad: true }
          ))
        },
        fontColour: attrs.load<string>(field.type, 'fontColour'),
        fontSize: attrs.load<number>(field.type, 'fontSize') || defaultFontSize
      };
    case CustomFieldType.timestamp:
      if (!partyId) {
        console.warn('attempted to add party field without selected party information');
        return undefined;
      }
      return {
        id: newId,
        type: CustomFieldType.timestamp,
        partyId,
        position: {
          ...position,
          ...(estimator.estimateTextDimensions(
            '22-MMM-2222',
            attrs.load<string>(field.type, 'fontFamily'),
            attrs.load<number>(field.type, 'fontSize') || defaultFontSize
          ))
        },
        fontColour: attrs.load<string>(field.type, 'fontColour'),
        fontSize: attrs.load<number>(field.type, 'fontSize') || defaultFontSize,
        fontFamily: attrs.load<string>(field.type, 'fontFamily'),
        lineHeight: attrs.load<number>(field.type, 'lineHeight') || defaultLineHeight,
        bg: attrs.load<boolean>(field.type, 'bg'),
        bgColour: attrs.load<string>(field.type, 'bgColour')
      };
    case CustomFieldType.initials:
    case CustomFieldType.signature:
      if (!partyId) {
        console.warn('attempted to add party field without selected party information');
        return undefined;
      }
      return {
        id: newId,
        type: field.type,
        partyId,
        position,
        bg: attrs.load<boolean>(field.type, 'bg'),
        bgColour: attrs.load<string>(field.type, 'bgColour')
      };
    case CustomFieldType.purchaserName:
    case CustomFieldType.purchaserAddress:
      return {
        id: newId,
        type: field.type,
        position: {
          ...position,
          ...(estimator.estimateTextDimensions(
            '123 Quite Long Name For A Terrace, Rather Large Suburb Name, WWW 5555',
            attrs.load<string>(field.type, 'fontFamily'),
            attrs.load<number>(field.type, 'fontSize') || defaultFontSize
          ))
        },
        fontColour: attrs.load<string>(field.type, 'fontColour'),
        fontSize: attrs.load<number>(field.type, 'fontSize') || defaultFontSize,
        fontFamily: attrs.load<string>(field.type, 'fontFamily'),
        lineHeight: attrs.load<number>(field.type, 'lineHeight') || defaultLineHeight,
        bg: attrs.load<boolean>(field.type, 'bg'),
        bgColour: attrs.load<string>(field.type, 'bgColour')
      };
    case CustomFieldType.contractDate:
      return {
        id: newId,
        type: field.type,
        position: {
          ...position,
          ...(estimator.estimateTextDimensions(
            // previously we'd only calculate enough space to display a formatted date, but then someone raised a bug
            // that this cuts off the 'Contract Date' label when on the field editing screen, which looks weird.
            // so, now we calculate enough space for <[icon] Contract Date> instead of a date like 22-MAR-2024.
            'ICO Contract Date',
            attrs.load<string>(field.type, 'fontFamily'),
            attrs.load<number>(field.type, 'fontSize') || defaultFontSize
          ))
        },
        fontColour: attrs.load<string>(field.type, 'fontColour'),
        fontSize: attrs.load<number>(field.type, 'fontSize') || defaultFontSize,
        fontFamily: attrs.load<string>(field.type, 'fontFamily'),
        lineHeight: attrs.load<number>(field.type, 'lineHeight') || defaultLineHeight,
        bg: attrs.load<boolean>(field.type, 'bg'),
        bgColour: attrs.load<string>(field.type, 'bgColour')
      };
    case CustomFieldType.remoteCheck: {
      const label = '';
      const item = {
        id: newId,
        type: field.type,
        position,
        fontColour: attrs.load<string>(field.type, 'fontColour'),
        fontSize: attrs.load<number>(field.type, 'fontSize') || defaultFontSize,
        fontFamily: attrs.load<string>(field.type, 'fontFamily'),
        lineHeight: attrs.load<number>(field.type, 'lineHeight') || defaultLineHeight,
        group: getNextGroupId(existingFields || [], 'check-'),
        label
      };
      autoResize(item, { estimator, force: true });
      return item;
    }
    case CustomFieldType.remoteRadio: {
      const label = '';
      const item = {
        id: newId,
        type: field.type,
        position,
        fontColour: attrs.load<string>(field.type, 'fontColour'),
        fontSize: attrs.load<number>(field.type, 'fontSize') || defaultFontSize,
        fontFamily: attrs.load<string>(field.type, 'fontFamily'),
        lineHeight: attrs.load<number>(field.type, 'lineHeight') || defaultLineHeight,
        group: getNextGroupId(existingFields || [], 'radio-'),
        label
      };
      autoResize(item, { estimator, force: true });
      return item;
    }
    case CustomFieldType.remoteText: {
      const item: CustomFieldConfiguration = {
        id: newId,
        type: field.type,
        position,
        fontColour: attrs.load<string>(field.type, 'fontColour'),
        fontSize: attrs.load<number>(field.type, 'fontSize') || defaultFontSize,
        fontFamily: attrs.load<string>(field.type, 'fontFamily'),
        lineHeight: attrs.load<number>(field.type, 'lineHeight') || defaultLineHeight,
        bg: attrs.load<boolean>(field.type, 'bg'),
        bgColour: attrs.load<string>(field.type, 'bgColour'),
        required: true
      };
      autoResize(item, { estimator, force: true });
      return item;
    }
    default:
      console.warn(`Cannot add ${field.type} field. Not yet implemented.`);
      return undefined;
  }
}

function getNextGroupId(fields: CustomFieldConfiguration[], prefix: 'radio-' | 'check-'): string {
  const existingGroups = new Set<string>();
  for (const field of fields) {
    if ('group' in field && field.group) {
      existingGroups.add(field.group);
    }
  }
  let next = existingGroups.size + 1;
  let candidate = `${prefix}${next}`;
  while (existingGroups.has(candidate)) {
    next = next + 1;
    candidate = `${prefix}${next}`;
  }
  return candidate;
}

type MinimapThumbnailActionHandler = (pageIndex: number, action: 'rotate_cw' | 'rotate_ccw' | 'delete' | 'undelete') => Promise<void>;

function MinimapActionWrapper({ pageIndex, onAction, allowDelete, deleted, children }: React.PropsWithChildren<{
  pageIndex: number,
  onAction: MinimapThumbnailActionHandler,
  allowDelete: boolean,
  deleted: boolean
}>) {
  const [confirmingDelete, setConfirmingDelete] = useState(false);

  return <div
    key={pageIndex}
    className='mt-2 position-relative'
  >
    <div
      key='toolbar'
      className='d-flex flex-row justify-content-between w-100 thumbnail-toolbar gap-2 p-1'
      style={{ background: 'var(--clr-bg-pdf-toolbar)', backdropFilter: 'blur(3px)' }}
    >
      <div className='d-flex flex-row gap-2'>
        {!deleted && <Button
          variant='secondary'
          title='Rotate page 90 degrees clockwise'
          onClick={() => onAction(pageIndex, 'rotate_cw')}
        ><Icon name='rotate_90_degrees_cw'/></Button>}
        {!deleted && <Button
          variant='secondary'
          title='Rotate page 90 degrees counter-clockwise'
          onClick={() => onAction(pageIndex, 'rotate_ccw')}
        ><Icon name='rotate_90_degrees_ccw'/></Button>
        }
      </div>
      <div>
        {allowDelete && !deleted && <Button
          variant='secondary'
          title='Remove page'
          onClick={() => setConfirmingDelete(true)}
        ><Icon name='delete'/></Button>}
        {deleted && <Button
          variant='secondary'
          title='Restore page'
          onClick={() => onAction(pageIndex, 'undelete')}
        ><Icon name='restore'/></Button>}
      </div>
    </div>
    <div className='position-relative w-100 h-100'>
      {children}
      {deleted && <div
        className='position-absolute w-100 h-100 d-flex align-items-center justify-content-center'
        style={{ backdropFilter: 'blur(3px) brightness(0.8)', zIndex: '1', bottom: '0' }}
      >
        <span className='fs-4 fw-bold'>Deleted</span>
      </div>}
    </div>
    {confirmingDelete && <Modal key='modal' show={true} onHide={() => setConfirmingDelete(false)}>
      <Modal.Header>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        You're about to delete this page from the document.
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-secondary' onClick={() => setConfirmingDelete(false)}>Cancel</Button>
        <Button variant='danger' onClick={() => {
          onAction(pageIndex, 'delete');
          setConfirmingDelete(false);
        }}>Delete</Button>
      </Modal.Footer>
    </Modal>}
  </div>;
}

function clampFieldPosition(field: CustomFieldConfiguration, page: ScalingDimensions) {
  // clamp rect to within the pdf page dimensions
  field.position.x = clamp(field.position.x, 0, page.width - field.position.width);
  field.position.y = clamp(field.position.y, 0, page.height - field.position.height);
  field.position.width = clamp(field.position.width, 1, page.width);
  field.position.height = clamp(field.position.height, 1, page.height);
}
