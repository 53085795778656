import React, { useContext, useEffect, useState } from 'react';
import { YjsDocContext } from '../../context/YjsDocContext';
import { FormContext } from '../../context/FormContext';
import { FormTypes, mapSigningPartySourceTypeToCategory } from '@property-folders/common/yjs-schema/property/form';
import { useLightweightTransaction } from '../../hooks/useTransactionField';
import {
  FormInstance,
  MaterialisedPropertyData,
  PartyType,
  SigningAuthorityType,
  SigningPartySource,
  TransactionMetaData
} from '@property-folders/contract';
import {
  addMissingSigningOrderSettings,
  addMissingSigningParties,
  FormUtil,
  removeUnexpectedSigningOrderSettings,
  removeUnexpectedSigningParties
} from '@property-folders/common/util/form';
import { useImmerYjs } from '../../hooks/useImmerYjs';
import { materialisePropertyData } from '@property-folders/common/yjs-schema/property';
import { Predicate } from '@property-folders/common/predicate';
import { Button, Modal } from 'react-bootstrap';
import { uuidv4 } from 'lib0/random';
import CreatableSelect from 'react-select/creatable';
import { createFilter } from 'react-select';
import { upperFirst } from 'lodash';
import { PartyAuthorityInput } from '../form/PartyAuthorityInput';
import { purchaserTypeOptions } from '@property-folders/common/util/pdfgen/constants';

export function CustomisePartiesSection() {
  const { ydoc, transactionRootKey, transactionMetaRootKey } = useContext(YjsDocContext);
  const { formName: formCode, formId } = useContext(FormContext);
  const family = FormTypes[formCode].formFamily;
  const { value: formInstance } = useLightweightTransaction<FormInstance>({
    myPath: FormUtil.getFormPath(formCode, formId),
    bindToMetaKey: true
  });
  const { value: sublineageRoots } = useLightweightTransaction<string[]>({
    myPath: 'sublineageRoots',
    bindToMetaKey: true
  });
  const { value: data } = useLightweightTransaction<MaterialisedPropertyData>({
    myPath: ''
  });
  const [editOtherContact, setEditOtherContact] = useState<{ id: string, create: boolean } | undefined>(undefined);
  const { binder: dataBinder } = useImmerYjs<MaterialisedPropertyData>(ydoc, transactionRootKey);
  const { binder: metaBinder } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);

  // construct list of options
  const [options, setOptions] = useState<SigningPartySource[]>([]);
  useEffect(() => {
    if (!data) {
      setOptions([]);
      return;
    }
    const newOpts: SigningPartySource[] = [];
    newOpts.push(...FormUtil.getSalespersonSigningSources(data, true));
    newOpts.push(...FormUtil.getPartySigningSources(data, 'vendors', { type: 'vendor' }, true));
    newOpts.push(...FormUtil.getPartySigningSources(data, 'purchasers', { type: 'purchaser' }, true));
    if (ydoc) {
      for (const sublineageId of sublineageRoots || []) {
        newOpts.push(...FormUtil.getPartySigningSources(
          materialisePropertyData(ydoc, sublineageId),
          'purchasers',
          { type: 'purchaser' },
          true,
          sublineageId
        ));
      }
    }
    newOpts.push(...FormUtil.getPartySigningSources(data, 'otherContacts', { type: 'other' }, true));
    setOptions(newOpts);
  }, [data]);
  const partyColours = new Map((formInstance?.signing?.parties || [])
    .map(p => ([partyColourKey(p.source), p.colour])));
  const selected = (formInstance?.signing?.parties || [])
    .map(p => options.find(o => p.source.id === o.id && p.source.type === o.type && p.source.sublineageId === o.sublineageId))
    .filter(Predicate.isNotNull);

  return <>
    {editOtherContact && <EditOtherContactModal
      id={editOtherContact.id}
      creating={editOtherContact.create}
      onClose={cancel => {
        try {
          if (editOtherContact.create) {
            if (cancel) {
              dataBinder?.update(draft => {
                if (!draft.otherContacts?.length) return;
                const index = draft.otherContacts.findIndex(oc => oc.id === editOtherContact.id);
                if (index < 0) return;
                draft.otherContacts.splice(index, 1);
              });
              return;
            }

            // figure out the option and add it?
            const createdOptions = options.filter(opt => opt.id === editOtherContact?.id);
            if (createdOptions.length && dataBinder) {
              metaBinder?.update(draft => {
                const instance = draft.formStates?.[family].instances?.find(i => i.id === formId);
                if (!instance) return;

                addMissingSigningOrderSettings(instance, new Set(createdOptions
                  .map(o => mapSigningPartySourceTypeToCategory(o.type))
                  .filter(Predicate.isNotNull)
                ));
                addMissingSigningParties(instance, createdOptions);
              });
            }
          }
        } finally {
          setEditOtherContact(undefined);
        }
      }}
    />}
    <div className='d-flex flex-row justify-content-between align-items-center mt-3 pb-1'>
      <span className='fs-5 fw-bold'>Parties</span>
      <div>
        <Button
          variant='outline-secondary'
          className=''
          onClick={() => {
            if (!dataBinder) return;
            const contactId = uuidv4();
            dataBinder.update(draft => {
              if (!draft.otherContacts) {
                draft.otherContacts = [];
              }
              draft.otherContacts.push({
                id: contactId,
                partyType: PartyType.Individual,
                authority: SigningAuthorityType.self
              });
            });
            setEditOtherContact({ id: contactId, create: true });
          }}
        >Add new party</Button>
      </div>
    </div>
    <CreatableSelect
      isMulti
      isSearchable
      isClearable={false}
      options={options}
      value={selected}
      filterOption={createFilter({
        trim: true,
        ignoreCase: true,
        matchFrom: 'any',
        ignoreAccents: true,
        stringify: option => option.data._optimisation?.searchable || ''
      })}
      onChange={(value) => {
        metaBinder?.update(draft => {
          const instance = draft.formStates?.[family].instances?.find(i => i.id === formId);
          if (!instance) return;
          if (!dataBinder) return;
          const expectedTypes = new Set(value
            .map(v => mapSigningPartySourceTypeToCategory(v.type))
            .filter(Predicate.isNotNull));
          removeUnexpectedSigningOrderSettings(instance, expectedTypes);
          addMissingSigningOrderSettings(instance, expectedTypes);

          const expectedParties = [...value];
          removeUnexpectedSigningParties(instance, expectedParties);
          addMissingSigningParties(instance, expectedParties);
        });
      }}
      onCreateOption={value => {
        if (!dataBinder) return;
        const contactId = uuidv4();
        dataBinder.update(draft => {
          if (!draft.otherContacts) {
            draft.otherContacts = [];
          }
          draft.otherContacts.push({
            id: contactId,
            partyType: PartyType.Individual,
            authority: SigningAuthorityType.self,
            fullLegalName: value
          });
        });

        setEditOtherContact({ id: contactId, create: true });
      }}
      formatCreateLabel={value => {
        return value;
      }}
      formatOptionLabel={(data, meta) => {
        if ('__isNew__' in data && data.__isNew__) {
          return `Create new contact: ${meta.inputValue}`;
        }

        const partyType = mapSigningPartySourceTypeToCategory(data.type);
        const isOther = partyType === 'other';

        if (meta.context === 'value') {
          return <div>
            <div><span>{data._optimisation?.name || 'Unknown'}</span></div>
            <small className='text-muted d-flex justify-content-between gap-1'>
              <span>{upperFirst(partyType)}</span>
              {isOther && <a href='#' className='text-black text-decoration-underline' onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setEditOtherContact({
                  id: data.id,
                  create: false
                });
              }}>Edit</a>}
            </small>
          </div>;
        }

        const small = [
          upperFirst(partyType),
          data._optimisation?.email || '',
          data._optimisation?.phone || ''
        ].map(x => x.trim()).filter(x => !!x).join(', ');

        return <div>
          <div><span>{data._optimisation?.name || 'Unknown'}</span></div>
          <div><small className='text-muted'>{small}</small></div>
        </div>;
      }}
      getOptionLabel={x => x._optimisation?.searchable || ''}
      getOptionValue={x => x._optimisation?.searchable || ''}
      styles={{
        control: (base, state) => ({
          ...base,
          ...state.isFocused ? {
            borderColor: '#8db7fb',
            outline: '0',
            boxShadow: '0 0 0 0.25rem rgba(26, 110, 246, 0.25)',
            ':hover': {
              borderColor: '#8db7fb'
            }
          } : undefined,
          borderRadius: '0',
          backgroundColor: 'var(--bs-body-bg)'
        }),
        menu: base => ({ ...base, borderRadius: '0', zIndex: 6 }),
        multiValue: (base, state) => {
          const partyColour = partyColours.get(partyColourKey(state.data));
          return ({
            ...base,
            ...partyColour ? {
              borderLeft: `4px solid ${partyColour}`
            } : undefined
          });
        },
        multiValueRemove: base => ({
          ...base,
          alignItems: 'top',
          paddingTop: '6px',
          ':hover': {
            backgroundColor: 'lightgrey'
          }
        }),
        option: (base, state) => ({
          ...base,
          ...state.isFocused ? {
            backgroundColor: '#e9ecef'
          } : undefined,
          ':hover': { backgroundColor: '#e9ecef' }
        })
      }}
    />
  </>;
}

function EditOtherContactModal({ id, creating, onClose }: {
  id: string,
  creating: boolean,
  onClose: (cancel: boolean) => void
}) {
  return <Modal show size='lg' onHide={() => onClose(true)}>
    <Modal.Header>
      <Modal.Title>{creating ? 'Create' : 'Edit'} Other Contact</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <PartyAuthorityInput
        thisLevel={1}
        hideOnTitleField={true}
        primaryIdAbsPath={'primaryOtherContact'}
        partyLabel='Other Contact'
        typeOptions={purchaserTypeOptions}
        myPath={`otherContacts.[${id}]`}
        hideDelete={true}
        setAutoComplete={false}
        hideRootPrimaryContactCheckbox={true}
        otherContact={true}
      />
    </Modal.Body>
    <Modal.Footer>
      {creating && <Button variant='outline-secondary' onClick={() => onClose(true)}>Cancel</Button>}
      <Button onClick={() => onClose(false)}>Ok</Button>
    </Modal.Footer>
  </Modal>;
}

function partyColourKey(source: SigningPartySource): string {
  return `${source.id}|${source.type}`;
}
