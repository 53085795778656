import { Button, Col, Container, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import React, { useContext, useMemo, useState } from 'react';
import { canonicalisers, formatTimestamp } from '@property-folders/common/util/formatting';
import * as Y from 'yjs';
import { buildAndDownloadServeForm } from '@property-folders/components/dragged-components/signing/ServeToPurchaserSection';
import { AsyncButton } from '@property-folders/components/dragged-components/AsyncButton';
import { BareAddressSelector } from '@property-folders/components/dragged-components/address/BareAddressSelector';
import { usePropertyGnafLocation } from '@property-folders/components/hooks/usePropertyGnafLocation';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { PropertyFormYjsDal } from '@property-folders/common/yjs-schema/property/form';
import { useLightweightTransaction } from '../../hooks/useTransactionField';
import {
  ProspectivePurchaserGroup,
  ServedByAgent,
  ServeStateRecipient,
  SigningParty
} from '@property-folders/contract';
import { Hint, Typeahead } from 'react-bootstrap-typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { stringifySaleAddress } from '@property-folders/common/util/stringifySaleAddress';
import { FormCode, MasterRootKey, MaterialisedPropertyData, META_APPEND, TransactionMetaData } from '@property-folders/contract/yjs-schema/property';
import { getContactsFromAuthorityParty } from '@property-folders/common/util/digest-authority-party-details';
import { Predicate } from '@property-folders/common/predicate';
import { TextClickCheck } from '../../dragged-components/form/TextClickCheck';
import { DateFunctions } from '@property-folders/common/util/date';

type InputChangeEvent = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
interface ServablePurchaser {
  key: string;
  name: string;
  address: string;
  email: string;
  agentDocPurchaser?: {
    sublineage: string | null,// Null being the root document, buuut given we serve to purchasers, should probably always be filled
    sourceId: string
    // Not recording formId or formCode, because the data is derived from the data model, not signing parties. For signing state, we'll have to just look it (the RSC family instances) up
  }
}

export function ServeToNewPurchaserModal({
  formCode,
  formId,
  onClose,
  purchaser,
  unservedRecipient,
  recipientId,
  contractMode,
  contractSignedDate,
  form1Name,
  form1Address,
  originatingForm
}: {
  formCode: string,
  formId: string,
  onClose: () => void,
  purchaser?: SigningParty,
  unservedRecipient?: ServeStateRecipient,
  recipientId?: string, // If we're overriding the recipient because it's an old one
  contractMode?: boolean,
  contractSignedDate?: string,
  form1Name?: string,
  form1Address?: string,
  originatingForm?: ServeStateRecipient['originatingForm']
}) {

  const existingDetails = purchaser
    ? { name: purchaser.snapshot?.name, email: purchaser.snapshot?.email, address: purchaser.snapshot?.addressSingleLine, isPrimary: purchaser.snapshot?.isPrimary }
    : unservedRecipient
      ? { name: unservedRecipient.name, email: unservedRecipient.email, address: unservedRecipient.address, isPrimary: true }
      : {};

  const { ydoc, transactionRootKey, transactionMetaRootKey } = useContext(YjsDocContext);
  const { value: ppGroups } = useLightweightTransaction<ProspectivePurchaserGroup[]>({
    bindToMetaKey: true,
    myPath: 'offerManagement.prospectivePurchasers'
  });
  const { value: entityId } = useLightweightTransaction<number>({ bindToMetaKey: true, parentPath: 'entity', myPath: 'id' });
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();

  const agentServing: ServedByAgent | undefined = useMemo(()=>{
    if (!sessionInfo || !entityId) return undefined;
    const entity = sessionInfo.entities.find(e=>e.entityId === entityId);
    if (!entity) console.warn ('agent not in folder declared entity');
    return {
      agentUuid: sessionInfo.agentUuid,
      entityUuid: entity?.entityUuid
    };
  }, [sessionInfo]);

  const servablePurchasers = useMemo(() => {
    const distinct = new Map<string, ServablePurchaser>();

    // Hmm, don't think I have a good way to reactively watch all the sublineages. We'll just have
    // to read them statically for now
    const subLines = (ydoc?.getMap(MasterRootKey.Meta).toJSON() as TransactionMetaData).sublineageRoots;

    for (const lineageKey of subLines??[]) {
      const data = (ydoc?.getMap(lineageKey).toJSON() as MaterialisedPropertyData);
      const purchasers = (data.purchasers??[]).filter(p=>p.partyType);

      // Primary in this context means the primary point of contact for this particular purchaser party
      const contacts = purchasers
        .map(p=>({
          sourceParty: p,
          contacts: getContactsFromAuthorityParty(p, p.id, { setPrimaryToFirst: p.id!==data.primaryPurchaser, realPrimaryPurchaserIdForAddress: data.primaryPurchaser, allPurchasersForAddress: purchasers })
        }))
        .map(clist=>{
          const primary = clist.contacts.find(c=>c.isPrimary);
          return primary ? { sourceParty: clist.sourceParty, contact: primary } : null;
        })
        .filter(Predicate.isTruthy);

      for (const contactWrapper of contacts) {
        const contact = contactWrapper.contact;
        if (!(contactWrapper && contact.fullLegalName && contact.email)) continue;
        const key = getPurchaserKey(contact.fullLegalName, contact.email);
        if (distinct.has(key)) continue;

        distinct.set(key, {
          key,
          name: contact.fullLegalName,
          address: contact.address ?? '',
          email: contact.email,
          agentDocPurchaser: {
            sublineage: lineageKey,
            sourceId: contactWrapper.sourceParty.id
          }
        });
      }
    }

    // Prioritise agent doc purchasers over prospective purchasers, as we can actually track their
    // document signing state

    for (const ppg of ppGroups || []) {
      if (!ppg.primaryPurchaser) continue;

      const primary = ppg.purchaserParties.find(party => party.id === ppg.primaryPurchaser);
      if (!primary) continue;
      if (!primary.fullLegalName) continue;
      if (!primary.email) continue;
      if (!primary.address) continue;

      const key = getPurchaserKey(primary.fullLegalName, primary.email);
      if (distinct.has(key)) continue;

      distinct.set(key, {
        key,
        name: primary.fullLegalName,
        address: primary.address || '',
        email: primary.email
      });
    }

    return [...distinct.values()];
  }, [ppGroups]);
  const [selected, setSelected] = useState<Option[]>([]);

  const propertyGnafLocation = usePropertyGnafLocation();

  const [show, setShow] = useState(true);
  const [name, setName] = useState(existingDetails.name||'');
  const [address, setAddress] = useState(existingDetails.address||'');
  const [email, setEmail] = useState(existingDetails.email||'');
  const [contractDate, setContractDate] = useState('');
  const [date, setDate] = useState('');
  const [emailValid, setEmailValid] = useState(!!existingDetails.email);
  const [preFilled, setPreFilled] = useState(false);
  const [purchaserContractSigned, setPurchaserContractSigned] = useState(false);
  const [signingStateLocks, setSigningStateLocks] = useState(false);

  const addressRequired = (!address && !contractMode) || (contractMode && existingDetails.isPrimary && !address);
  const now = new Date();

  const handleEmailChange = (e: InputChangeEvent) => {
    const value = e.target.value;
    setEmail(value);
    if (value) {
      setEmailValid(!!canonicalisers.email(value).valid);
    } else {
      setEmailValid(false);
    }
  };

  const handleCancel = () => {
    setShow(false);
    onClose();
  };

  const handleSend = async () => {
    try {
      await createServeToPurchaserRecord({
        ydoc,
        formCode,
        formId,
        id: recipientId??unservedRecipient?.id??purchaser?.id,
        name,
        address,
        email,
        contractDate: purchaserContractSigned || signingStateLocks ? contractDate : '',
        dataRootKey: transactionRootKey,
        metaRootKey: transactionMetaRootKey,
        servingAgent: agentServing,
        addressRequired,
        originatingForm
      });
      setShow(false);
      onClose();
    } catch (err: unknown) {
      console.error(err);
    }
  };

  const handleDownload = async () => {
    try {
      await createServeToPurchaserRecord({
        ydoc,
        formCode,
        formId,
        id: recipientId??unservedRecipient?.id??purchaser?.id,
        name,
        address,
        contractDate: contractDate || contractSignedDate || '',
        form1Name,
        form1Address,
        download: true,
        dataRootKey: transactionRootKey,
        metaRootKey: transactionMetaRootKey,
        servingAgent: agentServing,
        addressRequired,
        originatingForm
      });
      setShow(false);
      onClose();
    } catch (err: unknown) {
      console.error(err);
    }
  };

  return <Modal show={show} onHide={handleCancel}>
    <Modal.Header closeButton><Modal.Title>Serve to Purchaser</Modal.Title></Modal.Header>
    <Modal.Body>
      <Container>
        <Row>
          <Col>
            {(purchaser || unservedRecipient)
              ? <FloatingLabel label='Name'>
                <Form.Control
                  id='purchaser-name'
                  type='text'
                  disabled={!!existingDetails.name}
                  value={existingDetails.name}
                  className='mb-2'
                  tabIndex={2}
                />
                <Form.Control.Feedback type={'invalid'}>Must be valid address.</Form.Control.Feedback>
              </FloatingLabel>
              : <Typeahead
                id='select-purchaser-name'
                labelKey='name'
                options={servablePurchasers}
                filterBy={['name', 'email']}
                placeholder={''}
                onChange={x => {

                  setSelected(x);
                  const first = x.at(0);
                  if (!first) {
                    setName('');
                    setEmail('');
                    setEmailValid(true);
                    setAddress('');
                    setPreFilled(false);
                    setPurchaserContractSigned(false);
                    if (signingStateLocks) {
                      setSigningStateLocks(false);
                      setContractDate('');
                    }
                    return;
                  }

                  if (typeof first === 'object' && 'customOption' in first && first.customOption) {
                    setName(first.name);
                    setEmail('');
                    setEmailValid(true);
                    setAddress('');
                    setPreFilled(false);
                    setPurchaserContractSigned(false);
                    if (signingStateLocks) {
                      setSigningStateLocks(false);
                      setContractDate('');
                    }
                    return;
                  }

                  const purchaser = first as ServablePurchaser;
                  setName(purchaser.name);
                  setEmail(purchaser.email);
                  setEmailValid(true);
                  setAddress(purchaser.address);
                  setPreFilled(true);
                  if (purchaser.agentDocPurchaser) {
                    const metaDoc = ydoc?.getMap(purchaser.agentDocPurchaser.sublineage+META_APPEND).toJSON() as TransactionMetaData;
                    if (!metaDoc?.formStates) return;
                    const latestSignedInstance = PropertyFormYjsDal.getLatestSignedInstanceForSourceParty(FormCode.RSC_ContractOfSale, purchaser.agentDocPurchaser.sourceId, metaDoc);
                    if (latestSignedInstance?.signing?.session?.completedTime) {
                      setContractDate(DateFunctions.isoDateFormatInTz(latestSignedInstance.signing.session.completedTime, 'Australia/Adelaide'));
                      setSigningStateLocks(true);
                    }
                  }
                }}
                selected={selected}
                renderInput={({ inputRef, referenceElementRef, value, ...inputProps }) => (
                  <Hint>
                    <FloatingLabel label="Purchaser" className='flex-grow-1 common-label mb-2'>
                      <Form.Control
                        value={value as string | string[] | number | undefined}
                        {...inputProps}
                        ref={(node: any) => {
                          inputRef(node);
                          referenceElementRef(node);
                        }}
                        tabIndex={1}
                      />
                    </FloatingLabel>
                  </Hint>
                )}
                renderMenuItemChildren={option => {
                  const purchaser = option as ServablePurchaser;
                  return <div>
                    {purchaser.name}
                    <div>
                      <small>{purchaser.email}</small>
                    </div>
                  </div>;
                }}
                onBlur={x => {
                  setName(x.target.value);
                }}
                emptyLabel='No matching prospective purchasers found.'
              >
              </Typeahead>}
          </Col>
        </Row>
        <Row>
          <Col>
            {!preFilled && !existingDetails.address
              ? <BareAddressSelector
                label='Postal Address'
                selectIntermediateAddressLine={true}
                onAddressSelect={(value, maybeMoreDetails) => {
                  if (maybeMoreDetails?.Postcode) {
                    value = stringifySaleAddress({ streetAddr_parts: maybeMoreDetails });
                  }
                  setAddress(value);
                }}
                id='address-selector'
                international={true}
                className='mb-2'
                gnafCentre={propertyGnafLocation}
                tabIndex={2}
              />
              : <FloatingLabel label='Postal Address'>
                <Form.Control
                  id='purchaser-address'
                  placeholder=''
                  type='text'
                  disabled={preFilled || !!existingDetails.address}
                  value={address}
                  className='mb-2'
                  tabIndex={2}
                />
                <Form.Control.Feedback type={'invalid'}>Must be valid address.</Form.Control.Feedback>
              </FloatingLabel>}
          </Col>
        </Row>
        <Row>
          <Col>
            <FloatingLabel label='Email' className='flex-grow-1 common-label'>
              <Form.Control
                id='purchaser-email'
                placeholder=''
                type='text'
                autoComplete='email'
                className='mb-2'
                onChange={handleEmailChange}
                isInvalid={!!email && !emailValid}
                disabled={preFilled || !!existingDetails.email}
                value={email}
                tabIndex={3}
              />
              <Form.Control.Feedback type={'invalid'}>Must be valid email address.</Form.Control.Feedback>
            </FloatingLabel>
          </Col>
        </Row>
        {!contractMode && <Row>
          <Col><TextClickCheck
            label={'This Purchaser has signed a contract'}
            onSelected={()=>{setPurchaserContractSigned(ps=>!ps);}}
            checked={signingStateLocks || purchaserContractSigned}
            disabled={signingStateLocks}
          />
          </Col>
        </Row>}
        {!contractMode && (purchaserContractSigned || signingStateLocks) && <Row>
          <Col>
            <FloatingLabel label='Contract Date' className='flex-grow-1 common-label'>
              <Form.Control
                disabled={signingStateLocks}
                id='contract-date'
                placeholder=''
                type='date'
                max={`${now.getFullYear()}-${(now.getMonth()+1).toString().padStart(2,'0')}-${(now.getDate()).toString().padStart(2,'0')}`}
                onChange={e=> setContractDate(e.target.value)}
                isInvalid={false}
                value={contractDate}
                tabIndex={4}
              />
              <Form.Control.Feedback type={'invalid'}>Must be valid email address.</Form.Control.Feedback>
            </FloatingLabel>
          </Col>
        </Row>}
      </Container>
    </Modal.Body>
    <Modal.Footer>
      <Button variant='outline-secondary' onClick={handleCancel} tabIndex={6}>Cancel</Button>
      <AsyncButton variant='outline-secondary' disabled={!name || (!address && !contractMode) || (contractMode && existingDetails.isPrimary && !address)} onClick={handleDownload} tabIndex={5}>Download</AsyncButton>
      <AsyncButton disabled={!email || !name || (!address && !contractMode) || (contractMode && existingDetails.isPrimary && !address) || !emailValid} onClick={handleSend} tabIndex={4}>Send</AsyncButton>
    </Modal.Footer>
  </Modal>;
}

async function createServeToPurchaserRecord({
  ydoc,
  formCode,
  formId,
  id,
  email,
  address,
  name,
  contractDate,
  download,
  dataRootKey,
  metaRootKey,
  servingAgent,
  addressRequired,
  form1Name,
  form1Address,
  originatingForm
}: {
  ydoc?: Y.Doc,
  formCode: string,
  formId: string,
  id?: string,
  email?: string,
  address: string,
  name: string,
  contractDate: string,
  download?: boolean,
  dataRootKey: string,
  metaRootKey: string,
  servingAgent: ServedByAgent | undefined,
  addressRequired?: boolean,
  form1Name?: string,
  form1Address?: string,
  originatingForm?: ServeStateRecipient['originatingForm']
}) {
  if (!ydoc) throw new Error('Data state not initialised');
  if (!name) throw new Error('Name required');
  if (!address && addressRequired) throw new Error('Address required');

  const formDal = new PropertyFormYjsDal(ydoc, dataRootKey, metaRootKey);
  const signing = formDal.getFormSigningSession(formCode, formId);

  if (!signing?.id) {
    throw new Error('No active signing session');
  }

  if (download) {
    await buildAndDownloadServeForm({
      ydoc,
      name: form1Name || name,
      address: form1Address || address,
      contractDate,
      formCode,
      formId,
      formDal,
      timeZone: signing.initiator.timeZone,
      signingSessionId: signing.id,
      forceRegen: true
    });
  }

  const timestamp = Date.now();
  formDal.addServeRecipient(
    formCode,
    formId,
    {
      id,
      signingSessionId: signing.id,
      type: 'purchaser',
      email,
      name,
      address,
      contractDate: formatTimestamp(contractDate, undefined, false),
      originatingForm,
      timestamp,
      downloaded: download
        ? { timestamp, online: false }
        : undefined,
      serveMode: download ? 'download' : 'email',
      servedBy: servingAgent,
      // The user has manually added this recipient, this means they intend to serve it now
      autoServe: true
    }
  );
}

function getPurchaserKey(name: string, email: string): string {
  return `n:${name};e:${email}`.toLowerCase();
}
