import { Predicate } from '@property-folders/common/predicate';
import { NoneSetOrRange, PaymentTermsType, ProfessionalFeeType, UpliftType } from '@property-folders/contract';
import { useLightweightTransaction } from '../../hooks/useTransactionField';
import { accRendOpts, boolYesNoOpts, payMethodOpts, professionalCommissionFeeModeOpts, professionalFixedFeeModeOpts } from '@property-folders/common/data-and-text/constants';
import { WrField } from './CommonComponentWrappers';
import './ManualFeesSection.scss';

const modeEnabledTypes = [NoneSetOrRange.Set, NoneSetOrRange.Range];

function ProfessionalFeeSubSection () {
  const { value: profess, fullPath } = useLightweightTransaction<ProfessionalFeeType>({ myPath: 'professFee' });
  const { value: uplift } = useLightweightTransaction<UpliftType>({ myPath: 'uplift' });
  return <div className='subsection scrollspy-target' data-focus-path="subsection-profess-fee">
    <div className="w-100 d-flex flex-wrap">
      <div className='mt-2' style={{ flex: '0 1 300px' }}>
        <WrField.CheckRadio
          label='Commission'
          titleGuidanceNoteKey='calculationProfessionalFee'
          radioType='checkbox'
          options={professionalCommissionFeeModeOpts}
          valueType='int'
          inline={false}
          parentPath={fullPath}
          name='commissionMode'
          myPath='commissionMode'
        />
        {[NoneSetOrRange.Range, NoneSetOrRange.Set].includes(profess?.commissionMode)
          && <div className='d-flex flex-wrap mt-2'>
            <WrField.Control
              className='flex-grow-1'
              label={profess?.commissionMode === NoneSetOrRange.Range ? 'Minimum %' : 'Commission %'}
              parentPath={fullPath}
              name='commis'
              myPath='commis'
              style={{ width: '140px' }}
              hasAsterisks
            />
            {profess?.commissionMode === NoneSetOrRange.Range &&
              <WrField.Control
                className='flex-grow-1'
                label={'Maximum %'}
                parentPath={fullPath}
                name='commisUpper'
                myPath='commisUpper'
                style={{ width: '140px' }}
                hasAsterisks
              />}
          </div>
        }
      </div>
      <div className='mt-2' style={{ flex: '0 1 300px' }}>
        <WrField.CheckRadio
          label='Fixed Fee'
          titleGuidanceNoteKey='calculationProfessionalFee'
          radioType='checkbox'
          options={professionalFixedFeeModeOpts}
          valueType='int'
          inline={false}
          parentPath={fullPath}
          name='fixedMode'
          myPath='fixedMode'
        />
        {[NoneSetOrRange.Range, NoneSetOrRange.Set].includes(profess?.fixedMode)
          && <div className='d-flex flex-wrap mt-2'>
            <WrField.Control
              className='flex-grow-1'
              label={profess?.fixedMode === NoneSetOrRange.Range ? 'Minimum Fee' : 'Fixed Fee Amount'}
              parentPath={fullPath}
              name='fixed'
              myPath='fixed'
              style={{ width: '140px' }}
              hasAsterisks
            />
            {profess?.fixedMode === NoneSetOrRange.Range &&
              <WrField.Control
                className='flex-grow-1'
                label={'Maximum Fee'}
                parentPath={fullPath}
                name='fixedUpper'
                myPath='fixedUpper'
                style={{ width: '140px' }}
                hasAsterisks
              />}
          </div>
        }
      </div>
    </div>
    <div className="w-100 d-flex flex-wrap">
      {(Predicate.isNotNullish(profess?.fixedMode) || Predicate.isNotNullish(profess?.commissionMode))
        && <div className='mt-2 flex-grow-1' style={{ flex: '1 0 200px' }}>
          <WrField.CheckRadio
            label='Will an uplift be used?'
            titleGuidanceNoteKey='upliftCommis'
            radioType='checkbox'
            options={boolYesNoOpts}
            valueType='boolean'
            inline={true}
            name='uplift.enable'
            myPath='uplift.enable'
          />
        </div>
      }
      {Predicate.boolFalse(uplift?.enable) && <div className='mt-3' style={{ flex: '1 0 200px' }}>
        <WrField.CheckRadio
          label=''
          radioType='radio'
          options={{ true: 'Strike out section in document', false: 'Remove section from document' }}
          valueType='boolean'
          inline={false}
          name='uplift.strike'
          myPath='uplift.strike'
        />
      </div>}
    </div>
  </div>;

}

const upliftFieldStyles = { minWidth: '180px', maxWidth: '270px' };

function UpliftSection() {
  const { value: uplift, fullPath } = useLightweightTransaction<UpliftType>({ myPath: 'uplift' });
  const { value: professionalFee } = useLightweightTransaction<ProfessionalFeeType>({ myPath: 'professFee' });
  const mode = modeEnabledTypes.includes(professionalFee?.commissionMode) && modeEnabledTypes.includes(professionalFee?.fixedMode);

  return <>
    {uplift?.enable && Predicate.isNotNullish(mode)
      ? <div className='subsection scrollspy-target' data-focus-path="subsection-uplift">
        <div className='fs-4'>Commission Uplift</div>
        <div className="w-100 mt-3 d-flex flex-wrap">
          {uplift?.enable && <div className='mt-2' style={{ flex: '0 0 200px' }}>
            <WrField.CheckRadio
              label='Calculation of Uplift'
              radioType='radio'
              options={{ true: 'Percentage', false: 'Fixed Amount' }}
              valueType='boolean'
              inline={false}
              parentPath={fullPath}
              name='percentageOrFixed'
              myPath='percentageOrFixed'
            />
          </div>}

          <div className='flex-grow-1'>
            <div style={upliftFieldStyles}>
              <WrField.Control
                className='mt-2'
                key='thresh'
                label='Sale price threshold for uplift'
                parentPath={fullPath}
                myPath='thresh'
                name='thresh'
              />
            </div>
            {uplift.percentageOrFixed && <div className='flex-grow-1 mt-2' style={upliftFieldStyles}>
              <WrField.Control
                key='portion'
                label='Percentage of amount over threshold'
                parentPath={fullPath}
                myPath='portion'
                name='portion'
              />
            </div>}
            {uplift.percentageOrFixed === false && <div className='flex-grow-1 mt-2' style={upliftFieldStyles}>
              <WrField.Control
                key='fixed'
                label='Additional commission if over threshold'
                parentPath={fullPath}
                myPath='fixed'
                name='fixed'
              />
            </div>}
          </div>
        </div>
      </div>
      :<></>}
  </>;

}

function PaymentTermsSection () {
  const { value: pterm, fullPath } = useLightweightTransaction<PaymentTermsType>({ myPath: 'payTerm' });
  const enable = pterm?.enable;
  const otherMethod = pterm?.method === 'other';
  return <div className='subsection scrollspy-target' data-focus-path="subsection-pay-terms">
    <div className='fs-4'>Terms of payment</div>
    <div className="w-100 mt-2 d-flex flex-wrap">
      <div style={{ flex: '0 1 300px' }}>
        <WrField.CheckRadio
          label='Should section be printed?'
          radioType='radio'
          options={boolYesNoOpts}
          valueType='boolean'
          inline={true}
          parentPath={fullPath}
          name='enable'
          myPath='enable'
        />
      </div>
      {Predicate.boolFalse(pterm?.enable) && <div className='mt-3' style={{ flex: '1 0 200px' }}>
        <WrField.CheckRadio
          label=''
          radioType='radio'
          options={{ true: 'Strike out section in document', false: 'Remove section from document' }}
          valueType='boolean'
          inline={false}
          name='payTerm.strike'
          myPath='payTerm.strike'
        />
      </div>}
      {enable && <div className='flex-grow-1'>
        <WrField.CheckRadio
          label='Approved marketing budget paid in advance?'
          radioType='checkbox'
          options={boolYesNoOpts}
          valueType='boolean'
          inline={true}
          parentPath={fullPath}
          name='marketingPaid'
          myPath='marketingPaid'
        />
      </div>}
    </div>
    {enable && <>
      <div className="w-100 mt-2 d-flex">
        <div className='flex-grow-1'>
          <WrField.Select
            label="Method of payment"
            name='method'
            myPath='method'
            parentPath={fullPath}
            options={payMethodOpts}
          />
        </div>
        <div className='flex-grow-1'>
          <WrField.Select
            label="Accounts rendered frequency"
            name='rendFreq'
            myPath='rendFreq'
            parentPath={fullPath}
            options={accRendOpts}
          />
        </div>
      </div>
      {otherMethod && <div className="w-100 mt-2 d-flex">
        <div className='flex-grow-1'>
          <WrField.Control
            label="Other payment method"
            name='methodOther'
            myPath='methodOther'
            parentPath={fullPath}
          />
        </div>
      </div>}
      <div className="w-100 mt-2">
        <WrField.Control
          label='Payment terms'
          textArea={{ rows: 3 }}
          parentPath={fullPath}
          myPath='actualTerms'
          name='actualTerms'
        />
      </div>
      <div className='mt-2 text-wrap'>
      A surcharge of <span className='mx-2 d-inline-block' style={{ width: '80px' }}><WrField.Control parentPath={fullPath} myPath='surcharge' name='surcharge' placeholder='%' textEnd/></span> will apply to all payments authorised to recover back charges and merchant fees.
      </div>
    </>}
  </div>;
}

export function ProfessionalFeeSection () {

  return <>
    <ProfessionalFeeSubSection/>
    <UpliftSection/>
  </>;
}
