import { NOT_SET_STR } from '@property-folders/common/data-and-text/constants';
import { Predicate } from '@property-folders/common/predicate';
import { getContactsFromAuthorityParty } from '@property-folders/common/util/digest-authority-party-details';
import { canonicalisers } from '@property-folders/common/util/formatting';
import { commaAndReducer } from '@property-folders/common/util/formatting/string-composites';
import { getVendorConfig } from '@property-folders/common/util/stagedDataTransformations';
import { PartyType, PurchaserParty, SigningAuthorityType, VendorParty, Agent, SaleAddress, VendorPartyJoint, jointTypes } from '@property-folders/contract';
import { Card } from 'react-bootstrap';

function formatPhone (phone: string) {
  return canonicalisers.phone(phone).display;
}

export function SidebarParties(props: {
  vendors: VendorParty[] | undefined,
  purchasers: PurchaserParty[] | undefined,
  agent: Agent[] | undefined
  primaryVendorId: string | undefined
  propertyAddress: SaleAddress | undefined
}) {
  const vendorList = [...props.vendors??[]].sort((a,b) => {
    if (a.id === props.primaryVendorId) return -1;
    if (b.id === props.primaryVendorId) return 1;
    return 0;
  });

  function PartyListSummariser(vp: VendorParty | PurchaserParty | VendorPartyJoint) {

    let liveVendor = vp as VendorParty | PurchaserParty | VendorPartyJoint;
    const rElems = [];
    let mainName = liveVendor.fullLegalName ?? '';

    const address = vp.addrSameAsSale
      ? `${props.propertyAddress?.streetAddr}, ${props.propertyAddress?.subStateAndPost}`
      : vp.addressSingleLine;
    const isPrimary = liveVendor.id===props.primaryVendorId;

    let workingAuthority = liveVendor.authority;
    if (jointTypes.includes(liveVendor.partyType)) {
      const vj = vp as VendorPartyJoint;
      const primarySub = isPrimary ? vj?.namedExecutors?.find(subE=>subE.id === vj.primaryNamedExecutor) : vj?.namedExecutors?.[0];
      mainName = vj?.namedExecutors
        ?.map(subE=>subE.fullLegalName)
        .filter(Predicate.isTruthy)
        .reduce(commaAndReducer, '');
      workingAuthority = primarySub?.authority;
      if (primarySub) liveVendor = primarySub;
    }

    let contactDetailsAlreadyPushed = false;

    const primaryContact = getContactsFromAuthorityParty(liveVendor, liveVendor.id).filter(c=>c.isPrimary)[0];

    if (workingAuthority) {
      let typePrefix = '';
      if (liveVendor.partyType) {
        switch (liveVendor.partyType) {
          case PartyType.ExecutorCompany:
          case PartyType.ExecutorNatural:
          case PartyType.ExecutorJoint:
            typePrefix = 'Executor\'s';
            break;
          case PartyType.AdministratorCompany:
          case PartyType.AdministratorNatural:
          case PartyType.AdministratorJoint:
            typePrefix = 'Administrator\'s';
            break;
        }
      }

      if (typePrefix) {
        typePrefix = typePrefix + ' ';
      }

      let authorityShort: string[] = [];
      switch (workingAuthority) {
        case SigningAuthorityType.attorney:
        case SigningAuthorityType.attorneyJoint:
          authorityShort = ['Attorney'];
          break;
        case SigningAuthorityType.guardian:
        case SigningAuthorityType.guardianJoint:
          authorityShort = ['Guardian'];
          break;
        case SigningAuthorityType.authRep:
          authorityShort = ['Authorised Rep'];
          break;
        case SigningAuthorityType.directors2:
          authorityShort = ['Director', 'Director'];
          break;
        case SigningAuthorityType.directorSecretary:
          authorityShort = ['Director', 'Secretary'];
          break;
        case SigningAuthorityType.sole:
          authorityShort = ['Director/Secretary'];
          break;
      }

      if (authorityShort.length) contactDetailsAlreadyPushed = true;

      rElems.push(...authorityShort.map((auth, idx) => {
        const useDetail = authorityShort.length <= 1
          ? primaryContact
          : null;
        const email = useDetail ? useDetail.email : idx ? liveVendor.email2 : liveVendor.email1;
        const phone = useDetail ? useDetail.phone : idx ? liveVendor.phone2 : liveVendor.phone1;
        const shownPerson = useDetail ? useDetail.name : idx ? liveVendor?.personName2 : liveVendor?.personName1;
        return <div key={`${liveVendor.id}-${idx}`} className='associated-above'>
          <div><small>({typePrefix}{auth} - {shownPerson})</small></div>
          <div><small>{address}</small></div>
          <div><small><a href={`mailto:${email}`}>{email}</a></small></div>
          <div><small><a href={`tel:${phone}`}>{formatPhone(phone)}</a></small></div>
        </div>;
      }));
    }

    const { appointedAlwaysApplicable } = getVendorConfig(liveVendor);
    if (vp.partyType && [PartyType.MortgageeCompany, PartyType.MortgageeNatural, PartyType.MortgageeJoint].includes(liveVendor.partyType)) {
      // Check and set this separately as we could technically also have them as trustees on behalf of.
      mainName = mainName + ` as Mortgagee${vp.partyType === PartyType.MortgageeJoint && vp.namedExecutors.length>1?'s':''}`;
    }
    if (appointedAlwaysApplicable || vp.inTrust) {
      let trustPhrase = 'as trustee';
      if (liveVendor?.partyType === PartyType.AdministratorCompany || liveVendor?.partyType === PartyType.AdministratorNatural) {
        trustPhrase = 'as Administrator';
      }
      if (liveVendor?.partyType === PartyType.ExecutorCompany || liveVendor?.partyType === PartyType.ExecutorNatural) {
        trustPhrase = 'as Executor';
      }
      if (vp?.partyType === PartyType.AdministratorJoint) trustPhrase = 'as Administrators';
      if (vp?.partyType === PartyType.ExecutorJoint) trustPhrase = 'as Executors';
      if (vp?.partyType === PartyType.MortgageeJoint) trustPhrase = 'as trustees';

      mainName = mainName + ` ${trustPhrase}`;
    }

    const primaryElem = isPrimary
      ? <div className='chip'>Primary</div>
      : undefined;

    rElems.splice(0, 0, <li key={`${liveVendor.id}-special`} className='key-info'>{mainName}{primaryElem}</li>);

    if (!contactDetailsAlreadyPushed) {

      const email = liveVendor.email1;
      const phone = liveVendor.phone1;
      rElems.push(
        <div key={`${liveVendor.id}-associated-above`} className='associated-above'>
          <div><small>{address}</small></div>
          <div><small><a href={`mailto:${email}`}>{email}</a></small></div>
          <div><small><a href={`tel:${phone}`}>{formatPhone(phone)}</a></small></div>
        </div>
      );
    }

    return rElems;

  }

  return <Card className="p-3 m-2">
    <div className='fw-bold card-title'>{`Vendor${Array.isArray(vendorList) && vendorList.length > 1 ? 's' : ''}`.toLocaleUpperCase()}</div>
    <div className='summary-card-body'>
      {Array.isArray(vendorList) && vendorList.length > 0
        ? <ul>
          {vendorList?.map(PartyListSummariser).flat()}

        </ul>
        : NOT_SET_STR
      }
    </div>
  </Card>;

}
