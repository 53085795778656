import { useLightweightTransaction, useTransactionField } from '../../hooks/useTransactionField';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import '../Form.scss';
import { CollectionRemoveButton } from './CollectionRemoveButton';
import { MultiLineAddressSelector } from './MultilLineAddressSelector';
import { Suggestion } from './Suggestor';
import { useYdocBinder } from '../../hooks/useYdocBinder';
import { bindSetOrUnsetIfUndefined } from '@property-folders/common/util/immerTools';
import { composeStreetAddressFromParts, composeSubStatePostFromParts } from '@property-folders/common/util/formatting/string-composites';
import { Hundred } from './Hundred';
import { Lga } from './Lga';
import { WrField } from './CommonComponentWrappers';
import { IrrigationArea } from './IrrigationArea';
import { useEffect, useState } from 'react';
import { useEntity } from '../../hooks/useEntity';
import { SaleAddress, TransactionMetaData } from '@property-folders/contract';

type AddressInputProps = TransactionConsumerProps & {
  placeholder?: string,
  removable?: boolean,
  editable?: boolean,
  hideDelete?: boolean
  onUpdate?: (el: any) => void;
  onDelete?: (el: any) => void;
  duplicate?: boolean;
  autoFocus?: boolean;
  minimalUi?: boolean;
};

export const NarrowAddressInput = ({ placeholder, removable = true, editable = true, hideDelete = false, onUpdate, onDelete, duplicate, autoFocus, minimalUi = false, ...restProps }: AddressInputProps): JSX.Element => {
  const { fullPath, handleRemove, value: addressObj } = useTransactionField(restProps);
  const { updateDraft } = useYdocBinder({ path: fullPath });
  const closeButton = handleRemove && <CollectionRemoveButton removable={removable} onRemove={ () => { if (onDelete) { onDelete(addressObj); } handleRemove(); } } />;
  const [doShowAdditionalDescription, setDoShowAdditionalDescription] = useState('additionalDesc' in addressObj && addressObj.additionalDesc);
  const { value: meta } = useLightweightTransaction<TransactionMetaData>({ myPath: '', bindToMetaKey: true });
  const entitySettings = useEntity(meta?.entity?.id);

  useEffect(()=> {
    if (!entitySettings?.showLegalLandDescriptionOverride) {
      updateDraft?.((draft:SaleAddress) => {
        draft.useAdditionalDescAsLegalDesc = false;
      });
    }
  }, [entitySettings?.showLegalLandDescriptionOverride]);

  const onAddrSelect = (suggestion: Suggestion) => {
    if (suggestion.AddressParts === undefined) {
      return;
    }

    updateDraft?.(draft => {
      const setAddrField = bindSetOrUnsetIfUndefined(draft);
      const parts = suggestion.AddressParts;

      draft.streetAddr = composeStreetAddressFromParts(parts);
      draft.subStateAndPost = composeSubStatePostFromParts(parts);
      draft.streetAddr_parts = parts;
      draft.gnaf = suggestion.GNAF;
      delete draft.fromLssa;

      setAddrField('hundred', suggestion.Hundred||'Out of Hundreds');
      setAddrField('irrigationArea', suggestion.IrrigationArea||'');
      setAddrField('lga', suggestion.LGA?.Name);
      setAddrField('suburb', parts.Suburb);
    });

    onUpdate?.({ suggestion, address: addressObj });
  };

  const onSuburbSelect = (suggestion: Suggestion) => {

    updateDraft?.(draft => {
      const setAddrField = bindSetOrUnsetIfUndefined(draft);

      draft.subStateAndPost = `${suggestion.Name} ${suggestion.State} ${suggestion.Postcode}`;
      setAddrField('suburb', suggestion.Name);

      let lga = '';
      if (suggestion.LGA?.length === 1) {
        lga = suggestion.LGA[0];
      }

      let hundred = '';
      if (suggestion.Hundred?.length === 1) {
        hundred = suggestion.Hundred[0];
      }

      let irrigationArea = '';
      if (suggestion.IrrigationArea?.length === 1) {
        irrigationArea = suggestion.IrrigationArea[0];
      }

      setAddrField('lga', lga);
      setAddrField('hundred', hundred);
      setAddrField('irrigationArea', irrigationArea);
    });

    onUpdate?.(suggestion.FullAddress);
  };

  if (!editable) {
    return <div className='d-flex w-100 flex-column'>
      <div><span className='fs-5'>{addressObj?.streetAddr || 'Address'}</span></div>
      {addressObj?.subStateAndPost && <div><span className='text-muted'>{addressObj.subStateAndPost}</span></div>}
      {addressObj?.lga && <div><span className='text-muted'>{addressObj.lga}</span></div>}
      {addressObj?.hundred && <div><span className='text-muted'>Hundred of {addressObj.hundred}</span></div>}
      {addressObj?.additionalDesc && <div><span className='text-muted'>{addressObj?.additionalDesc}</span></div>}
    </div>;
  }

  return (
    <div className="d-flex w-100 gapped-row extra-gap narrow-address-input">
      <div className='w-100'>
        <div className="d-flex w-100 flex-wrap">
          <div style={{ flexGrow: 3, flexShrink: 0 }}>
            <MultiLineAddressSelector
              parentPath={fullPath}
              myPath={['streetAddr', 'subStateAndPost']}
              autoFocus={autoFocus}
              onSuburbSelect={onSuburbSelect}
              onAddrSelect={onAddrSelect}
              primaryConnected={!!addressObj?.streetAddr_parts}
              onPrimaryDisconnect={() => updateDraft?.(draft => {
                delete draft.streetAddr_parts;
              })}
              duplicate={duplicate}
              onChange={e => {
                updateDraft?.(draft => {
                  delete draft.fromLssa;
                });
                onUpdate?.({ address: addressObj });
              }}
              fromLssa={addressObj?.fromLssa}
            />
          </div>
          <div style={{ flexGrow: 2, flexShrink: 2, width: '110px', display: minimalUi ? 'none' : 'block' }}>
            <Hundred myPath="hundred" parentPath={fullPath} onChange={() => {
              onUpdate?.({ address: addressObj });
            }}/>
          </div>
          {addressObj?.irrigationArea && <div style={{ flexGrow: 2, flexShrink: 2, width: '110px', display: minimalUi ? 'none' : 'block' }}>
            <IrrigationArea myPath="irrigationArea" parentPath={fullPath} onChange={() => {
              onUpdate?.({ address: addressObj });
            }}/>
          </div>}
          <div style={{ flexGrow: 3, flexShrink: 1, display: minimalUi ? 'none' : 'block' }}>
            <Lga myPath="lga" parentPath={fullPath} onChange={() => {
              onUpdate?.({ address: addressObj });
            }}/>
          </div>
        </div>
        {doShowAdditionalDescription
          ? <div className={'d-flex flex-row'}>
            <div className={'flex-grow-1'}><WrField.Control textArea={{ rows: 2, minHeight: '5rem', height: '5rem' }} parentPath={fullPath} myPath='additionalDesc' name={fullPath+'.additionalDesc'} label='Additional description of Property' maxLength={2000}/></div>
            {entitySettings?.showLegalLandDescriptionOverride && <div className={'align-content-end'}>
              <WrField.BoolCheck parentPath={fullPath} myPath='useAdditionalDescAsLegalDesc' name={fullPath+'.useAdditionalDescAsLegalDesc'} label='Use as legal description' className={'mt-2 ms-2'} />
            </div>}
          </div>
          : <button type='button' className='btn btn-link' onClick={() => setDoShowAdditionalDescription(true)}>Add additional description of property</button>
        }
      </div>
      <div className="d-flex flex-wrap justify-content-center" style={{ maxWidth: '32px' }}>
        {(!hideDelete) && <div className='delete-div'>
          {closeButton}
        </div>}
      </div>
    </div>
  );
};
