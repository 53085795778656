import { Icon } from '@property-folders/components/dragged-components/Icon';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { SetupPdfLoadStateContext } from '@property-folders/components/context/pdfLoadStateContext';
import { PDFViewer, PDFViewerRefProps, ZoomMode } from './PDFViewer';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { PreviewFallback } from '../../display/errors/pdf';

export type ProcessPdfFunc = (blob: Blob) => Promise<Blob>;

async function preparePdf(
  url: string,
  filename?: string,
  processPdf?: ProcessPdfFunc
): Promise<{ pdfUrl: string, filename: string }> {
  filename = filename || 'document.pdf';

  if (url.startsWith('blob:')) {
    // note: processPdf is currently ignored in this scenario.
    return { pdfUrl: url, filename };
  }
  const ajaxResponse = await fetch(url);

  if (ajaxResponse.headers.has('Content-Type') && ajaxResponse.headers.get('Content-Type') === 'application/pdf') {
    const blob = processPdf
      ? await processPdf(await ajaxResponse.blob())
      : await ajaxResponse.blob();

    return {
      pdfUrl: URL.createObjectURL(blob),
      filename
    };
  }

  const ajax = await ajaxResponse.json();
  if (!(ajax.success && ajax.link)) throw new Error('Could not prepare pdf');

  const dataResponse = await fetch(ajax.link);
  if (!dataResponse.ok) throw new Error('Could not fetch data');

  const blob = processPdf
    ? await processPdf(await dataResponse.blob())
    : await dataResponse.blob();

  return {
    pdfUrl: URL.createObjectURL(blob),
    filename: typeof ajax.name === 'string' && ajax.name
      ? ajax.name
      : filename
  };
}
type PreparePdfResult = Awaited<ReturnType<typeof preparePdf>>;

export interface PDFPreviewerProps {
  url: string,
  onClose: () => void,
  useCoolSpinner?: boolean,
  tryMakeScrollWork?: boolean,
  processPdf?: ProcessPdfFunc,
  filename?: string,
  renderTextLayer?: boolean
}

/**
 * Standalone fullscreen wrapper over PDFViewer component.
 * For cases where you just have an external url to download/render.
 */
function PDFPreviewerUnwrapped({
  url,
  onClose,
  useCoolSpinner,
  tryMakeScrollWork,
  processPdf,
  filename,
  renderTextLayer = false
}: PDFPreviewerProps) {

  const topbarHeight = '40px';
  const ref = useRef<HTMLElement>(null);
  const viewerRef = useRef<PDFViewerRefProps>(null);
  const [showModal, setShowModal] = useState(true);
  const [dataError, setDataError] = useState(false);
  const [data, setData] = useState<PreparePdfResult | undefined>(undefined);
  const close = useCallback(() => {
    setShowModal(false);
    onClose();
  }, []);
  const scrollContainerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    preparePdf(url, filename, processPdf)
      .then(data => setData(data))
      .catch(err => {
        console.error(err);
        setDataError(true);
      });
  }, [url]);

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (!e.isTrusted) return;
      switch (e.key) {
        case 'PageDown':
        case 'PageUp':
          if (!tryMakeScrollWork) return;
          if (!scrollContainerRef.current) return;
          scrollContainerRef.current.focus();
          scrollContainerRef.current.click();
          scrollContainerRef.current.dispatchEvent(new KeyboardEvent(e.type, e));
          return;
        case 'Escape':
          close();
          return;
        default:
          return;
      }
    };

    document.addEventListener('keydown', keydownHandler);
    return () => {
      document.removeEventListener('keydown', keydownHandler);
    };
  }, []);

  if (!showModal) {
    return <></>;
  }

  return <div ref={ref} className={'d-flex flex-column'} style={{ position: 'fixed', top: '0', left: '0', right: '0', bottom: '0', zIndex: '10000' }}>
    <input type='hidden' />
    {!data && <><div className={'p-0 border-0'} style={{ height: topbarHeight }}>
      <div className={'w-100 h-100 m-0 text-white d-flex flex-row justify-content-end'}
        style={{ background: 'var(--clr-bg-pdf-toolbar)', padding: '5px' }}>
        <Button variant={'secondary'} className={'cursor-pointer'}
          style={{ backgroundColor: 'var(--clr-bg-pdf-toolbar)', width: '26px', height: '26px', padding: '0' }}
          onClick={close}>
          <Icon name={'close'}/>
        </Button>
      </div>
    </div>
    <div className={'p-0 overflow-hidden'} style={{ background: '#2A2A2E', color: 'white', height: `calc(100% - ${topbarHeight})` }}>
      <div className={'w-100 h-100 d-flex flex-column justify-content-center align-items-center'}>
        {!dataError && useCoolSpinner && <div>
          <i className={'la la-empire spinner'} style={{ fontSize: '128px', margin: 'auto' }}></i>
        </div>}
        {!dataError && !useCoolSpinner && <Spinner animation="border" style={{ width: '6rem', height: '6rem' }}/>}
        {!dataError && <span className={'fs-1'}>Preparing your preview...</span>}
        {dataError && <span className={'fs-1'}>There was an error preparing your preview.</span>}
      </div>
    </div></>}
    {!!data && <SetupPdfLoadStateContext><div className={'p-0 h-100'} style={{ backgroundColor: '#2A2A2E' }}>
      <PDFViewer
        ref={viewerRef}
        scrollContainerRef={scrollContainerRef}
        pdfUrl={data.pdfUrl}
        bookmark=''
        filename={data.filename}
        allowPrint={true}
        renderTextLayer={renderTextLayer}
        zoomMode={ZoomMode.Manual}
        activeViews={1}
        toolbarRight={
          <Button variant="secondary" title='Close' onClick={close}>
            <Icon name="close" />
          </Button>
        }
        useLoadSuccessForCompletion={true}
      />
    </div></SetupPdfLoadStateContext>}
  </div>;
}

export function PDFPreviewer(props: PDFPreviewerProps) {
  return <ErrorBoundary fallbackRender={fallback=><div style={{ position: 'fixed', top: '0', left: '0', right: '0', bottom: '0', zIndex: '10000' }}>
    <PreviewFallback {...fallback} >
      <Button variant='link' onClick={props.onClose}>Dismiss</Button>
    </PreviewFallback>
  </div>} >
    <PDFPreviewerUnwrapped {...props} />
  </ErrorBoundary>;
}
