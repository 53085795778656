import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Dropdown, NavItem, NavLink, Placeholder } from 'react-bootstrap';
import { Icon } from './Icon';
import React, { useEffect, useRef, useState } from 'react';
import { UserUtil } from '@property-folders/common/util/user';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { Link, useNavigate } from 'react-router-dom';
import { useOnline } from '../hooks/useOnline';
import { sortBy } from 'lodash';
import { useSelector } from 'react-redux';
import { BelongingEntityMeta } from '@property-folders/common/redux-reducers/entityMeta';
import clsJn from '@property-folders/common/util/classNameJoin';
import { useFeatureFlags } from '../hooks/useFeatureFlags';
import { AgentAvatar } from '../display/AgentAvatar';
import { useIsGreatforms } from '../hooks/useIsGreatforms';
import { AjaxPhp } from '@property-folders/common/util/ajaxPhp';

export type TopNavMenuProps = {
  onSideMenuClick?: () => void;
  showSaved?: boolean;
  showSaving?: boolean;
  showUpdating?: boolean;
  showUpdated?: boolean;
  onLogoutClick?: () => void;
  isSideMenuCollapsed?: boolean;
};

type TopNavUserProps = {
  onLogoutClick?: () => void;
  expanded: boolean;
};

const NoChevronDropdownToggle = React.forwardRef(({ children, onClick, className }, ref) => {
  const classes = (className as string).split(' ').filter(t=>t!=='dropdown-toggle').join(' ');
  return <NavLink
    href=""
    className={classes}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </NavLink>;
});

export function TopNavExternalLink({ icon, text, href }: { icon: string, text: string, href: string}) {
  return <NavDropdown.Item href={href} target='_blank'>
    <Icon name={icon} icoClass={'me-2 fs-unset'}/>{text}
  </NavDropdown.Item>;
}

export function TopNavInternalAction({ icon, text, onClick }: { icon: string, text: string, onClick?: () => void }) {
  return <NavDropdown.Item onClick={onClick}>
    <Icon name={icon} icoClass={'me-2 fs-unset'}/>{text}
  </NavDropdown.Item>;
}

export function TopNavUser(props: TopNavUserProps) {
  const {
    data: sessionInfo
  } = AuthApi.useGetAgentSessionInfo();
  const navigate = useNavigate();
  const isOnline = useOnline();
  const [show, setShow] = useState(false);
  const navRef = useRef<HTMLDivElement>();
  const entityMeta =  useSelector((state: any) => state?.entityMeta as BelongingEntityMeta[] | undefined);
  const featureFlags = useFeatureFlags();

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (!navRef?.current?.contains?.(event.target)) {
        setShow(false);
      }
    }

    try {
      document.addEventListener('mousedown', handleClickOutside);
      document.querySelector('iframe:not(#print-iframe)')?.contentWindow?.addEventListener('click', handleClickOutside);
    } catch (e) {
      console.error('unable to add iframe event listener', e);
    }

    return () => {
      try {
        document.removeEventListener('mousedown', handleClickOutside);
      } catch (e) {
        console.error('unable to remove mousedown event listener', e);
      }
      try {
        document.querySelector('iframe')?.contentWindow?.removeEventListener('click', handleClickOutside);
      } catch (e) {
        console.error('unable to remove iframe event listener', e);
      }
    };
  }, [navRef, show]);

  // we don't really care about (loading/error/success) here.
  // if session info isn't known (expired according to the online server, or not stored) the user will be booted to the login screen
  if (!sessionInfo) {
    return <Dropdown as={NavItem} ref={navRef} align="end" className="flex-shrink-0">
      <Dropdown.Toggle as={NoChevronDropdownToggle} className={clsJn('user-settings-top', props.expanded && 'expanded')}>
        <Placeholder as={'span'} animation={'glow'}>
          <Placeholder className={'user-initials'}></Placeholder>
        </Placeholder>
      </Dropdown.Toggle>
    </Dropdown>;
  }

  const { name, agentId,  isGlobalAdmin, email, impersonator } = sessionInfo;
  const initials = UserUtil.getInitials(name);
  const style = UserUtil.getThumbnailStyle(name, agentId);
  const displayName = name || (isGlobalAdmin ? 'Administrator' : email || '');
  const firstEntity = sortBy(sessionInfo.entities, [e => e.entityId])?.[0];

  const managementEntities = sessionInfo.entities.filter(entity=>entity.roles.includes('Manager'));
  const emEntity = entityMeta?.[firstEntity?.entityId];
  const companyName = emEntity?.tradeName || emEntity?.name || '';

  return (
    <div className={'d-flex flex-row w-100 overflow-visible'}>
      <div className={'flex-grow-0 overflow-visible'}>
        <Dropdown as={NavItem} ref={navRef} align="end" drop={'up'} className="flex-shrink-0 no-hover overflow-visible" show={show} onClick={() => {
          setShow(s => !s);
        }}>
          <Dropdown.Toggle as={NoChevronDropdownToggle} className={clsJn('user-settings-top', props.expanded && 'expanded')}>
            <AgentAvatar agent={sessionInfo}/>
          </Dropdown.Toggle>
          <Dropdown.Menu className="shadow user-menu">
            {isOnline && <>
              <NavDropdown.Header className='d-flex flex-row align-items-center'><Icon name='settings' icoClass='fs-5 me-1' />Settings</NavDropdown.Header>
              <TopNavInternalAction icon={'person'} onClick={() => {navigate('/user_profile.php');}} text={'Manage Profile'} />
              {(managementEntities.length > 0 || isGlobalAdmin) && <TopNavInternalAction
                icon={'business'}
                onClick={() => navigate('/agency-settings')}
                text={'Agency Settings'}/>}
              <NavDropdown.Divider></NavDropdown.Divider>
              <TopNavInternalAction icon={'mail'} onClick={() => {
                navigate('/emailstatus.php');
              }} text={'E-mail Status'}/>
              <TopNavInternalAction icon={'edit'} onClick={() => {
                navigate('/esignstatus.php');
              }} text={'Pending eSignings'}/>
              <NavDropdown.Divider></NavDropdown.Divider>
              {!sessionInfo.featureFlags?.newFormsCreated && <>
                <TopNavInternalAction icon={'note'} onClick={() => {
                  navigate('/formsarchived.php');
                }} text={'Archived Documents'}/>
                <NavDropdown.Divider></NavDropdown.Divider>
              </>}
              {managementEntities.length > 0 || isGlobalAdmin ?
                <TopNavInternalAction icon={'playlist_add'} onClick={() => {
                  navigate('/clauses');
                }} text={'Clauses'}/> : null}
              {(sessionInfo.canTemplate || sessionInfo.canMarketingTemplate) &&
                <TopNavInternalAction icon={'content_paste'} onClick={() => {
                  navigate('/user_form_templates.php');
                }} text={'Form Templates'}/>}
              <TopNavInternalAction icon={'mail'} onClick={() => {
                navigate('/user_email_templates.php');
              }} text={'E-mail Templates'}/>
              <NavDropdown.Divider></NavDropdown.Divider>
              <TopNavInternalAction icon={'lock'} onClick={() => {
                document?.querySelector?.('iframe') ? document?.querySelector?.('iframe')?.contentWindow?.openDialog?.('Modal_Password') : navigate('/change_password.php');
              }} text={'Change Password'}/>
              <NavDropdown.Divider></NavDropdown.Divider>
              {sessionInfo.isGlobalAdmin && <>
                <NavDropdown.Header>Admin</NavDropdown.Header>
                <TopNavInternalAction icon={'display_settings'} onClick={() => {
                  navigate('/admin/admin_devform.php');
                }} text={'Forms Development'}/>
                <TopNavInternalAction icon={'person'} onClick={() => {
                  navigate('/admin/admin_users.php');
                }} text={'Customers'}/>
                <TopNavInternalAction icon={'group'} onClick={() => {
                  navigate('/admin/admin_agents.php');
                }} text={'Agents'}/>
                <TopNavInternalAction icon={'hourglass_bottom'} onClick={() => {
                  navigate('/admin/admin_trials.php');
                }} text={'Trials'}/>
                <TopNavInternalAction icon={'list'} onClick={() => {
                  navigate('/admin/admin_formlist.php');
                }} text={'Complete Forms List'}/>
                <TopNavInternalAction icon={'description'} onClick={() => {
                  navigate('/admin/admin_allformscreated.php');
                }} text={'All Forms Created'}/>
                <TopNavInternalAction icon={'bar_chart'} onClick={() => {
                  navigate('/admin/admin_topformslist.php');
                }} text={'Form Yearly'}/>
                <TopNavInternalAction icon={'legend_toggle'} onClick={() => {
                  navigate('/admin/admin_topagencylist.php');
                }} text={'Agency Stats'}/>
                <TopNavInternalAction icon={'equalizer'} onClick={() => {
                  navigate('/admin/admin_topformslist_agency.php');
                }} text={'Form Monthly Stats'}/>
                <TopNavInternalAction icon={'area_chart'} onClick={() => {
                  navigate('/admin/admin_report.php');
                }} text={'Reports'}/>
                <TopNavInternalAction icon={'inbox'} onClick={() => {
                  navigate('/admin/admin_email_templates.php');
                }} text={'Email Templates'}/>
                <NavDropdown.Divider></NavDropdown.Divider>
              </>}
              <TopNavExternalLink icon={'policy'} href={LinkBuilder.reaformsTerms} text={'Terms of Use'}/>
              <TopNavExternalLink icon={'mail'} href={LinkBuilder.reaformsMailToSupport} text={'Contact Support'}/>
              <TopNavExternalLink icon={'school'} href={LinkBuilder.reaformsUserGuide} text={'User Guide'}/>
              <TopNavExternalLink icon={'phone'} href={LinkBuilder.reaformsPhone} text={'08 8166 1188'}/>
              <NavDropdown.Divider></NavDropdown.Divider>
            </>}
            {impersonator && <TopNavInternalAction icon={'arrow_back'} onClick={() => {
              AjaxPhp.loginAs(impersonator.agentId).then(() => {
                window.location.href = '/';
              });
            }} text={`Back to ${impersonator.name}`}/>
            }
            <TopNavInternalAction icon={'logout'} onClick={props.onLogoutClick} text={'Logout'}/>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {props.expanded && <div id="session-username" className={'d-flex flex-column justify-content-center'} style={{ lineHeight: '1.1rem' }}>
        <div className={'fw-semibold'}>{displayName}</div>
        <div className={'fw-normal'}>{companyName.toString()}</div>
      </div>}
    </div>
  );
}

export function TopNavMenu(props: TopNavMenuProps) {
  const isOnline = useOnline();
  const isGreatforms = useIsGreatforms();
  const logo = isGreatforms
    ? LinkBuilder.propertiesImages('greatforms_logo_strap.svg')
    : LinkBuilder.propertiesImages('reaforms_logo_strap.svg');

  return (
    <Navbar bg="light" expand="md" className={clsJn('top-nav-menu header pb-1 pt-1', props.isSideMenuCollapsed && 'side-menu-collapsed')}>
      <Container fluid className={'ps-1'}>
        <Navbar.Brand className="flex-shrink-1 ps-2">
          <Link to={isOnline ? LinkBuilder.homePage : '/properties'}>
            <img id="logo-large" alt="reaforms" src={logo} className="d-inline-block"></img>
            <img id="logo-small" alt="reaforms" src={LinkBuilder.propertiesImages('reaforms_icon.svg')} className="d-inline-block"></img>
          </Link>
        </Navbar.Brand>
        <div className="d-flex flex-row">
          <TopNavUser onLogoutClick={props.onLogoutClick}></TopNavUser>
        </div>
      </Container>
    </Navbar>
  );
}
