import { useTransactionField } from '../../hooks/useTransactionField';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { WrField } from './CommonComponentWrappers';
import '../Form.scss';
import { CollectionEditor } from './CollectionEditor';
import { NarrowSalesAgentInput } from './NarrowSalesAgentInput';
import { CollectionRemoveButton } from './CollectionRemoveButton';
import { useSelector } from 'react-redux';
import { BelongingEntityMeta, REDUCER_NAME as entityMetaKey } from '@property-folders/common/redux-reducers/entityMeta';

import { canonicalisers } from '@property-folders/common/util/formatting';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { v4 } from 'uuid';
import { EntitySettingsEntity } from '@property-folders/contract/yjs-schema/model';
import { useYdocBinder } from '../../hooks/useYdocBinder';
import { bindSetOrUnsetIfUndefined } from '@property-folders/common/util/immerTools';
import { companyTradingAs } from '@property-folders/common/util/formatting';
import { SetHeaderActionsFn } from '../Wizard/WizardStepPage';
import { Predicate } from '@property-folders/common/predicate';

type SalesRepInputProps = TransactionConsumerProps & {
  removable?: boolean,
  hideDelete?: boolean,
  noPersonAuto?: boolean
  thisLevel?: number
  primaryAgentMode?: boolean
  autoFocus?: boolean
  noSuggest?: boolean
  setHeaderActions?: SetHeaderActionsFn;
  manageSalesRepresentatives?: boolean
};

export function generateDetailRow (entity: {rla: number|string, abn: number|string}) {
  return <div className='d-flex flex-grow-1 justify-content-between combo-field-subtext'>
    <div>RLA: {entity?.rla}</div>
    <div>ABN: {canonicalisers.abnacn(entity?.abn).display}</div>
  </div>;
}

export const NarrowAgentInput = ({
  noSuggest = true,
  primaryAgentMode = false,
  thisLevel = 1,
  removable = true,
  hideDelete = false,
  noPersonAuto = false,
  manageSalesRepresentatives = true,
  autoFocus,
  ...restProps
}: SalesRepInputProps): JSX.Element => {
  if (primaryAgentMode) {
    noSuggest = false;
  }
  const { value: entity, fullPath, handleRemove } = useTransactionField(restProps);
  const { valid: rlaValid, errorKeys: rlaError, mergedRules: rlaRules } = useTransactionField({ parentPath: fullPath, myPath: 'rla' });
  const { updateDraft } = useYdocBinder({ path: fullPath });

  const { value: linkedEntity, handleRemove: removeLinkedEntity } = useTransactionField({ parentPath: fullPath, myPath: 'linkedEntityId' });
  const memberEntities = useSelector((state: any) => state?.[entityMetaKey] as BelongingEntityMeta | undefined);
  const agentNameKey = primaryAgentMode ? 'profileName' : 'company';
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();

  const options = Object.values(memberEntities||{}).map(e=>{
    if (e?.archived === true || e?.useNewPropertyTransactions === false) {
      return;
    }

    const compositeName = companyTradingAs(e.name, e.tradeName);
    return {
      label: e.profileName || compositeName,
      compositeName,
      ...e
    };
  }).filter(Predicate.isNotNull);

  const closeButton = handleRemove && <CollectionRemoveButton removable={removable} onRemove={handleRemove} />;

  function suggestionSelected(selection: ({label: string} & EntitySettingsEntity)[]) {
    const suggestion = selection?.[0];
    if (!suggestion) {
      return;
    }
    const currentPerson = suggestion.salespeople?.find(person=> person.id === sessionInfo?.agentId);
    updateDraft?.(draft=>{
      const setEntityField = bindSetOrUnsetIfUndefined(draft);
      draft.company = suggestion.compositeName;
      draft.profileName = suggestion.label;
      setEntityField('abn', canonicalisers.abnacn(suggestion.abn).canonical);
      setEntityField('rla', suggestion.rla);
      setEntityField('linkedEntityId', suggestion.entityId || undefined);

      if (!Array.isArray(draft.salesp)) {
        draft.salesp = [];
      }
      for (let salespIdx = draft.salesp.length - 1; salespIdx >= 0; salespIdx--) {
        const salesp = draft.salesp[salespIdx];
        const spSource = suggestion.salespeople?.find(esp=>esp.id === salesp.linkedSalespersonId);
        if (!spSource) {
          (draft.salesp as any[]).splice(salespIdx, 1);
          continue;
        }
        if (!spSource?.id) console.warn('Loop set salesperson has lost id');
        const setSalespField = bindSetOrUnsetIfUndefined(salesp);
        setSalespField('name', spSource?.name);
        setSalespField('email', canonicalisers.email(spSource?.email).canonical);
        setSalespField('phone', canonicalisers.phone(spSource?.phone).canonical);
        setSalespField('linkedSalespersonId', spSource?.id);
      }

      if (draft.salesp.length === 0 && currentPerson?.isSalesperson) {
        if (!currentPerson?.id) console.warn('Default person set has no id');
        draft.salesp.push({
          id: v4(),
          name: currentPerson?.name || '',
          phone: canonicalisers.phone(currentPerson?.phone || '').canonical,
          email: canonicalisers.email(currentPerson?.email || '').canonical,
          linkedSalespersonId: currentPerson?.id
        });
      }
    });

  }

  function onUnbouncedFieldChange() {
    removeLinkedEntity();
  }

  function linkedIcon(text: string) {
    return linkedEntity
      ? <>{text} <i className='bi bi-link-45deg' /></>
      : text;
  }
  const extraRowData = primaryAgentMode
    ? generateDetailRow(entity)
    : undefined;

  return (
    <div className='w-100'>
      <div className="d-flex w-100 gapped-row">
        <div className='w-100'>
          <div className="d-flex w-100 flex-wrap gapped-row">
            <div className='flex-grow-1' style={{ minWidth: '300px' }}>
              {
                noSuggest
                  ? <WrField.Control
                    onChange={onUnbouncedFieldChange}
                    name={agentNameKey}
                    label={primaryAgentMode ? undefined : linkedIcon('Agency name')}
                    autoFocus={autoFocus}
                    parentPath={fullPath}
                    myPath={agentNameKey}
                    placeholder='Agent company name'
                    className={primaryAgentMode ? 'fw-bold' : undefined}
                    extraErrors={!rlaValid && rlaError.length > 0 && rlaError[0] === 'stillInvalidAfterProcessing' ? [rlaRules._validationRequirementFailedMessage] : undefined}
                  />
                  : <WrField.AutoComplete
                    onChange={onUnbouncedFieldChange}
                    options={options}
                    optionRender={(option, idx) => {
                      const entity = option as EntitySettingsEntity;
                      return <div className='d-flex flex-column'>
                        <div className='fw-bold'>{entity.label}</div>
                        {entity.profileName && <div>{entity.compositeName}</div>}
                        {generateDetailRow(entity)}
                      </div>;
                    }}
                    onSuggestSelect={suggestionSelected}
                    name={agentNameKey}
                    label={primaryAgentMode ? undefined : linkedIcon('Agency name')}
                    autoFocus={autoFocus}
                    parentPath={fullPath}
                    myPath={agentNameKey}
                    placeholder='Agent company name'
                    extraRowData={extraRowData}
                    selectOnly={primaryAgentMode}
                    className={primaryAgentMode ? 'fw-bold' : undefined}
                    extraErrors={!rlaValid && rlaError.length > 0 && rlaError[0] === 'stillInvalidAfterProcessing' ? [rlaRules._validationRequirementFailedMessage] : undefined}
                  />
              }
            </div>
          </div>
          {!primaryAgentMode && <div className="d-flex w-100 flex-wrap gapped-row">
            <div className='flex-grow-1' style={{ minWidth: '300px' }}>
              <WrField.Control onChange={onUnbouncedFieldChange} name='rla' label={linkedIcon('Agent RLA')} parentPath={fullPath} myPath='rla' placeholder='######'/>
            </div>
            <div className='flex-grow-0' style={{ minWidth: '200px' }}>
              <WrField.Control onChange={onUnbouncedFieldChange} name='abn' label={linkedIcon('Agent ABN')} parentPath={fullPath} myPath='abn' placeholder='## ### ### ###'/>
            </div>
          </div>}
        </div>
        {(!hideDelete) && <div className='d-flex align-items-center delete-div ms-1'>
          {closeButton}
        </div>}
      </div>
      {manageSalesRepresentatives && <div className='w-100 mt-3'>
        <CollectionEditor
          level={thisLevel}
          title={entity?.salesp?.length > 0 ? (entity?.salesp?.length > 1 ? 'Sales Representatives' : 'Sales Representative') : ''}
          parentPath={fullPath}
          myPath='salesp'
          setHeaderActions={restProps.setHeaderActions}
          autoAddFirst={!noPersonAuto}
          childItemRenderer={NarrowSalesAgentInput}
          itemNoun='Sales Representative'
          restorationFieldDisplay='name'
          indentLevel={hideDelete ? 0 : 1}
          addTooltip='Add an additional Sales Representative to this agency'
          childProps={{ linkedEntityId: entity?.linkedEntityId, primaryAgentMode }}
        />
      </div>}
    </div>

  );
};
