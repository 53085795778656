import { useContext, useEffect } from 'react';
import { FileStorage, FileType } from '@property-folders/common/offline/fileStorage';
import { blobTob64 } from '@property-folders/common/util/dataExtract';
import { useLiveQuery } from 'dexie-react-hooks';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { ContentType, TransactionMetaData } from '@property-folders/contract';
import { MasterRootKey } from '@property-folders/contract/yjs-schema/property';
import { v4 } from 'uuid';
import { FileSync } from '@property-folders/common/offline/fileSync';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';

export function useEntityLogoForCurrentPropertyFolder() {
  const { ydoc } = useContext(YjsDocContext);
  // sublineages won't have different logos, so root key is fine
  const { bindState: bindMetaState } = useImmerYjs<TransactionMetaData>(ydoc, MasterRootKey.Meta);
  const { data: transMeta } = bindMetaState<TransactionMetaData>(meta => meta || {});

  return useEntityLogo(transMeta?.entity?.id);
}

export function useEntityLogo(entityId?: number) {
  const { instance: fileSync } = useContext(FileSyncContext);
  // generally this won't do anything.
  // if there is no logo loaded, then make an attempt here to do so.
  useEffect(() => {
    if (!entityId) return;
    if (!fileSync) return;

    (async () => {
      try {
        const key = `logo/${entityId}`;
        const result = await FileStorage.read(key);

        if (result) {
          return;
        }

        await FileStorage.queueDownload(
          `logo/${entityId}`,
          FileType.EntityLogo,
          ContentType.Png,
          { entityLogo: { entityId: entityId, logoUri: v4() } }
        );

        FileSync.triggerSync(fileSync);
      } catch (err: unknown) {
        console.error(err);
      }
    })();
  }, [entityId, fileSync]);

  return useLiveQuery(async () => {
    const data = await FileStorage.readFileOnly(`logo/${entityId}`);
    if (!data?.size) return '';
    if (![ContentType.Png, ContentType.Jpeg].includes(data.type as ContentType)) return '';

    return await blobTob64(data);
  }, [entityId]);
}
