import { Icon } from '../../dragged-components/Icon';
import { Spinner } from 'react-bootstrap';

export function DocumentLoading(props: {loadFailed?: boolean}) {
  return <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center" style={{ background: '#2A2A2E', color: 'white' }}>
    <div>
      {props.loadFailed ? <Icon name='warning' style={{ fontSize: '5rem' }}  /> : <Spinner animation={'border'} style={{ width: '6rem', height: '6rem' }} />}

    </div>

  </div>;
}