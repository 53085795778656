import {
  ContentType,
  FormCode,
  ManifestData,
  ManifestType, Maybe,
  TransactionMetaData,
  UploadType
} from '@property-folders/contract';
import { useContext, useState } from 'react';
import { useStore } from 'react-redux';
import { v4 } from 'uuid';
import { FileStorage, FileType, StorageItemSyncStatus } from '@property-folders/common/offline/fileStorage';
import { applyMigrationsV2 } from '@property-folders/common/yjs-schema';
import { MasterRootKey } from '@property-folders/contract/yjs-schema/property';
import { FileSyncContext } from '../context/fileSyncContext';
import { useReactRouterData } from './useReactRouterHooks';
import { RouterData } from '@property-folders/web/src/App';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { YjsDocContext } from '../context/YjsDocContext';
import { ensureFormStatesInstances } from '@property-folders/common/yjs-schema/property/form';
import { FileSync } from '@property-folders/common/offline/fileSync';
import { processPdf } from '@property-folders/common/util/pdf/pdf-process';

interface UploadFile {
  filename: string;
  id: string;
  contentType: string;
}

interface UploadFileWithData extends UploadFile {
  data: ArrayBuffer;
}

export function useUploadedDocumentUpload(
  afterUpload?: (files: UploadFile[]) => void,
  type?: UploadType,
  noTriggerSync?: boolean,
  process = true
) {
  const { ydoc: ydoc1, docName: docName1 } = useContext(YjsDocContext);
  const { ydoc: ydoc2, transId: docName2 } = useReactRouterData<RouterData>();
  const { instance: fileSync } = useContext(FileSyncContext);
  const [uploadProcessing, setUploadProcessing] = useState(false);
  const [uploadFiles, setUploadFiles] = useState<Maybe<UploadFile[]>>(undefined);
  const [uploadErrors, setUploadErrors] = useState<string[]>([]);
  const store = useStore();
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();

  const propertyId = docName1 || docName2;
  const ydoc = ydoc1 || ydoc2;

  const handleDrop = async (acceptedFiles: File[]) => {
    if (!propertyId) return;
    setUploadProcessing(true);

    try {

      const { fulfilled: files, rejected: errors } = await transformUploadedFiles(acceptedFiles, process);

      setUploadFiles(files.map<UploadFile>(f => ({ id: f.id, contentType: f.contentType, filename: f.filename })));
      setUploadErrors(errors);

      if (!files.length) return;

      const manifestData: ManifestData = {
        manifestType: ManifestType.None
      };

      await Promise.all(files?.map(async file => {
        return FileStorage.write(
          file.id,
          FileType.PropertyFile,
          ContentType.Pdf,
          new Blob([file.data], { type: ContentType.Pdf }),
          StorageItemSyncStatus.PendingUpload,
          { propertyFile: {
            propertyId,
            formCode: FormCode.UploadedDocument,
            formId: file.id
          }
          },
          store,
          manifestData,
          undefined,
        );
      }));

      if (!noTriggerSync) {
        FileSync.triggerSync(fileSync);
      }

      applyMigrationsV2<TransactionMetaData>({
        doc: ydoc,
        docKey: MasterRootKey.Meta.toString(),
        typeName: 'Property',
        migrations: [{
          name: 'add uploaded documents',
          fn: state => {
            const instances = ensureFormStatesInstances(state, FormCode.UploadedDocument);

            for (const file of files) {
              instances.push({
                id: file.id,
                formCode: FormCode.UploadedDocument,
                created: new Date().getTime(),
                modified: new Date().getTime(),
                upload: {
                  name: file.filename,
                  id: file.id,
                  contentType: ContentType.Pdf,
                  type,
                  uploader: sessionInfo?.agentUuid ? {
                    id: sessionInfo.agentUuid,
                    linkedSalespersonId: sessionInfo.agentId,
                    name: sessionInfo?.name,
                    email: sessionInfo?.email
                  } : undefined
                }
              });
            }
          }
        }]
      });

      afterUpload?.(files);
    } finally {
      setUploadProcessing(false);
    }
  };

  return { uploadFiles, uploadErrors, uploadProcessing, handleDrop };
}

async function transformUploadedFiles(files: File[], process: boolean) {
  const results = await Promise.allSettled(
    files.map(async file => transformUploadedFile(file, process)));

  const fulfilled  = new Array<UploadFileWithData>();
  const rejected = new Array<string>();
  for (const result of results) {
    switch (result.status) {
      case 'fulfilled':
        fulfilled.push(result.value);
        break;
      case 'rejected':
        rejected.push(getErrorMessage(result.reason));
        break;
    }
  }

  return { fulfilled, rejected };
}

function getErrorMessage(error: any): string {
  if (typeof error === 'string') {
    return error;
  }

  if (typeof error === 'object') {
    if ('message' in error && typeof error.message === 'string') {
      return error.message;
    }
    if ('toString' in error && typeof error.toString === 'function') {
      return error.toString();
    }
  }

  return 'Unknown error';
}

async function transformUploadedFile(file: File, process: boolean): Promise<UploadFileWithData> {
  const rawData = await file.arrayBuffer();
  const id = v4();
  const filename = file.name;
  const contentType = ContentType.Pdf;
  if (!process) {
    return { id, filename, data: rawData, contentType };
  }

  const result = await processPdf(rawData);
  if (result?.isEncrypted) throw new Error('PDF is encrypted');
  if (!result?.pdf) throw new Error('File could not be processed as PDF');

  return { id, filename, data: result.pdf, contentType };
}
