import { Icon } from './Icon';

export enum CardType {
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
  AMEX = 'AMEX',
  DISCOVER = 'DISCOVER',
  DINERS = 'DINERS',
  DINERS_CARTE_BLANCHE = 'DINERS_CARTE_BLANCHE',
  JCB = 'JCB',
  VISA_ELECTRON = 'VISA_ELECTRON',
  UNKNOWN = 'UNKNOWN'
}

interface CardTypeProps {
  cardType: CardType;
}

interface CardNumberProps {
  cardNumber: string;
}

export type Props =
  (CardTypeProps | CardNumberProps)
  & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

function getCardType(number: string): CardType {
  // visa
  let re = new RegExp('^4');
  if (number.match(re) != null)
    return CardType.VISA;

  // Mastercard
  // Updated for Mastercard 2017 BINs expansion
  if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number))
    return CardType.MASTERCARD;

  // AMEX
  re = new RegExp('^3[47]');
  if (number.match(re) != null)
    return CardType.AMEX;

  // Discover
  re = new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)');
  if (number.match(re) != null)
    return CardType.DISCOVER;

  // Diners
  re = new RegExp('^36');
  if (number.match(re) != null)
    return CardType.DINERS;

  // Diners - Carte Blanche
  re = new RegExp('^30[0-5]');
  if (number.match(re) != null)
    return CardType.DINERS_CARTE_BLANCHE;

  // JCB
  re = new RegExp('^35(2[89]|[3-8][0-9])');
  if (number.match(re) != null)
    return CardType.JCB;

  // Visa Electron
  re = new RegExp('^(4026|417500|4508|4844|491(3|7))');
  if (number.match(re) != null)
    return CardType.VISA_ELECTRON;

  return CardType.UNKNOWN;
}

export function CardLogo(props: Props) {
  const { cardNumber, cardType, ...restProps } = {
    cardType: 'cardNumber' in props ? getCardType(props.cardNumber) : props.cardType,
    cardNumber: '',
    ...props
  };

  let iconName: string;

  switch (cardType) {
    case CardType.MASTERCARD:
      iconName = 'cc-mastercard';
      break;

    case CardType.VISA_ELECTRON:
    case CardType.VISA:
      iconName = 'cc-visa';
      break;

    case CardType.JCB:
      iconName = 'cc-jcb';
      break;

    case CardType.DINERS:
      iconName = 'cc-diners-club';
      break;

    case CardType.AMEX:
      iconName = 'cc-amex';
      break;

    case CardType.DISCOVER:
      iconName = 'cc-discover';
      break;

    case CardType.UNKNOWN:
    default:
      iconName = 'credit-card';
  }

  return <Icon pack='la' name={iconName} {...restProps} />;
}
