import {
  Dimension,
  drawCompoundSignatureWithinDimensions
} from '@property-folders/common/signing/draw-compound-signature';
import React, { useEffect, useState } from 'react';
import { formatTimestamp } from '@property-folders/common/util/formatting';
import clsJn from '@property-folders/common/util/classNameJoin';
import { loadImage } from './common';

export function StackedFieldImage({
  image: rawImage,
  name,
  timestamp,
  clearable,
  timeZone,
  dimensions,
  pixelsPerInch
}: {
  image: string,
  name?: string,
  timestamp?: number,
  clearable: boolean,
  timeZone: string,
  dimensions: Dimension,
  pixelsPerInch: number
}) {
  const [image, setImage] = useState<string>(rawImage);

  useEffect(() => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (!context) {
      canvas.remove();
      return;
    }

    loadImage(rawImage)
      .then(imageElement => {
        drawCompoundSignatureWithinDimensions({
          context,
          signature: imageElement,
          name: name || '',
          timestamp: timestamp && timestamp > 0
            ? formatTimestamp(timestamp, timeZone, false)
            : 'Signature upload pending',
          dimensions,
          textPixelsPerInch: pixelsPerInch
        });
        setImage(canvas.toDataURL('image/png'));
        imageElement.remove();
      })
      .catch(console.error)
      .finally(() => {
        canvas.remove();
      });
  }, [name, timestamp]);

  return <img
    src={image}
    className={clsJn({
      'h-100': true,
      'w-100': true,
      'clearable-field': clearable })}
    style={{
      aspectRatio: dimensions.width / dimensions.height
    }} />;
}
