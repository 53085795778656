import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { Maybe } from '@property-folders/common/types/Utility';
import {
  CachedParty,
  CustomFieldConfiguration,
  CustomFieldType,
  FileRef,
  FormInstanceSigning,
  IFileProvider,
  InlineFile,
  MaterialisedPropertyData,
  PendingRemoteSigningSessionAutoField,
  PendingRemoteSigningSessionField,
  PendingRemoteSigningSessionFieldCustom,
  PendingRemoteSigningSessionResult,
  RemoteSigningSessionDataField,
  SigningParty,
  SigningPartyDeclineType,
  SigningPartySnapshot,
  SigningPartySourceType,
  SigningPartyVerificationType,
  SigningSession,
  SigningSessionFieldType,
  SigningSessionSubType,
  TransactionMetaData
} from '@property-folders/contract';
import { Predicate } from '@property-folders/common/predicate';
import {
  FileStorage,
  FileType,
  IFileRelatedData,
  StorageItemFileStatus,
  StorageItemSyncStatus
} from '@property-folders/common/offline/fileStorage';
import { useLiveQuery } from 'dexie-react-hooks';
import React, { createRef, RefObject, useContext, useEffect, useRef, useState } from 'react';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';

import './SigningProcess.scss';
import { Document } from 'react-pdf';
import { PdfInformationExtractor } from '@property-folders/common/util/pdf/pdf-information-extractor';
import { useAsync } from 'react-use';
import {
  getInitialSigningState,
  SigningAction,
  SigningActionType,
  SigningImageDetail,
  SigningState,
  signingStateReducer
} from './SigningState';
import { SystemGeneratedSignatureFontConfiguration } from './adoptSignature/SelectSystemGeneratedSignature';
import { preloadFonts } from '@property-folders/common/util/image';
import { useImmerReducer } from 'use-immer';
import { scrollTo } from '@property-folders/common/util/scrolling';
import {
  OverlaidPdfPage,
  OverlaidPdfPageField,
  OverlaidPdfPageTimestamp,
  OverlaidPdfPageWitness
} from './OverlaidPdfPage';
import { InteractiveSignHere } from './SignHere';
import { AdoptSignatureModal } from './adoptSignature/AdoptSignatureModal';
import { SigningCompletionModal } from './SigningCompletionModal';
import { uuidv4 } from 'lib0/random';
import { generateHeadlineFromMaterialisedData, signFields } from '@property-folders/common/yjs-schema/property';
import * as Y from 'yjs';
import { DeclineToSignModal } from '@property-folders/components/dragged-components/signing/DeclineToSignModal';
import { PleaseReviewBanner } from '@property-folders/components/dragged-components/signing/PleaseReviewBanner';
import { useBrandConfig, useEntity } from '@property-folders/components/hooks/useEntity';
import {
  SigningProcessSmsVerification
} from '@property-folders/components/dragged-components/signing/SigningProcessSmsVerification';
import { PartyCacheYjsDal } from '@property-folders/common/yjs-schema/property/party-cache';
import { UserPreferencesMain } from '@property-folders/contract/yjs-schema/user-preferences';
import { useUserPreferences } from '@property-folders/components/hooks/useUserPreferences';
import { FieldType, parseFieldName } from '@property-folders/common/signing/pdf-form-field';
import { TandcAcceptPartyYjsDal } from '@property-folders/common/yjs-schema/property/tandc-accept-party';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { AnyAction, Store } from 'redux';
import { useStore } from 'react-redux';
import { MaybeUpdateFn } from '@property-folders/common/types/MaybeUpdateFn';
import { MasterRootKey, SignerProxyType } from '@property-folders/contract/yjs-schema/property';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { FallbackModal } from '../../display/errors/modals';
import { FileSync } from '@property-folders/common/offline/fileSync';
import { FileSyncContext } from '../../context/fileSyncContext';
import { DocumentLoading } from '../../display/form/DocumentLoading';
import { positionSortMultiPage } from '@property-folders/common/util/position-sort';

export function dataURLtoBlob(dataurl: string) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

function getPdfFileIds(session: Maybe<SigningSession>) {
  const candidateFiles = [session?.file, ...session?.intermediateFiles || []];
  const latestFile = candidateFiles.filter(Predicate.isNotNull).slice(-1)[0];
  return {
    baseFileId: session?.file.id,
    latestFileId: latestFile?.id
  };
}

class FileBlobUrlCache {
  constructor(
    private map = new Map<string, string>()
  ) {
  }

  async addAll(ids?: Array<Maybe<string>>) {
    for (const id of ids || []) {
      await this.add(id);
    }
  }

  async add(id?: string) {
    if (!id) return;
    if (this.map.has(id)) return;

    const result = await FileStorage.read(id);
    if (!result?.data) return;

    this.map.set(id, URL.createObjectURL(result.data));
  }

  get(id?: string) {
    if (!id) return undefined;
    return this.map.get(id);
  }
}

async function buildPendingRemoteSigningSessionResult(
  baseData: Maybe<Blob>,
  signing: Maybe<FormInstanceSigning>,
  session: Maybe<SigningSession>,
  party: Maybe<SigningParty>,
  cachedParties: Maybe<CachedParty[]>,
  propertyMeta: Maybe<TransactionMetaData>,
  agentPrefs?: Maybe<UserPreferencesMain>
): Promise<Maybe<PendingRemoteSigningSessionResult>> {
  if (!(baseData && signing && session && party?.snapshot)) {
    return undefined;
  }

  const extractor = new PdfInformationExtractor(await baseData.arrayBuffer());
  const rawFields = await extractor.getFieldPositions();
  const pageDimensions = await extractor.getPageDimensions();

  const initiator = session.initiator;
  const snapshot = party.snapshot;

  const parsedFields = rawFields.map(({ id, positions }) => {
    const parsed = parseFieldName(id);
    const position = positions.at(0);

    if (!(parsed && position)) {
      return undefined;
    }

    return {
      field: parsed,
      position
    };
  }).filter(Predicate.isNotNull);
  // We don't want to be keeping proxy party's signatures in the cache of the main party, so we will
  // use awareness of those details here
  const proxyMode = Predicate.proxyNotSelf(party.proxyAuthority);
  const signerName = proxyMode ? party.proxyName : party.snapshot.name;
  const signerEmail = proxyMode ? party.proxyEmail : party.snapshot.email;
  const proxySigningReaformsUsers = [SignerProxyType.Auctioneer, SignerProxyType.Salesperson].includes(party.proxyAuthority);

  const blobUrls = new FileBlobUrlCache();
  await blobUrls.addAll(session.fields.map(field => field.file?.id));
  const matchingParty = PartyCacheYjsDal.findCachedPartyInPartyCache(cachedParties, signerName, signerEmail);
  await blobUrls.add(matchingParty?.images?.signature?.id);
  await blobUrls.add(matchingParty?.images?.initials?.id);
  if (proxySigningReaformsUsers || party.source.type === SigningPartySourceType.Salesperson) {
    await blobUrls.add(agentPrefs?.images?.signature?.ref?.id);
    await blobUrls.add(agentPrefs?.images?.initials?.ref?.id);
  }
  const resultFields = session.fields
    .map<PendingRemoteSigningSessionField | undefined>(field => {
      const partyMatches = field.partyId === party.id;
      const fieldParty = (signing.parties || []).find(p => p.id === field.partyId);
      const pf = parsedFields.find(x => x?.field.type === FieldType.FieldValue && x?.field.fieldId === field.id);
      if (!pf) {
        console.error('Missing expected parsed field value');
        return undefined;
      }
      const customField = field.customFieldId && signing.customFields?.length
        ? signing.customFields.find(f => f.id === field.customFieldId)
        : undefined;
      return {
        id: partyMatches
          ? field.id
          : undefined,
        type: field.type,
        subtype: field.subtype || SigningSessionSubType.None,
        timestamp: fieldParty
          ? fieldParty.serverAcceptPending
            ? -1
            : fieldParty.signedTimestamp
          : field.timestamp,
        imageUrl: blobUrls.get(field.file?.id),
        partyInfo: fieldParty?.snapshot,
        fieldPosition: { ...pf?.position },
        tsPosition: parsedFields.find(x => x?.field?.type === FieldType.FieldTimestamp && x?.field?.fieldId === field.id)?.position,
        witPosition: parsedFields.find(x => x?.field?.type === FieldType.FieldWitness && x?.field?.fieldId === field.id)?.position,
        text: field.text,
        isWet: field.isWetSigned,
        custom: customField
          ? transformFieldCustom(customField)
          : undefined
      };
    })
    .filter(Predicate.isNotNull);

  return {
    status: 'pending',
    party: party,
    config: signing.general || { message: '', subject: '' },
    cssOverrides: {},
    initiator: {
      id: initiator.id,
      email: initiator.email,
      entity: initiator.entity,
      timeZone: initiator.timeZone || 'Australia/Adelaide',
      name: initiator.name
    },
    pages: pageDimensions.map(p => ({ url: '', width: p.width, height: p.height })),
    fields: positionSortMultiPage(
      resultFields,
      item => item.fieldPosition?.page ?? 0,
      item => ({ x: item.fieldPosition?.x ?? 0, y: item.fieldPosition?.y ?? 0 }),
      items => Math.max(...items.map(
        item => item.fieldPosition?.height ?? 0)),
      true
    ),
    autos: parsedFields.map<PendingRemoteSigningSessionAutoField | undefined>(pf => {
      if (!pf?.position) return undefined;
      if (pf?.field?.type !== FieldType.PartyTimestamp) return undefined;
      // only do it for the current party, it seems that everything else is already filled in fine when others sign?
      if (pf.field.partyId !== party.id) return undefined;

      return {
        type: 'timestamp',
        position: pf.position,
        currentParty: true
      };
    }).filter(Predicate.isNotNull),
    dataFields: parsedFields.map<RemoteSigningSessionDataField | undefined>(pf => {
      if (!pf) return undefined;
      switch (pf.field.type) {
        case FieldType.PartyTimestamp: {
          const partyId = pf.field.partyId;
          const party = (signing.parties || [])
            .find(p => p.id === partyId);
          return {
            type: 'ts-party',
            value: party?.signedTimestamp
              ? party.serverAcceptPending
                ? -1
                : party.signedTimestamp
              : undefined,
            position: pf.position
          };
        }
        default:
          return undefined;
      }
    }).filter(Predicate.isNotNull),
    images: party.source.type === SigningPartySourceType.Salesperson
      ? {
        initials: blobUrls.get(agentPrefs?.images?.initials?.ref?.id),
        signature: blobUrls.get(agentPrefs?.images?.signature?.ref?.id)
      }
      : {
        initials: blobUrls.get(matchingParty?.images?.initials?.id),
        signature: blobUrls.get(matchingParty?.images?.signature?.id)
      },
    tandcAgreed: TandcAcceptPartyYjsDal.partyHasAcceptEvent(propertyMeta, party, agentPrefs?.agentId)
  };
}

export function SigningProcessShim({
  formPath,
  partyId,
  propertyId,
  formId,
  formCode,
  hosted,
  onSigningComplete,
  onFailure
}: {
  formPath: string,
  partyId: string,
  propertyId: string,
  formId: string,
  formCode: string,
  hosted?: HostedSigningProcessProps,
  onSigningComplete: () => void,
  onFailure: () => void
}) {
  const { ydoc, transactionMetaRootKey, transactionRootKey } = useContext(YjsDocContext);
  const { data: userPreferences } = useUserPreferences();
  const {
    value: signing
  } = useLightweightTransaction<FormInstanceSigning>({
    parentPath: formPath,
    myPath: 'signing',
    bindToMetaKey: true
  });
  const {
    value: session
  } = useLightweightTransaction<SigningSession>({
    parentPath: formPath,
    myPath: 'signing.session',
    bindToMetaKey: true
  });
  const {
    value: signingParty,
    fullPath: partyPath
  } = useLightweightTransaction<SigningParty>({
    parentPath: formPath,
    myPath: `signing.parties.[${partyId}]`,
    bindToMetaKey: true
  });
  const {
    value: propertyMeta
  } = useLightweightTransaction<TransactionMetaData>({
    parentPath: '',
    myPath: '',
    bindToMetaKey: true
  });
  const {
    value: partyCache
  } = useLightweightTransaction<CachedParty[]>({
    parentPath: '',
    myPath: 'partyCache',
    ydocForceKey: MasterRootKey.Meta // Cache in root only
  });
  const {
    value: propertyData
  } = useLightweightTransaction<MaterialisedPropertyData>({
    parentPath: '',
    myPath: ''
  });
  const { baseFileId } = getPdfFileIds(session);
  const basePdfFile = useLiveQuery(async () => {
    return await FileStorage.read(baseFileId);
  }, [baseFileId]);
  const [smsVerified, setSmsVerified] = useState(false);
  const [smsSecret, setSmsSecret] = useState<string | undefined>();
  const { value: signingData } = useAsync(async () => {
    try {
      return await buildPendingRemoteSigningSessionResult(
        basePdfFile?.data,
        signing,
        session,
        signingParty,
        partyCache,
        propertyMeta,
        userPreferences);
    } catch (e) {
      console.error('Error processing signing data', e);
    }

  }, [!!basePdfFile?.data, signing, session, signingParty, !!partyCache, !!userPreferences]);
  const headline = generateHeadlineFromMaterialisedData(propertyData);
  const maxRetries = 3;
  const [retries, setRetries] = useState(0);
  const { instance: fileSync } = useContext(FileSyncContext);
  useEffect(()=>{
    // Because sometimes the file fails to load after a signing link is clicked as Auctioneer,
    // let's try really hard to make sure the file is actually downloaded, rather than dying
    // because it couldn't be found. Waiting for the background retry takes too long.
    const remainingRetries = maxRetries - retries;
    if (basePdfFile?.fileStatus === StorageItemFileStatus.Failed && remainingRetries > 0) {
      const timeout = 1000 * 2 * Math.pow(4, retries); // 2 sec, 8 sec, 32 sec
      setTimeout(()=>{
        FileStorage.requeueIndividualDownload(basePdfFile.id);
        FileSync.triggerSync(fileSync);
      }, timeout);
      setRetries(ps => ps + 1);
    }
  }, [basePdfFile?.fileStatus]);
  const fullyFailed = basePdfFile?.fileStatus === StorageItemFileStatus.Failed && retries >= maxRetries;
  if (!(ydoc && basePdfFile?.data && signingData && session && headline)) {
    return <DocumentLoading loadFailed={fullyFailed} />;
  }

  const signerPhone = Predicate.proxyNotSelf(signingParty?.proxyAuthority) ? signingParty.proxyPhone : signingParty?.snapshot?.phone;
  if (signerPhone && signingParty?.verification?.type === SigningPartyVerificationType.Sms && !smsVerified) {
    return <SigningProcessSmsVerification
      phone={signerPhone}
      signingSessionId={session.id}
      propertyId={propertyId}
      formCode={formCode}
      formId={formId}
      partyId={partyId}
      onSuccess={(secret) => {
        setSmsVerified(true);
        setSmsSecret(secret);
      }}
      onFailure={() => onFailure()}
    />;
  }
  return <SigningProcess
    ydoc={ydoc}
    data={signingData}
    pdf={basePdfFile.data}
    signingSessionId={session.id}
    propertyId={propertyId}
    formCode={formCode}
    formId={formId}
    partyId={partyId}
    headline={headline}
    hosted={hosted}
    onSigningComplete={onSigningComplete}
    partyPath={partyPath}
    smsSecret={smsSecret}
    dataRootKey={transactionRootKey}
    metaRootKey={transactionMetaRootKey}
  />;
}

function prepareField(field: PendingRemoteSigningSessionField, state: SigningState): OverlaidPdfPageField | undefined {
  if (field.fieldPosition?.page == null) return undefined;
  if (field.fieldPosition?.x == null) return undefined;
  if (field.fieldPosition?.y == null) return undefined;
  if (field.fieldPosition?.width == null) return undefined;
  if (field.fieldPosition?.height == null) return undefined;

  const localFieldState = state.fields.filter(f => f.id === field.id)[0];
  return {
    ...field.fieldPosition,
    id: field.id,
    type: field.type,
    subtype: field.subtype,
    name: field.partyInfo?.name || '',
    signingPhrase: field.partyInfo?.filledSigningPhrase || '',
    image: field.id && localFieldState?.filled
      ? field.type === SigningSessionFieldType.Initials
        ? state.party.images.initials.data
        : state.party.images.signature.data
      : field.imageUrl,
    timestamp: field.id && localFieldState?.filled
      ? localFieldState.timestamp
      : field.timestamp,
    text: field.text || localFieldState?.text,
    custom: field.custom
  };
}

function prepareTimestamp(field: PendingRemoteSigningSessionField, state: SigningState): OverlaidPdfPageTimestamp | undefined {
  if (field.tsPosition?.page == null) return undefined;
  if (field.tsPosition?.x == null) return undefined;
  if (field.tsPosition?.y == null) return undefined;
  if (field.tsPosition?.width == null) return undefined;
  if (field.tsPosition?.height == null) return undefined;

  const localFieldState = state.fields.filter(f => f.id === field.id)[0];

  return {
    ...field.tsPosition,
    id: field.id,
    value: field.id && localFieldState?.filled
      ? localFieldState.timestamp
      : field.timestamp
  };
}

function prepareWitness(field: PendingRemoteSigningSessionField, state: SigningState): OverlaidPdfPageWitness | undefined {
  if (field.witPosition?.page == null) return undefined;
  if (field.witPosition?.x == null) return undefined;
  if (field.witPosition?.y == null) return undefined;
  if (field.witPosition?.width == null) return undefined;
  if (field.witPosition?.height == null) return undefined;

  const localFieldState = state.fields.filter(f => f.id === field.id)[0];

  return {
    ...field.witPosition,
    id: field.id,
    value: (field.id && localFieldState?.filled) || (field.timestamp && field.timestamp > 0 && !field.isWet)
      ? 'N/A'
      : ''
  };
}

export interface ScrollToNextFieldEvent {
  didScroll: boolean,
  ref?: RefObject<Element>,
  type?: SigningSessionFieldType
}

// todo: scroll to next in list of unfinished, not just the first in list of unfinished.
function scrollToNextField(state: SigningState, fieldRefs: React.MutableRefObject<Map<string, RefObject<HTMLDivElement>>>, dispatch: React.Dispatch<SigningAction>): ScrollToNextFieldEvent {
  const field = state.fields.find(field => !field.filled);
  const nextFieldId = field?.id;

  if (!nextFieldId) {
    return { didScroll: false };
  }

  const ref = fieldRefs.current.get(nextFieldId);
  const didScroll = scrollTo(ref?.current);
  dispatch({ type: SigningActionType.VisitField, id: nextFieldId });
  return { ref, type: field.type, didScroll };
}

function prepareAutoTimestamps(autos: PendingRemoteSigningSessionAutoField[], state: SigningState): OverlaidPdfPageTimestamp[] {
  if (!autos.length) return [];
  const tss = state.fields.map(field => field.timestamp);
  const currentPartyTimestamp = tss.length && tss.every(ts => ts && ts > 0)
    ? Math.max(...tss)
    : undefined;
  return autos.map<OverlaidPdfPageTimestamp | undefined>(auto => {
    switch (auto.type) {
      case 'timestamp': {
        if (auto.value) {
          return {
            ...auto.position,
            value: auto.value
          };
        }
        if (auto.currentParty) {
          return {
            ...auto.position,
            value: currentPartyTimestamp
          };
        }
        return undefined;
      }
      default:
        return undefined;
    }
  }).filter(Predicate.isNotNull);
}

async function storeSigningImage(data: Maybe<string>, relatedData: IFileRelatedData, store: Store<unknown, AnyAction>): Promise<Maybe<{ ref: FileRef, inline: number[] }>> {
  if (!data) {
    return undefined;
  }

  const id = uuidv4();
  const blob = dataURLtoBlob(data);
  await FileStorage.write(
    id,
    FileType.PropertyFile,
    blob.type,
    blob,
    // since the ydoc will have an inline file, there's not really any need to queue it for upload here as well.
    StorageItemSyncStatus.None,
    relatedData,
    store
  );

  return {
    ref: {
      id,
      contentType: blob.type
    },
    inline: Array.from(
      new Uint8Array(await blob.arrayBuffer()))
  };
}

interface StoreSigningImagesResult {
  signature?: FileRef;
  signatureInline?: number[];
  initials?: FileRef;
  initialsInline?: number[];
  changed: boolean;
}

async function storeSigningImagesForNonSalesperson(
  partyCache: PartyCacheYjsDal,
  newSignature: SigningImageDetail,
  newInitials: SigningImageDetail,
  party: SigningPartySnapshot | undefined,
  relatedData: IFileRelatedData,
  store: Store<unknown, AnyAction>
): Promise<StoreSigningImagesResult> {
  const cachedParty = partyCache.getParty(party?.name, party?.email);
  if (!(newSignature?.replace || newInitials?.replace)) {
    return {
      initials: cachedParty?.images.initials,
      signature: cachedParty?.images.signature,
      changed: false
    };
  }

  const storedNewSignature = newSignature.replace ? await storeSigningImage(newSignature.data, relatedData, store) : undefined;
  const storedNewInitials = newInitials.replace ? await storeSigningImage(newInitials.data, relatedData, store) : undefined;

  return {
    signature: storedNewSignature?.ref || cachedParty?.images.signature,
    signatureInline: storedNewSignature?.inline,
    initials: storedNewInitials?.ref || cachedParty?.images.initials,
    initialsInline: storedNewInitials?.inline,
    changed: true
  };
}

async function storeSigningImagesForSalesperson(
  userPrefs: UserPreferencesMain | undefined,
  updateUserPrefs: MaybeUpdateFn<UserPreferencesMain>,
  newSignature: SigningImageDetail,
  newInitials: SigningImageDetail,
  relatedData: IFileRelatedData,
  store: Store<unknown, AnyAction>
): Promise<StoreSigningImagesResult> {
  if (!(newSignature?.replace || newInitials?.replace)) {
    return {
      initials: userPrefs?.images?.initials?.ref,
      signature: userPrefs?.images?.signature?.ref,
      changed: false
    };
  }

  const storedNewSignature = newSignature.replace ? await storeSigningImage(newSignature.data, relatedData, store) : undefined;
  const storedNewInitials = newInitials.replace ? await storeSigningImage(newInitials.data, relatedData, store) : undefined;

  // may as well do it here for the salesperson's user-prefs
  if (updateUserPrefs && (storedNewSignature || storedNewInitials)) {
    updateUserPrefs(state => {
      if (!state.images) {
        state.images = {};
      }

      if (storedNewSignature) {
        state.images.signature = {
          ref: storedNewSignature.ref,
          setAtMs: Date.now()
        };
      }

      if (storedNewInitials) {
        state.images.initials = {
          ref: storedNewInitials.ref,
          setAtMs: Date.now()
        };
      }
    });
  }

  return {
    signature: storedNewSignature?.ref || userPrefs?.images?.signature?.ref,
    signatureInline: storedNewSignature?.inline,
    initials: storedNewInitials?.ref || userPrefs?.images?.initials?.ref,
    initialsInline: storedNewInitials?.inline,
    changed: true
  };
}

async function completeSigningForParty(
  ydoc: Y.Doc,
  state: SigningState,
  signingSessionId: string,
  propertyId: string,
  formId: string,
  formCode: string,
  partyId: string,
  store: Store<unknown, AnyAction>,
  smsSecret?: string,
  party?: SigningParty,
  updateUserPrefs?: MaybeUpdateFn<UserPreferencesMain>,
  userPrefs?: UserPreferencesMain,
  opts?: {
    dataRootKey?: string,
    metaRootKey?: string
  }
) {
  const {
    dataRootKey = MasterRootKey.Data,
    metaRootKey = MasterRootKey.Meta
  } = opts??{};
  const signatureRelatedData: IFileRelatedData = {
    propertyFile: {
      signingSessionId,
      propertyId,
      formId,
      formCode
    }
  };
  const { signature, signatureInline, initials, initialsInline, changed } = party?.source.type === SigningPartySourceType.Salesperson
    ? await storeSigningImagesForSalesperson(
      userPrefs,
      updateUserPrefs,
      state.party.images.signature,
      state.party.images.initials,
      signatureRelatedData,
      store
    )
    : await storeSigningImagesForNonSalesperson(
      new PartyCacheYjsDal(ydoc),
      state.party.images.signature,
      state.party.images.initials,
      party?.snapshot,
      signatureRelatedData,
      store
    );

  const now = Date.now();

  const addInline: InlineFile[] = [];
  if (signatureInline && signature) {
    addInline.push({
      ...signature,
      data: signatureInline,
      meta: {
        formId,
        formCode,
        signingSessionId,
        agentId: userPrefs?.agentId || 0
      }
    });
  }
  if (initialsInline && initials) {
    addInline.push({
      ...initials,
      data: initialsInline,
      meta: {
        formId,
        formCode,
        signingSessionId,
        agentId: userPrefs?.agentId || 0
      }
    });
  }

  const proxyMode = Predicate.proxyNotSelf(party?.proxyAuthority);
  const signerName = proxyMode ? party.proxyName : party?.snapshot?.name;
  const signerEmail = proxyMode ? party.proxyEmail : party?.snapshot?.email;

  const { allSigned } = signFields(ydoc, {
    formId,
    formCode,
    signingSessionId,
    partyId,
    fields: state.fields.map(f => ({
      id: f.id,
      timestamp: now,
      text: f.text,
      value: f.value || ''
    })),
    initials,
    signature,
    smsSecret,
    updatePartyImages: changed
      ? {
        name: signerName,
        email: signerEmail
      }
      : undefined,
    dataRootKey,
    metaRootKey,
    addInline
  });

  return allSigned;
}

export class AppFileProvider implements IFileProvider {
  async getFile(id: string): Promise<Uint8Array | undefined> {
    const result = await FileStorage.read(id);
    if (!result?.data) {
      return undefined;
    }

    return new Uint8Array(await result.data.arrayBuffer());
  }

  async getBlob(id: string): Promise<Blob | undefined> {
    return (await FileStorage.read(id))?.data;
  }
}

export interface HostedSigningProcessProps {
  formPath: string,
  onCancel: () => void,
  onDeclineToSign: () => void
}

export function SigningProcess({
  ydoc,
  data,
  pdf,
  signingSessionId,
  propertyId,
  formId,
  formCode,
  partyId,
  headline,
  hosted,
  onSigningComplete,
  partyPath,
  smsSecret,
  dataRootKey = MasterRootKey.Data,
  metaRootKey = MasterRootKey.Meta
}: {
  ydoc: Y.Doc,
  data: PendingRemoteSigningSessionResult,
  pdf: Blob,
  signingSessionId: string,
  propertyId: string,
  formId: string,
  formCode: string,
  partyId: string,
  headline: string,
  hosted?: HostedSigningProcessProps,
  onSigningComplete: () => void,
  partyPath: string,
  smsSecret?: string
  dataRootKey?: string
  metaRootKey?: string
}) {
  const brands = useBrandConfig();
  const { update: updateUserPrefs, data: userPrefs } = useUserPreferences();
  const {
    value: signingParty
  } = useLightweightTransaction<SigningParty>({
    parentPath: partyPath,
    bindToMetaKey: true
  });
  const signingConfig = brands.getSigningConfig(data.initiator.entity.id);
  const localEntity = useEntity(data.initiator.entity.id);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [state, dispatch] = useImmerReducer(signingStateReducer, getInitialSigningState(data));
  const store = useStore();

  useEffect(() => {
    preloadFonts(SystemGeneratedSignatureFontConfiguration.map(f => f.font.name));
  }, []);

  const preparedFields = data.fields.map(field => prepareField(field, state)).filter(Predicate.isNotNull);
  const preparedTimestamps = data.fields.map(field => prepareTimestamp(field, state)).filter(Predicate.isNotNull)
    .concat(prepareAutoTimestamps(data.autos || [], state));
  const preparedWitnesses = data.fields.map(field => prepareWitness(field, state)).filter(Predicate.isNotNull);

  // track reference to scroll container, so we can do intersection observations
  const scrollContainerRef = useRef(null);
  const [scrollObserver, setScrollObserver] = useState<IntersectionObserver | undefined>(undefined);

  useEffect(() => {
    if (!scrollContainerRef.current) {
      return;
    }

    const root = scrollContainerRef.current;
    const observer = new IntersectionObserver((entries, o) => {
      const relevant = entries.filter(entry => entry.isIntersecting);
      if (!relevant.length) return;
      for (const entry of relevant) {
        const dataset = (entry.target as HTMLElement).dataset;
        if (!dataset) continue;
        const fieldId = dataset['fieldId'];
        if (!fieldId) continue;

        dispatch({ type: SigningActionType.VisitField, id: fieldId });
      }
    }, {
      root,
      rootMargin: '0px',
      threshold: 1.0
    });
    setScrollObserver(observer);

    return () => {
      observer.disconnect();
      setScrollObserver(undefined);
    };
  }, [scrollContainerRef.current]);

  // track references to the pages, so we can scroll to them
  const pageRefs = useRef<RefObject<HTMLDivElement>[]>([]);
  // only initialise the first time this component renders
  if (!pageRefs.current.length) {
    pageRefs.current = data.pages.map(_ => createRef<HTMLDivElement>());
  }

  // track references to the fields the user needs to fill in, so we can scroll to them
  const fieldRefs = useRef<Map<string, RefObject<HTMLDivElement>>>(new Map());
  // only initialise the first time this component renders
  if (!fieldRefs.current.size) {
    fieldRefs.current = new Map(preparedFields
      .map(f => f.id)
      .filter(Predicate.isNotNull)
      .map(id => ([id, createRef<HTMLDivElement>()]))
    );
  }

  const allFieldsAreFilled = state.fields.every(field => field.filled);
  const preparedPages = data.pages.map((page, index) => {
    const timestamps = state.fields
      .filter(f => f.filled)
      .map(f => f.timestamp)
      .filter(Predicate.isNotNull);
    const partyTimestamp = timestamps.length ? Math.max(...timestamps) : undefined;
    return {
      index,
      page,
      pageRef: pageRefs.current[index],
      fields: preparedFields.filter(f => f.page === index),
      timestamps: preparedTimestamps.filter(f => f.page === index),
      witnesses: preparedWitnesses.filter(f => f.page === index),
      dataFields: (data.dataFields || [])
        .filter(f => f.position.page === index)
    };
  });
  const widestWidth = Math.max(...preparedPages.map(p => p.page.width));

  const finishSigning = () => {
    dispatch({ type: SigningActionType.SigningSessionCompleting });
    completeSigningForParty(
      ydoc,
      state,
      signingSessionId,
      propertyId,
      formId,
      formCode,
      partyId,
      store,
      smsSecret,
      signingParty,
      updateUserPrefs,
      userPrefs,
      { dataRootKey, metaRootKey }
    )
      .then(allSigned => {
        dispatch({ type: SigningActionType.SigningSessionComplete, moreSignaturesRequired: !allSigned });
      })
      .catch(err => {
        console.error(err);
        dispatch({ type: SigningActionType.SigningSessionCompletionFailed });
      });
  };

  const allowDecline = !!hosted;
  const hasImageData = !!state.party.images.signature.data || !!state.party.images.initials.data;
  const hasOtherActions = allowDecline || hasImageData;

  return <div className={'d-flex flex-column h-100 signing-process'}>
    {hosted && !data.tandcAgreed &&
      <PleaseReviewBanner
        next={() => {/**/
        }}
        cancel={() => dispatch({
          type: SigningActionType.BeginDeclining,
          declineType: SigningPartyDeclineType.TermsAndConditions,
          initialReason: 'Terms and Conditions are unacceptable'
        })}
        formPath={hosted.formPath}
        partyId={partyId}
        formCode={formCode}
        formId={formId}
        signingSessionId={signingSessionId}
      />}
    {!!state.pending?.mode &&
      <AdoptSignatureModal
        mode={state.pending.mode}
        initialState={{
          fullName: state.party.fullName,
          initials: state.party.initials,
          signatureImage: state.party.images.signature.data,
          initialsImage: state.party.images.initials.data
        }}
        onSave={(newState) => dispatch({
          type: SigningActionType.CompleteAdopt,
          initialsImage: newState.initialsImage,
          signatureImage: newState.signatureImage
        })}
        onCancel={() => dispatch({
          type: SigningActionType.CancelFill
        })}/>}
    {state.completionStatus && <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} />}>
      <SigningCompletionModal
        status={state.completionStatus}
        clearStatus={() => dispatch({ type: SigningActionType.SigningSessionCompletionCleared })}
        requiresMoreSignatures={state.moreSignaturesRequired}
        onContinue={() => onSigningComplete()}
        partyPath={partyPath}
        partyId={partyId}
        signingSessionId={signingSessionId}
        formCode={formCode}
        formId={formId}
      />
    </ErrorBoundary>}
    {state.declining && hosted &&
      <DeclineToSignModal
        onClose={() => dispatch({ type: SigningActionType.CancelDeclining })}
        onSubmit={() => hosted.onDeclineToSign()}
        initialReason={state.initialDeclineReason}
        formPath={hosted.formPath}
        partyId={partyId}
        signingSessionId={signingSessionId}
        formCode={formCode}
        formId={formId}
        declineType={state.declining}
      />}
    <div className={'d-flex p-3 gap-3 justify-content-end bg-white shadow'} style={{ zIndex: '1' }}>
      {allFieldsAreFilled
        ? <Button variant={'primary'} onClick={finishSigning}>Finish</Button>
        : <Button variant={'primary'} onClick={() => scrollToNextField(state, fieldRefs, dispatch)}>Continue</Button>}
      {hasOtherActions && <DropdownButton drop={'down'} variant={'outline-secondary'} title={'Other Actions'}>
        {allowDecline && <Dropdown.Item
          onClick={() => dispatch({
            type: SigningActionType.BeginDeclining,
            declineType: SigningPartyDeclineType.Document
          })}
        >
          Decline to sign
        </Dropdown.Item>}
        {hasImageData &&
          <Dropdown.Item
            onClick={() => dispatch({ type: SigningActionType.RemoveStoredImageData })}
          >
            Remove stored signature
          </Dropdown.Item>}
      </DropdownButton>}
    </div>
    <div
      className={'w-100'}
      style={{
        height: 'calc(100% - 62px)',
        backgroundColor: '#2A2A2E',
        '--agency-signing-highlight-color': localEntity?.brand?.signing?.remoteCompletion?.borderColour
      }}
    >
      <div ref={scrollContainerRef} className={'d-flex h-100 justify-content-center overflow-auto'}>
        <Document file={pdf} onLoadSuccess={pdf => setNumPages(pdf.numPages)}>
          <div
            style={{
              position: 'absolute'
            }}>
            <InteractiveSignHere
              scrollContainerRef={scrollContainerRef}
              allFieldsAreFilled={allFieldsAreFilled}
              finish={finishSigning}
              scrollToNextField={() => scrollToNextField(state, fieldRefs, dispatch)}
              brand={{
                color: signingConfig.button.foregroundColour,
                bg: signingConfig.button.backgroundColour
              }}/>
          </div>
          {numPages && preparedPages.map(item =>
            <OverlaidPdfPage
              key={item.index}
              index={item.index}
              ref={item.pageRef}
              page={item.page}
              fields={item.fields}
              timestamps={item.timestamps}
              witnesses={item.witnesses}
              dataFields={item.dataFields}
              timeZone={data.initiator.timeZone}
              getFieldRef={(id) => fieldRefs.current.get(id)}
              fillField={(id, text) => dispatch({
                type: SigningActionType.FillField,
                id,
                text
              })}
              clearField={(id) => dispatch({
                type: SigningActionType.ClearField,
                id
              })}
              scrollContainerRef={scrollContainerRef}
              minCompositeSignatureDimensions={state.minCompositeSignatureDimensions}
              widestWidth={widestWidth}
              observer={scrollObserver}
            />
          )}
          <div style={{ minHeight: '100px' }}></div>
        </Document>
      </div>
    </div>
  </div>;
}

function transformFieldCustom(custom?: CustomFieldConfiguration): undefined | PendingRemoteSigningSessionFieldCustom {
  if (!custom) return undefined;
  return {
    fontColour: 'fontColour' in custom ? custom.fontColour : undefined,
    fontSize: 'fontSize' in custom ? custom.fontSize : undefined,
    lineHeight: 'lineHeight' in custom ? custom.lineHeight : undefined,
    fontFamily: 'fontFamily' in custom ? custom.fontFamily : undefined,
    bg: 'bg' in custom ? custom.bg : undefined,
    bgColour: 'bgColour' in custom ? custom.bgColour : undefined,
    multiline: custom.type === CustomFieldType.remoteText
      ? Boolean(custom.multiline)
      : undefined,
    radio: custom.type === CustomFieldType.remoteRadio
      ? { group: custom.group }
      : undefined,
    check: custom.type === CustomFieldType.remoteCheck
      ? { group: custom.group || custom.id }
      : undefined,
    text: 'on' in custom && custom.on
      ? 'on'
      : 'text' in custom && custom.text
        ? custom.text
        : undefined,
    required: 'required' in custom && custom.required != null
      ? custom.required
      : true
  };
}
