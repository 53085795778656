import { composeErrorPathClassName } from '@property-folders/common/util/formatting';
import { getPathParentAndIndex, getValueByPath, normalisePathToStr, normalisePathToStrArray } from '@property-folders/common/util/pathHandling';
import { PathType } from '@property-folders/contract/yjs-schema/model';
import { YjsDocContext } from '../../context/YjsDocContext';
import { TextClickCheck } from '../../dragged-components/form/TextClickCheck';
import { useImmerYjs } from '../../hooks/useImmerYjs';
import { useContext } from 'react';
import { MaterialisedPropertyData } from '@property-folders/contract';
import { QuadState } from '@property-folders/common/types/QuadState';
import { quadStateFilter } from '@property-folders/common/util/quadStateFilter';
import { ShowGuidanceNotesButton } from '../../dragged-components/guidance/ShowGuidanceNotesButton';
import { notesTable } from '../GuidanceNotes';
import { useTransactionField } from '../../hooks/useTransactionField';
import { generateStandardFieldWrapping } from '../../dragged-components/form/CommonComponentWrappers';

export function QuadStateDualBoolCheckboxes({
  options,
  parentPath = [],
  unknownBoolRelPath,
  yesNoBoolRelPath,
  label,
  inline,
  titleGuidanceNoteKey
}: {
  // note: if not specified, treated as the root of the doc.
  // if specified, then both yesNoBool and unknownBool rel paths will be relative to this
  parentPath?: PathType,
  yesNoBoolRelPath: PathType,
  unknownBoolRelPath: PathType,
  options: [QuadState, string][],
  inline?: boolean,
  label: string,
  titleGuidanceNoteKey?: keyof typeof notesTable
}
) {
  const yesNoPath = [...normalisePathToStrArray(parentPath), ...normalisePathToStrArray(yesNoBoolRelPath)];
  const yesNoStringPath = normalisePathToStr(yesNoPath);

  const unknownPath = [...normalisePathToStrArray(parentPath), ...normalisePathToStrArray(unknownBoolRelPath)];

  const { ydoc, transactionRootKey } = useContext(YjsDocContext);
  const { bindState } = useImmerYjs<MaterialisedPropertyData>(ydoc, transactionRootKey);
  const { valid, errorKeys, required, fullPath } = useTransactionField({
    myPath: yesNoStringPath
  });

  const { update: rootBinderUpdate, data: yesNoValue } = bindState(m=>getValueByPath(yesNoPath, m, true));
  const { data: unknownValue } = bindState(m=>getValueByPath(unknownPath, m, true));

  const calculatedValue = quadStateFilter(yesNoValue, unknownValue);

  function handleUpdateFactory(clicked: QuadState) {
    return function (event: React.ChangeEvent<HTMLInputElement>) {

      rootBinderUpdate?.((draft: MaterialisedPropertyData) => {
        const { parent: ynParent, indexer: ynIndexer } = getPathParentAndIndex(yesNoPath, draft, true, true);
        const { parent: unParent, indexer: unIndexer } = getPathParentAndIndex(unknownPath, draft, true, true);

        if (calculatedValue === clicked && !event.target.checked) {
          delete ynParent[ynIndexer];
          delete unParent[unIndexer];
          return;
        }

        switch (clicked) {
          case QuadState.True:
            ynParent[ynIndexer] = true;
            delete unParent[unIndexer];
            return;
          case QuadState.False:
            ynParent[ynIndexer] = false;
            delete unParent[unIndexer];
            return;
          case QuadState.AssertUnknown:
            delete ynParent[ynIndexer];
            unParent[unIndexer] = true;
            return;
        }
      });

    };
  }
  return <div>
    {generateStandardFieldWrapping(
      undefined,
      errorKeys,
      required,
      fullPath,
      <>
        {label && <div className='lead d-flex align-items-center'>
          <span>{label}{titleGuidanceNoteKey && <ShowGuidanceNotesButton noteId={titleGuidanceNoteKey}/>}{required && <sup className='fs-6' style={{ color: 'red' }}> *</sup>}</span>
        </div>}
        {options.map(([enumKey, checkLabel]) => {
          if (enumKey === QuadState.NotSet) return null;
          const keyStr = enumKey.toString();
          return <TextClickCheck
            className={composeErrorPathClassName(yesNoStringPath, undefined)}
            key={name+keyStr}
            name={name+keyStr}
            inline={inline}
            onSelected={handleUpdateFactory(enumKey)}
            label={checkLabel}
            type={'checkbox'}
            checked={enumKey === calculatedValue}
          />;
        })}
      </>
    )}
  </div>;
}
