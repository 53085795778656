import { v4 as uuidV4 } from 'uuid';
import { useLightweightTransaction, useTransactionField } from '../../hooks/useTransactionField';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { CollectionEditor } from './CollectionEditor';
import { NarrowAddressInput } from './NarrowAddressInput';
import { NarrowTitleInput } from './NarrowTitle';
import React, { useContext, useEffect, useState } from 'react';
import { PropertySearchApi } from '@property-folders/common/client-api/propertySearchApi';
import {
  IAddressSearchResponseParts,
  IPropertySearchSuggestions
} from '@property-folders/contract/rest/address-search';
import { Lssa } from '@property-folders/common/util/lssa';
import { ITitleSearchItem } from '@property-folders/common/client-api/ITitleSearchResponse';
import { InlinePlanParcel, PropertyAddress } from '@property-folders/common/client-api/LssaCommon';
import { useYdocBinder } from '../../hooks/useYdocBinder';
import {
  FormOrderType,
  MaterialisedPropertyData,
  SaleAddress,
  SaleSubTitle,
  SaleTitle,
  SiteNotification,
  TitleDivision,
  TitleInclusionState,
  TransactionMetaData,
  VendorParty
} from '@property-folders/contract';
import { Maybe } from '@property-folders/common/types/Utility';
import { MultipleLocationsDetectedModal } from './MultipleLocationsDetectedModal';
import { toTitleCase } from '@property-folders/common/util/toTitleCase';
import { composeStreetAddressFromParts } from '@property-folders/common/util/formatting/string-composites';
import {
  determineTitleInclusionState
} from '@property-folders/common/yjs-schema/property/validation/expected-evaluator';
import { FormContext } from '../../context/FormContext';
import { getFormInstance } from '@property-folders/common/util/pdfgen/definitions/documents/saa-cover-page';
import { Alert, Button, Modal } from 'react-bootstrap';
import { useGetSiteNotificationsQuery } from '@property-folders/common/redux-reducers/restApiSlice';
import { formatTimestamp } from '@property-folders/common/util/formatting';
import { useIsSailisOutage } from '@property-folders/web/src/redux/useIsSailisOutage';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { FallbackModal } from '../../display/errors/modals';
import { isEqual, omit } from 'lodash';
import { SetHeaderActionsFn } from '../Wizard/WizardStepPage';
import { PortionOfLandDetails } from './SubSections/PortionOfLandDetails';
import { DwellingDetails } from './SubSections/DwellingDetails';
import { MasterRootKey } from '@property-folders/contract/yjs-schema/property';

function formatTitleForInsert(title: ITitleSearchItem, addressId?: string) {
  const parcels: SaleSubTitle[] = [];

  if (title.descriptionOfLand && title.descriptionOfLand.length > 0) {
    for (const parcel of title.descriptionOfLand) {
      if ('easementOnly' in parcel) {
        continue;
      }

      const planId = 'name' in parcel.plan
        ? parcel.plan.planNumber ?? parcel.plan.name
        : parcel.plan.planNumber;

      parcels.push({
        id: uuidV4(),
        portionType: TitleInclusionState.whole,
        plan: toTitleCase(parcel.plan.planType),
        planid: planId,
        lot: toTitleCase(parcel.plan.parcelType),
        lotid: parcel.plan.parcelNumber,
        hundreds: parcel.hundreds,
        area: 'area' in parcel ? parcel.area : undefined
      });
    }
  } else {
    for (const parcel of title.planParcel) {
      const parcelDescription = 'identifier' in parcel
        ? Lssa.parcelIdentifierToParts(parcel.identifier)
        : Lssa.ParcelToParts(parcel);

      parcels.push({
        id: uuidV4(),
        portionType: TitleInclusionState.whole,
        ...parcelDescription
      });
    }
  }

  const t: SaleTitle = {
    id: uuidV4(),
    isWhole: true,
    isNone: false,
    title: title.identifier.displayName,
    linkedAddresses: addressId ? [addressId] : [],
    subTitles: parcels,
    fromLssa: true,
    valuations: title.valuation.map(v => v.identifier.displayName),
    descriptionOfLand: title.descriptionOfLand ?? {},
    legalLandDescription: title.legalLandDescription
  };

  return t;
}

function removedSummariserForAddress(item: SaleAddress) {
  return `${item.streetAddr}, ${item.subStateAndPost}`;
}

export const AddressTitlePair = (props: TransactionConsumerProps & {
  noAttachPlan?: boolean,
  autoAddFirst: boolean,
  minimalUi?: boolean,
  setHeaderActions?: SetHeaderActionsFn
}) => {
  const { value: meta } = useLightweightTransaction<TransactionMetaData>({ bindToMetaKey: true });
  const { value: previouslySignedParties } = useLightweightTransaction<string[]>(
    { ydocForceKey: MasterRootKey.Meta, myPath: 'previouslySignedParties' }
  );
  const {
    value: addrs,
    fullPath: addressesPath
  } = useLightweightTransaction<SaleAddress[]>({ parentPath: props.parentPath, myPath: 'saleAddrs' });

  const {
    value: titles,
    fullPath: titlesPath
  } = useLightweightTransaction<SaleTitle[]>({ parentPath: props.parentPath, myPath: 'saleTitles' });

  const { value: vendors, fullPath: vendorsPath } = useLightweightTransaction<VendorParty[]>({ myPath: 'vendors' });
  const { value: titleDivision } = useLightweightTransaction<TitleDivision>({
    parentPath: 'titleDivision'
  });
  const { updateDraft: updateAddressesDraft } = useYdocBinder<Maybe<SaleAddress[]>>({ path: addressesPath });
  const { updateDraft: updateTitlesDraft } = useYdocBinder<Maybe<SaleTitle[]>>({ path: titlesPath });
  const { updateDraft: updateVendorsDraft } = useYdocBinder<Maybe<VendorParty[]>>({ path: vendorsPath });
  const { updateDraft: updateParent } = useYdocBinder<MaterialisedPropertyData>({ path: '' });
  const { formId, formName: formCode } = useContext(FormContext);
  const formInstance = getFormInstance(formCode, formId, meta);
  const inFillerMode = formInstance?.order?.type === FormOrderType.Filler;
  const hasPreviouslySigned = Boolean(previouslySignedParties?.length);
  const shouldBeLocked = inFillerMode || hasPreviouslySigned;

  const {
    fullPath: ignoredAddressesPath,
    value: ignoredAddresses,
    handleUpdate: updateIgnoredAddressesTF
  } = useTransactionField<string[]>({ myPath: '_ignoredAddresses' });
  const { updateDraft: updateIgnoredAddresses } = useYdocBinder<string[]>({ path: ignoredAddressesPath });

  const [lookingUpTitles, setLookingUpTitles] = useState(false);
  const [backLookupTitles, setBackLookupTitles] = useState(false);
  const [multipleLocationModalVisible, setMultipleLocationModalVisible] = useState(false);
  const [multipleLocationModalLocations, setMultipleLocationModalLocations] = useState<PropertyAddress[]>([]);
  const [multipleLocationModalTitles, setMultipleLocationModalTitles] = useState<ITitleSearchItem[]>([]);
  const [isLocked, setIsLocked] = useState(shouldBeLocked);
  const [showUnlockModal, setShowUnlockModal] = useState(false);
  const { data: notifications } = useGetSiteNotificationsQuery(undefined, { pollingInterval: 300000 });
  const isSailisOutage = useIsSailisOutage();

  // ignored addresses is only use programmatically, so we need to set the value explicitly
  useEffect(() => {
    if (!ignoredAddresses) {
      updateIgnoredAddressesTF([], true);
    }
  }, [ignoredAddresses]);

  const findTitlesFromAddress: (addressId: string) => SaleTitle[] = addressId => (titles || []).filter(t => t.linkedAddresses?.find(la => la === addressId));
  const findVendorsFromAddress: (addressId: string) => VendorParty[] = addressId => (vendors || []).filter(t => t.linkedAddresses?.find(la => la === addressId));

  function removeItemDraft(item: { id: string }, updateFn: any) {
    updateFn?.(draft => {
      const index = draft?.findIndex(i => item.id === i.id);
      if (index != null && index !== -1) {
        draft?.splice(index, 1);
      }
    });
  }

  function markAddressAsFromLssa(existingAddrId: string) {
    updateAddressesDraft?.(draft => {
      const addressParent = (draft || []).find(a => a.id === existingAddrId);
      if (!addressParent) {
        return;
      }

      addressParent.fromLssa = true;
    });
  }

  const onUpdate = (el: any) => {
    if (el && el.address) {
      const address = el.address;
      const titleLinked = findTitlesFromAddress(address.id);

      if (titleLinked?.length > 0) {
        for (let i = titleLinked.length - 1; i >= 0; i--) {
          removeItemDraft(titleLinked[i], updateTitlesDraft);
        }
      }

      const vendorsLinked = findVendorsFromAddress(address.id);
      if (vendorsLinked.length > 0) {
        for (let i = vendorsLinked.length - 1; i >= 0; i--) {
          removeItemDraft(vendorsLinked[i], updateVendorsDraft);
        }
      }
    }
  };

  useEffect(() => {
    if (!Array.isArray(addrs) || addrs.length === 0 || isSailisOutage) {
      return;
    }

    const waitingAddresses = addrs.filter(address => {
      const titleLinked = findTitlesFromAddress(address.id);
      if (!address.streetAddr_parts || titleLinked?.length > 0 || typeof address.fromLssa === 'boolean') {
        return false;
      } else {
        return true;
      }
    });

    if (waitingAddresses.length === 0) {
      return;
    }
    setLookingUpTitles(true);

    const promises = waitingAddresses.map(address => {
      const parts = address.streetAddr_parts as IAddressSearchResponseParts;

      // if a sale title hasn't been manually created, then the array won't be initiliased yet.
      updateParent?.(draft => {
        if (!draft.saleTitles) {
          draft.saleTitles = [];
        }
      });

      return PropertySearchApi.getTitlesForAddress(
        address.gnaf
          ? {
            gnafId: address.gnaf,
            ignoreEasements: true
          }
          : {
            unitNo: parts.UnitNumber,
            levelNo: parts.LevelNumber,
            street: parts.StreetName,
            lotNo: parts.LotNumber,
            streetNo: parts.StreetNumber,
            suburb: parts.Suburb,
            exact: true,
            ignoreEasements: true
          }
      ).response.then((r: ITitleSearchItem[] | undefined) => { // not sure why typescript is complaining about unknown types
        if (!r) {
          console.warn('no response from title search');
          return;
        }

        const ignoredAddressesObj = ignoredAddresses.map(t => JSON.parse(t));

        const allPropertyLocations = [
          ...new Set(r?.map(t => t.propertyAddress.map(pa => JSON.stringify(pa))).flat())
        ]
          .map(t => JSON.parse(t))
          .filter(t => {
            return !ignoredAddressesObj.some(ia => isEqual(omit(ia, ['__proto__']), omit(t, ['__proto__'])));
          });

        if (allPropertyLocations.length > 1) {
          setMultipleLocationModalVisible(true);
          setMultipleLocationModalLocations(allPropertyLocations);
          setMultipleLocationModalTitles(r);
          return;
        }

        updateTitlesDraft?.(draft => {
          if (!draft) {
            return;
          }

          for (const title of r) {
            const formattedTitle = formatTitleForInsert(title, address.id);

            if (
              draft.find(st => st.title === formattedTitle.title)
              || (formattedTitle.subTitles || []).length === 0
            ) {
              continue;
            }

            draft.push(formattedTitle);
          }
        });

        markAddressAsFromLssa(address.id);
      }).catch(e => {
        console.error('error fetching titles', e);
      });
    });
    Promise.allSettled(promises).then(() => setLookingUpTitles(false));

    return () => {
      setLookingUpTitles(false);
    };
  }, [addrs]);

  const onDelete = (el: any) => {
    if (!el?.id) {
      console.warn('no id to delete');
      return;
    }

    // yjs freaks out if you remove an item in the middle and then remove one after that
    // so remove them backwards
    const titles = findTitlesFromAddress(el.id);
    for (let i = titles.length - 1; i >= 0; i--) {
      removeItemDraft(titles[i], updateTitlesDraft);
    }

    const vendorsLinked = findVendorsFromAddress(el.id);
    if (vendorsLinked.length > 0) {
      for (let i = vendorsLinked.length - 1; i >= 0; i--) {
        removeItemDraft(vendorsLinked[i], updateVendorsDraft);
      }
    }
  };

  const { titleInclusionState, explicit } = determineTitleInclusionState(titles ?? []);
  const anyPortions = explicit && titleInclusionState === TitleInclusionState.portion;
  const [wasNoPortions, setWasNoPortions] = useState(!anyPortions);
  useEffect(() => {
    if (!anyPortions) {
      setWasNoPortions(true);
    }
  }, [anyPortions]);
  const startExpanded = wasNoPortions && anyPortions;

  const getSailisNotificationMessage = (n: SiteNotification) => {
    const now = new Date();
    return now > new Date(n.Start)
      ? `Land Services SA is performing scheduled maintenance at this time. Some functionality may be unavailable until ${formatTimestamp(n.End, '', true)}`
      : `Land Services SA will be performing scheduled maintenance between ${formatTimestamp(n.Start, '', true)} and ${formatTimestamp(n.End, '', true)}. Some functionality may be unavailable during this time.`;
  };

  return <div className={'position-relative'}>
    <Modal show={showUnlockModal}>
      {/* This modal only calls set states to this element, performing no interesting logic of it's own, there's no purpose in an Error Boundary */}
      <Modal.Header><Modal.Title>Property locked</Modal.Title></Modal.Header>
      {inFillerMode ?
        <Modal.Body>
        This Address is locked because it was provided
        by {formInstance?.order?.source?.sourceEntityName || 'the Agent'} as part of their property transaction.
        Changing this field will cause this order to diverge. Changes made here will be presented
        to {formInstance?.order?.source?.sourceEntityName || 'the Agent'} for review, when this order is delivered.
        </Modal.Body>
        : <Modal.Body>
          A party has signed documents for this transaction.
          For the agency to retain its legal authority over this transaction,
          changing the property details must not fundamentally change what the property is without a
          Variation to Sales Agency Agreement being signed.
        </Modal.Body>}
      <Modal.Footer className='d-flex'>
        <Button
          className='flex-fill'
          onClick={() => {
            setIsLocked(false);
            setShowUnlockModal(false);
          }}
        >
          Unlock and edit
        </Button>
        <Button
          className='flex-fill'
          variant='outline-secondary'
          onClick={() => {
            setShowUnlockModal(false);
          }}
        >Cancel</Button>
      </Modal.Footer>
    </Modal>

    <div className='mb-3'>
      {notifications?.filter(n => n.Type === 'SAILIS_SCHEDULED' && (new Date() < new Date(n.End)))?.map(n =>
        <Alert variant={'warning'} className={'mb-0'} dismissible={false}>{getSailisNotificationMessage(n)}</Alert>
      )}

      {isLocked &&
        <Button variant="outline-secondary" className={''} style={{ float: 'right' }}
          onClick={() => setShowUnlockModal(true)}>Unlock</Button>
      }
      <CollectionEditor
        title='Sale Property address'
        titlePlural='Sale Property addresses'
        level={2}
        isChildReadonly={() => isLocked}
        allowAdd={!isLocked}
        autoAddFirst={props.autoAddFirst}
        parentPath={props.myPath}
        myPath={'saleAddrs'}
        onUpdate={onUpdate}
        onDelete={onDelete}
        childItemRenderer={NarrowAddressInput}
        itemNoun='Property'
        restorationFieldDisplay={removedSummariserForAddress}
        addTooltip='If the sale property comprises more than one street address, add additional addresses here'
        setHeaderActions={props.setHeaderActions}
        duplicateCheck={(items: any) => {
          const duplicates = [];
          const seenValues: { [key: string]: boolean } = {};

          for (const address of items) {
            if (seenValues[address.streetAddr]) {
              duplicates.push(address);
            } else {
              seenValues[address.streetAddr] = true;
            }
          }
          if (duplicates && duplicates.length > 0) console.warn('Duplicate address found');
          return duplicates;
        }}
        childProps={{
          minimalUi: props.minimalUi
        }}
      />
    </div>
    <CollectionEditor
      title='Title'
      level={2}
      isChildReadonly={() => isLocked}
      allowAdd={!isLocked}
      parentPath={props.myPath}
      myPath={'saleTitles'}
      childItemRenderer={NarrowTitleInput}
      childProps={{
        allTitles: titles
      }}
      onUpdateBegin={() => setBackLookupTitles(true)}
      onUpdateCancel={() => setBackLookupTitles(false)}
      setHeaderActions={props.setHeaderActions}
      collectionMaskingText={
        lookingUpTitles
          ? 'Validating address with Land Services SA...'
          : backLookupTitles
            ? 'Validating title with Land Services SA...'
            : undefined
      }
      onDelete={eRaw => {
        const e = eRaw as SaleTitle;
        if (!e.linkedAddresses?.length) return;
        const addressIds = new Set(e.linkedAddresses);
        updateParent?.(draft => {
          if (draft.saleAddrs) {
            for (let i = draft.saleAddrs.length - 1; i >= 0; i--) {
              if (addressIds.has(draft.saleAddrs[i].id)) {
                draft.saleAddrs.splice(i, 1);
              }
            }
          }
          if (draft.saleTitles) {
            for (let i = draft.saleTitles.length - 1; i >= 0; i--) {
              if (draft.saleTitles[i].linkedAddresses?.some(la => addressIds.has(la))) {
                draft.saleTitles.splice(i, 1);
              }
            }
          }
        });
      }}
      onUpdate={e => {
        setBackLookupTitles(false);
        if (!e || !e.search?.property) {
          return;
        }

        const addressId = uuidV4();
        const property = e.search.property;
        const parts = property.AddressParts;

        const address = composeStreetAddressFromParts(parts);
        const secondaryPart = `${parts.Suburb}, ${parts.State} ${parts.Postcode}`;

        //remove empty addresses
        (addrs ?? [])
          .filter(a => !a.streetAddr && !a.suburb)
          .forEach(a => removeItemDraft(a, updateAddressesDraft));

        updateAddressesDraft?.(draft => {
          if (!draft) {
            return;
          }

          draft.push({
            hundred: property.Hundred ?? 'Out of Hundreds',
            irrigationArea: property.irrigationArea,
            id: addressId,
            lga: property.LGA?.Name,
            streetAddr: address,
            streetAddr_parts: parts,
            subStateAndPost: secondaryPart,
            suburb: parts.Suburb,
            gnaf: property.GNAF,
            fromLssa: true // always true, reverse title search
          });
        });

        updateTitlesDraft?.(draft => {
          const title = (draft || []).find(t => t.id === e.title.id);
          const searchedTitle = e.search?.titles.find(t => `${t.register} ${t.volume}/${t.folioInStringFormat}` === title?.title);
          if (!title) {
            return;
          }

          const descriptionOfLand = searchedTitle.descriptionOfLand;
          title.legalLandDescription = searchedTitle.legalLandDescription;
          title.descriptionOfLand = descriptionOfLand;
          title.isWhole = e.title.isWhole === undefined ? true : !!e.title.isWhole;
          if (e.search.identifier && e.search.identifier.resourceName?.includes('valuation')) {
            title.valuations = [e.search.identifier.displayName];
          }
          title.linkedAddresses = [addressId];
          title.fromLssa = e.search !== undefined;
          const originalAllotments = e.search.planParcels as InlinePlanParcel[];
          let allotments: SaleSubTitle[] = [];

          if (descriptionOfLand && descriptionOfLand.length > 0) {
            for (const parcel of descriptionOfLand) {
              const planId = 'name' in parcel.plan
                ? parcel.plan.planNumber ?? parcel.plan.name
                : parcel.plan.planNumber;

              allotments.push({
                id: uuidV4(),
                portionType: TitleInclusionState.whole,
                plan: toTitleCase(parcel.plan.planType),
                planid: planId,
                lot: toTitleCase(parcel.plan.parcelType),
                lotid: parcel.plan.parcelNumber,
                hundreds: parcel.hundreds,
                area: 'area' in parcel ? parcel.area : undefined
              });
            }
          } else {
            allotments = originalAllotments.map(pp => ({
              id: uuidV4(),
              ...Lssa.ParcelToParts(pp),
              portionType: e.title.isWhole === undefined
                ? TitleInclusionState.whole
                : (e.title.isWhole ? TitleInclusionState.whole : TitleInclusionState.portion)
            }));
          }

          title.subTitles = allotments;
        });
      }}
      itemNoun='Title'
      restorationFieldDisplay={'title'}
      addTooltip='If a title was not automatically found on property search, add the title here'
      guidanceUnderTitle={{
        title: 'Notes for titles',
        body: <div>
          <ul>
            <li>
              If the street address is recognised by Land Services SA,
              you will see a green tick and the title, parcel and plan details
              for that address will be automatically populated.
              If you are selling a portion of the Property, you can expand the
              title reference and select 'is portion of', and you will be prompted to
              provide further details.
            </li>
            <li>
              If the street address is not recognised by Land Services SA,
              you will not see a green tick and you will need to manually enter
              information relating to the title, parcel and plan, and select whether
              it is whole of or is portion of, and provide further details if applicable.
            </li>
          </ul>
        </div>
      }}
      duplicateCheck={(items: any) => {
        const duplicates = [];
        const seenValues: { [key: string]: boolean } = {};

        for (const address of items) {
          if (seenValues[address.title]) {
            duplicates.push(address);
          } else {
            seenValues[address.title] = true;
          }
        }

        return duplicates;
      }}
    />

    {anyPortions && <>
      <PortionOfLandDetails
        editable={!isLocked}
        noAttachPlan={props.noAttachPlan}
        startExpanded={startExpanded}
      />
      <DwellingDetails
        editable={!isLocked}
        startExpanded={startExpanded}
      />
    </>}
    <ErrorBoundary fallbackRender={fallback => <FallbackModal {...fallback} show={!!multipleLocationModalVisible}
      onClose={() => setMultipleLocationModalVisible(false)}/>}>
      <MultipleLocationsDetectedModal
        locations={multipleLocationModalLocations}
        onSelection={(l, notSelected) => {
          const promises: Promise<IPropertySearchSuggestions & { Request: PropertyAddress; }>[] = [];
          const includedPropertyLocations = l.map(pa => PropertySearchApi.stringifyPropertyAddressImmutable(pa));

          updateIgnoredAddresses?.(draft => {
            if (!draft) {
              return;
            }

            const toInsert = notSelected.filter(ns => !draft.some(d => isEqual(ns, d)));
            if (toInsert) {
              draft?.push(...toInsert.map(t => JSON.stringify({ id: uuidV4(), ...t })));
            }
          });

          // contains the titles to insert, and the associated (stringified) locations
          const titleAddress: { locations: string[]; title: SaleTitle; }[] = [];

          for (const address of l) {
            const formattedAddressParts = PropertySearchApi.stringifyPropertyAddressImmutable(address).split(',');
            promises.push((PropertySearchApi.getSuggestions(`${formattedAddressParts[0]} ${formattedAddressParts[1]}`).response as Promise<IPropertySearchSuggestions>)
              .then(r => ({ ...r, Request: address })));
          }

          // only insert titles with all the locations
          for (const title of multipleLocationModalTitles) {
            const titleLocations = title.propertyAddress.map(pa => PropertySearchApi.stringifyPropertyAddressImmutable(pa));
            let includeTitle = true;
            for (const titleLocation of titleLocations) {
              if (!includedPropertyLocations.includes(titleLocation)) {
                includeTitle = false;
                break;
              }
            }

            const formattedTitle = formatTitleForInsert(title);

            if (includeTitle && (formattedTitle.subTitles ?? []).length > 0) {
              titleAddress.push({ title: formattedTitle, locations: titleLocations });
            }
          }

          Promise.allSettled(promises).then(res => {
            for (const r of res) {
              if (r.status !== 'fulfilled') {
                continue;
              }

              const { value } = r;
              const stringifiedAddress = PropertySearchApi.stringifyPropertyAddressImmutable(value.Request);
              const newAddressId = uuidV4();

              const addLinkedAddressId = (id: string) => {
                for (const ta of titleAddress) {
                  if (ta.locations.includes(stringifiedAddress)) {
                    // formatForTitleInsert above constructs an array prop, so this will be fine.
                    ta.title.linkedAddresses?.push(id);
                  }
                }
              };

              if (value.Predictions.length === 1) {
                const property = value.Predictions[0];
                const subStateAndPost = `${property.AddressParts.Suburb} ${property.AddressParts.State} ${property.AddressParts.Postcode}`;
                const streetAddr = composeStreetAddressFromParts(property.AddressParts);
                const existingAddr = addrs.find(a => `${a.streetAddr} ${a.subStateAndPost}` === `${streetAddr} ${subStateAndPost}`);

                if (existingAddr) {
                  addLinkedAddressId(existingAddr.id);
                  markAddressAsFromLssa(existingAddr.id);
                  continue;
                }

                addLinkedAddressId(newAddressId);
                updateAddressesDraft?.(draft => {
                  if (!draft) {
                    return;
                  }

                  draft.push({
                    id: newAddressId,
                    streetAddr: streetAddr,
                    streetAddr_parts: property.AddressParts,
                    subStateAndPost,
                    suburb: property.AddressParts.Suburb,
                    fromLssa: true,
                    gnaf: property.GNAF ?? '',
                    lga: property.LGA?.Name ?? '',
                    hundred: property.Hundred ?? 'Out of Hundreds',
                    irrigationArea: property.IrrigationArea ?? ''
                  });
                });
              } else {
                const formattedAddressParts = stringifiedAddress.split(',').map(t => t.trim());
                const subStateAndPost = `${toTitleCase(value.Request.suburb)} ${value.Request.stateTerritory} ${value.Request.postcode}`;
                const streetAddr = formattedAddressParts[0];
                const existingAddr = addrs.find(a => `${a.streetAddr} ${a.subStateAndPost}` === `${streetAddr} ${subStateAndPost}`);
                // whilst the address was from lssa, this controls the validated tick and signifies whether advanced
                // GNAF features are available this seems unlikely to actually happen, but it has occurred in testing
                // (1619 Old Princes Highway)
                const fromLssa = false;

                if (existingAddr) {
                  addLinkedAddressId(existingAddr.id);
                  continue;
                }

                addLinkedAddressId(newAddressId);
                updateAddressesDraft?.(draft => {
                  if (!draft) {
                    return;
                  }

                  draft.push({
                    id: newAddressId,
                    streetAddr,
                    subStateAndPost,
                    suburb: value.Request.suburb,
                    fromLssa,
                    lga: '',
                    hundred: '',
                    irrigationArea: ''
                  });
                });
              }
            }

            for (const ta of titleAddress) {
              updateTitlesDraft?.(draft => {
                if (!draft) {
                  return;
                }

                draft.push(ta.title);
              });
            }
          });
        }}
        visible={multipleLocationModalVisible}
        setVisible={setMultipleLocationModalVisible}
        key={multipleLocationModalVisible ? '1' : '0'}
      />
    </ErrorBoundary>
  </div>;
};

function LockedSummary() {
  return <div>Locked Address/Title summary here.</div>;
}
