import { Button } from 'react-bootstrap';
import './CollectionRemoveButton.scss';

export const CollectionRemoveButton = ({ onRemove, removable=false }: {
  onRemove: ()=>void
  removable: boolean
}) => <Button disabled={!removable}
  onClick={()=>{onRemove?.();}}
  variant="light"
  className="btn-list-action coll-del-button form-floating"
  title='Remove this item'
>
  <i className="bi-trash-fill"></i>
</Button>;